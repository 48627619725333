import React from 'react';

function Ic_back(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M9 11.997a1 1 0 0 0 1 1h10a1 1 0 1 0 0-2H10a1 1 0 0 0-1 1z" fill={fill} fillRule="evenodd"/>
		<path d="M3.64 13.226a1.495 1.495 0 0 1 0-2.452l5.019-3.502c1-.699 2.341.035 2.341 1.225v7.006c0 1.19-1.34 1.924-2.341 1.225l-5.02-3.503z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_back;