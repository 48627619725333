import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useEffect, useState } from "react";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import { useNavigate } from "react-router";
import { RtmGetInvoicesA } from "../../../core/rtm/admin";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import ZTable from "../../../components/ZTable";
import ZSelect from "../../../components/ZSelect";
import ZInput from "../../../components/ZInput";
import Ic_search from "../../../assets/icons/ui/ic_search";
import IC_SORT_A from "../../../assets/icons/ui/IC_SORT_A";
import IC_SORT_Z from "../../../assets/icons/ui/IC_SORT_Z";
import IC_FILTER from "../../../assets/icons/ui/IC_FILTER";
import IC_FILTER_EDIT from "../../../assets/icons/ui/IC_FILTER_EDIT";
import { Invoice } from "../../../types/Invoice";
import { getCurrencySymbol, parseStripeAmount } from "../../../core/helper";

export default function SectionInvoicesA() {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<Invoice[]>([]);
  const [busy, setBusy] = useState(false);
  const [sort, setSort] = useState<{
    type:
      | "data.created"
      | "updated"
      | "customerName"
      | "id"
      | "data.total"
      | "data.status";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    type: "status";
    value: any;
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  const [filterPopoverAnchor, setfilterPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);

  async function load(page: number) {
    try {
      //Load data
      setBusy(true);
      const _invoices = await RtmGetInvoicesA({
        page: page,
        sort: sort,
        filter: filter,
        search: search,
      });
      if (_invoices) {
        setTotalPages(_invoices.pageCount);
        setPageNumber(_invoices.page);
        setTotalUsers(_invoices.size);
        setData(_invoices.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showInvoiceDialog(inv: Invoice) {
    if (!inv.data.livemode) {
      window.open(`https://dashboard.stripe.com/test/invoices/${inv.id}`);
    } else {
      window.open(`https://dashboard.stripe.com/invoices/${inv.id}`);
    }
  }

  useEffect(() => {
    // Load page 0
    load(0);
  }, []);

  useEffect(() => {
    load(pageNumber);
  }, [sort, filter, search]);

  function RenderInvoice(invoice: Invoice) {
    const _amnt = parseStripeAmount(invoice.data.total);
    const _sym = getCurrencySymbol(invoice.data.currency);
    return (
      <TableRow
        onClick={() => showInvoiceDialog(invoice)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(invoice.id)}
          >
            {invoice.id}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={invoice.customerImage || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {invoice.customerName}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {invoice.customerEmail}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={invoice.data.status}
              variant="filled"
              color={invoice.data?.status === "paid" ? "info" : "default"}
              size="small"
              sx={{ textTransform: "capitalize" }}
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(invoice.data.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {moment.unix(invoice.updated).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography>
            {_sym}
            {_amnt.dollars}.{_amnt.cents}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack flex={1} sx={{ p: "24px" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Invoices
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            onClick={() => load(pageNumber)}
            color="secondary"
            variant="outlined"
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack flex={1}>
        <ZTable
          sx={{ height: "100%" }}
          busy={busy}
          pageNumber={pageNumber}
          paginated
          totalPages={totalPages}
          nextPage={(pn) => load(pn)}
          prevPage={(pn) => load(pn)}
          titleBadge={totalUsers + " Invoices"}
          title="Invoices"
          description="List of all the invoices."
          actionButtons={
            <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
              <IconButton
                onClick={(e) => {
                  if (filter) {
                    setFilter(undefined);
                  } else {
                    setfilterPopoverAnchor(e.currentTarget);
                  }
                }}
              >
                {filter && <IC_FILTER_EDIT width={24} height={24} />}
                {!filter && <IC_FILTER width={24} height={24} />}
              </IconButton>
              <Popover
                open={Boolean(filterPopoverAnchor)}
                anchorEl={filterPopoverAnchor}
                onClose={() => setfilterPopoverAnchor(null)}
              >
                <Stack sx={{ px: "24px", py: "12px" }}>
                  <Typography>Filter Invoices Where</Typography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={"12px"}
                  >
                    <ZSelect
                      value={filter?.type}
                      onChange={(ce) =>
                        setFilter({ type: ce?.id as any, value: filter?.value })
                      }
                      options={[{ label: "Status", id: "status" }]}
                    />
                    <Typography>is</Typography>
                    {/* We show options for plan and role, and input for balance  */}
                    {filter?.type === "status" && (
                      <ZSelect
                        value={filter?.value}
                        onChange={(ce) =>
                          setFilter({
                            type: filter.type,
                            value: ce?.id,
                          })
                        }
                        options={[
                          { label: "Paid", id: "paid" },
                          { label: "Unpaid", id: "unpaid" },
                          { label: "Draft", id: "draft" },
                          { label: "Expired", id: "expired" },
                        ]}
                      />
                    )}
                  </Stack>
                </Stack>
              </Popover>
              <ZInput
                text={searchRaw}
                onUpdate={(r) => setSearchRaw(r)}
                onReturn={() => setSearch(searchRaw)}
                startIcon={<Ic_search width={24} height={24} />}
                placeholder="Search invoice..."
              />
            </Stack>
          }
          tableHead={
            <TableRow>
              {/* ID */}
              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>ID</Typography>
                </Stack>
              </TableCell>
              {/* User  */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "customerName",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Customer</Typography>
                  {sort?.type === "customerName" && (
                    <>
                      {sort?.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort?.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>
              {/* Status  */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "data.status",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Status</Typography>
                  {sort?.type === "data.status" && (
                    <>
                      {sort.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>
              {/* Registered  */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "data.created",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Created</Typography>
                  {sort?.type === "data.created" && (
                    <>
                      {sort.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>
              {/* Updated  */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "updated",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Updated</Typography>
                  {sort?.type === "updated" && (
                    <>
                      {sort.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>
              {/* Amount  */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "data.total",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Amount</Typography>
                  {sort?.type === "data.total" && (
                    <>
                      {sort.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          }
          tableBody={<>{data.map((d) => RenderInvoice(d))}</>}
        />
      </Stack>
    </Stack>
  );
}
