import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import ZInput from "../components/ZInput";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import IC_NOTE_EDIT from "../assets/icons/ui/IC_NOTE_EDIT";
import IC_NOTE_ADD from "../assets/icons/ui/IC_NOTE_ADD";
import { ChevronLeft, Close } from "@mui/icons-material";
import {
  RtmCreateEventA,
  RtmDeleteEventA,
  RtmFetchWeeklyEventsA,
  RtmUpdateEventA,
} from "../core/rtm/moderator";
import IC_NOTE_SAVE from "../assets/icons/ui/IC_NOTE_SAVE";
import IC_NOTE_REMOVE from "../assets/icons/ui/IC_NOTE_REMOVE";
import { useModal } from "mui-modal-provider";
import ConfirmationDialog from "./ConfirmationDialog";
import { DailyEvent } from "../types/DailyEvent";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { RtmGetConfigA } from "../core/rtm/admin";
import moment from "moment";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";

export default function EditEventDialog(props: {
  onClose?: (result?: boolean) => void;
  event?: DailyEvent;
}) {
  const [event, setEvent] = useState<DailyEvent | undefined>(props.event);
  const [events, setEvents] = useState<DailyEvent[]>([]); // fetched from API
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const { showModal } = useModal();
  const editor = useCreateBlockNote({
    initialContent: props.event?.comments
      ? JSON.parse(props.event?.comments!)
      : undefined,
  });

  async function add(status: "draft" | "published") {
    try {
      setError("");
      setBusy(true);
      event!.status = status;
      event!.comments = JSON.stringify(editor.document);
      if (props.event) {
        // we update
        await RtmUpdateEventA(event!);
      } else {
        // we create
        await RtmCreateEventA(event!);
      }
      enqueueSnackbar("The event has been added. ", { variant: "success" });
      props.onClose?.(true);
    } catch (err: any) {
      console.error("Error creating a new event. ", err);
      enqueueSnackbar("Error adding new event. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function deleteEvent() {
    const modal = showModal(ConfirmationDialog, {
      title: "Delete Event?",
      body: "Are you sure you want to delete this weekly event?",
      async onClose(result) {
        modal.destroy();
        if (result) {
          await RtmDeleteEventA(event!.id);
          props.onClose?.(true);
          enqueueSnackbar("The event has been deleted successfully. ", {
            variant: "success",
          });
        }
      },
    });
  }

  async function fetchEvents() {
    try {
      setBusy(true);
      // Load all events
      const _events = await RtmFetchWeeklyEventsA();
      if (_events) {
        console.log(_events);
        setEvents(_events);
      } else {
        throw new Error("Server returned invalid response. ");
      }
    } catch (err: any) {
      console.error("Error fetching weekly events. ", err);
      enqueueSnackbar("Error fetching weekly events. Please try again. ", {
        variant: "error",
      });
      props.onClose?.(false);
    }
    setBusy(false);
  }

  async function load() {
    setBusy(true);
    const _template = await RtmGetConfigA<string>("event_template");
    if (!props.event) {
      fetchEvents();
      setEvent({ comments: _template.value } as any);
    } else {
      if (!props.event.comments) {
        editor.replaceBlocks(editor.document, JSON.parse(_template.value));
      } else {
        editor.replaceBlocks(editor.document, JSON.parse(props.event.comments));
      }
      setEvent(props.event);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (props.event) return;
    setEvent(events.find((e) => e.id === rowSelectionModel[0]));
  }, [rowSelectionModel]);

  return (
    <Dialog fullScreen open>
      {busy && <LinearProgress />}
      <DialogTitle>
        <Stack direction="row" spacing={"10px"} alignItems={"center"}>
          <SvgIcon>
            {props.event && <IC_NOTE_EDIT />}
            {!props.event && <IC_NOTE_ADD />}
          </SvgIcon>
          <Typography
            sx={{ fontSize: 22, fontWeight: 500, fontFamily: "Space Grotesk" }}
          >
            Add Event
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={"8px"} sx={{ py: "24px" }}>
          <Accordion
            disabled={Boolean(props.event)}
            variant="elevation"
            sx={{ background: "#333" }}
          >
            <AccordionSummary
              expandIcon={<ChevronLeft sx={{ transform: "rotate(-90deg)" }} />}
            >
              <Typography>Select Event</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DataGrid
                sx={{ height: "600px" }}
                disableMultipleRowSelection
                columns={[
                  {
                    field: "date",
                    headerName: "Date",
                  },
                  {
                    field: "time",
                    headerName: "Time",
                  },
                  {
                    field: "title",
                    headerName: "Title",
                    flex: 1,
                  },
                  {
                    field: "impact",
                    headerName: "Impact",
                  },
                  {
                    field: "previous",
                    headerName: "Previous",
                  },
                  {
                    field: "forecast",
                    headerName: "Forecast",
                  },
                  {
                    field: "country",
                    headerName: "Country",
                  },
                  {
                    field: "source",
                    renderCell(params) {
                      if (params.row.url)
                        return (
                          <Typography
                            color={"secondary"}
                            component={"a"}
                            target="_blank"
                            href={params.row.url}
                          >
                            Source
                          </Typography>
                        );
                      else return <></>;
                    },
                  },
                ]}
                rows={events}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                checkboxSelection
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={(r) => setRowSelectionModel(r)}
              />
            </AccordionDetails>
          </Accordion>
          <ZInput
            disabled={busy}
            text={event?.title}
            onUpdate={(t) => setEvent({ ...(event as any), title: t })}
            errorText={error === "title" ? "Title cannot be blank." : undefined}
            label="Title"
            placeholder="Enter a title..."
          />

          <Typography>Your Analysis</Typography>
          <BlockNoteView
            theme={darkDefaultTheme}
            editor={editor}
            style={{ height: "600px", overflow: "auto" }}
          />
          {/* <Markdown
            key={busy as any}
            value={event?.comments}
            onChange={(v) => setEvent({ ...(event as any), comments: v })}
            style={{
              background: "#ffffff16",
              borderRadius: "8px",
              border: "0px solid #000",
              outline: "0px solid gray",
              color: "white",
              minHeight: "500px",
            }}
            previewOptions={{
              style: {
                background: "transparent",
                color: "white",
              },
            }}
          /> */}
          {/* <ZInput
            disabled={busy}
            text={event?.comments}
            onUpdate={(t) => setEvent({ ...(event as any), comments: t })}
            errorText={
              error === "description" ? "Comments cannot be blank." : undefined
            }
            multiline
            rows={4}
            label="Comments"
            placeholder="Provide your analysis on this event. MarkdownV2 is supported."
          /> */}

          <Typography
            fontWeight={500}
            fontSize={14}
            fontFamily={"Space Grotesk"}
          >
            Tags
          </Typography>
          <Autocomplete
            options={[]}
            disablePortal
            freeSolo
            disabled={busy}
            autoSelect
            multiple
            autoFocus
            key={event?.tags as any}
            onChange={(ev, values) => {
              setEvent({ ...event, tags: values } as any);
            }}
            value={event?.tags}
            fullWidth
            ListboxProps={{
              sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
            }}
            renderTags={(tags, tProps, state) =>
              tags.map((t: any) => (
                <Chip
                  deleteIcon={<Close height="16px" width="16px" />}
                  onDelete={(de) => {
                    // Remove from local
                    const _new = tags?.filter?.((s: any) => s !== t);
                    setEvent({ ...event, tags: _new } as any);
                  }}
                  size="small"
                  label={t}
                  variant="filled"
                  color="primary"
                  sx={{
                    color: "#FFF",
                    borderRadius: "24px",
                    mx: "2px",
                  }}
                />
              ))
            }
            sx={{
              borderRadius: "50px",
            }}
            size="small"
            getOptionLabel={(option: any) => option.label}
            renderInput={(params) => (
              <TextField
                variant="filled"
                autoFocus
                {...(params as any)}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  hiddenLabel: true,
                  sx: { borderRadius: "8px" },
                }}
                hiddenLabel
                multiline
                maxRows={4}
                helperText={
                  "Tags are useful when filtering events of certain types, or in search."
                }
                placeholder={"Enter additional tags for this event. "}
              />
            )}
          />

          <ZInput
            disabled={busy}
            text={event?.country}
            label="Country (read-only)"
          />

          <ZInput
            disabled={busy}
            text={event?.previous}
            label="Previous (read-only)"
          />

          <ZInput
            disabled={busy}
            text={event?.forecast}
            label="Forecast (read-only)"
          />

          <ZInput
            disabled={busy}
            text={
              event
                ? moment.unix(event!.date).format("MMM DD, YYYY hh:mm A T")
                : ""
            }
            label="Date Time (read-only)"
          />
          <Typography component={"a"} color={"secondary"} href={event?.url}>
            View Source
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "14px" }} spacing={"8px"}>
          <Button
            disabled={busy}
            onClick={() => props.onClose?.(false)}
            color="inherit"
          >
            Cancel
          </Button>
          {event?.id && (
            <Button
              disabled={busy}
              startIcon={
                <SvgIcon>
                  <IC_NOTE_REMOVE />
                </SvgIcon>
              }
              onClick={deleteEvent}
              color="error"
            >
              Delete
            </Button>
          )}
          <Button
            disabled={busy}
            color="inherit"
            variant="contained"
            onClick={() => add("draft")}
          >
            Save Draft
          </Button>
          <Button
            disabled={busy}
            variant="contained"
            startIcon={
              <SvgIcon>
                <IC_NOTE_SAVE />
              </SvgIcon>
            }
            onClick={() => add("published")}
          >
            Publish
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
