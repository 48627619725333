import React from 'react';

function Ic_search(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M16.777 15.363a2.23 2.23 0 0 1 2.278.54l3.042 3.042a2.23 2.23 0 0 1-3.152 3.152l-3.042-3.042a2.23 2.23 0 0 1-.54-2.278l-2.07-2.07 1.414-1.414 2.07 2.07z" fill={fill}/>
		<path d="M1 9a8 8 0 1 1 16 0A8 8 0 0 1 1 9zm8-6a6 6 0 1 0 0 12A6 6 0 0 0 9 3z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_search;