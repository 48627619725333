import React from 'react';

function Ic_recent(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M14.078 1.407l.058.012a10.77 10.77 0 0 1 8.614 10.559c0 5.947-4.81 10.772-10.75 10.772S1.25 17.925 1.25 11.978c0-3.013 1.235-5.738 3.224-7.692a.977.977 0 0 1 1.37 1.394 8.799 8.799 0 0 0-2.64 6.298c0 4.872 3.94 8.818 8.796 8.818 4.855 0 8.796-3.946 8.796-8.818a8.815 8.815 0 0 0-7.047-8.644c-.21-.042-.37-.074-.509-.097a2.545 2.545 0 0 0-.233-.03c-.027.177-.03.439-.03.935v.98a.977.977 0 0 1-1.954 0v-.98-.06c0-.422 0-.849.057-1.203.07-.422.237-.866.659-1.212.396-.325.83-.424 1.257-.417.34.007.724.085 1.082.157z" fill={fill} fillRule="evenodd"/>
		<path d="M7.527 6.418a.75.75 0 0 1 .938-.007l5.42 4.28a2.268 2.268 0 0 1 .011 3.555 2.297 2.297 0 0 1-3.486-.758L7.33 7.336a.75.75 0 0 1 .197-.918z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_recent;