import React from 'react';

function IC_USER_EDIT(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M18.73 12.553a2.148 2.148 0 0 1 2.145-.032c.28.155.527.408.807.696l.127.13c.283.287.53.539.68.822a2.231 2.231 0 0 1-.03 2.15c-.16.28-.414.523-.704.802l-4.39 4.229c-.582.561-1.023.986-1.587 1.206-.565.221-1.174.205-1.974.183l-.28-.007a3.028 3.028 0 0 1-.405-.03 1.025 1.025 0 0 1-.617-.328 1.037 1.037 0 0 1-.25-.643c-.007-.135.005-.29.014-.409l.023-.29c.054-.703.095-1.227.304-1.708.21-.482.564-.866 1.038-1.378l4.313-4.674c.272-.295.512-.556.787-.72z" fill={fill}/>
		<path d="M6.75 6.5a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0z" fill={fill}/>
		<path d="M10.755 21.815l.002.038c.032.471.049.706-.04.802-.09.095-.282.095-.667.095H6.59c-1.818 0-3.224-.894-4.396-2.01-.679-.647-1.017-1.375-.932-2.151.08-.73.518-1.325.98-1.777.738-.723 1.816-1.33 2.528-1.733.163-.091.307-.173.423-.242a11.291 11.291 0 0 1 4.201-1.474 11.501 11.501 0 0 1 4.97.391c.37.114.554.17.597.33.043.16-.093.308-.365.603l-2.136 2.314c-.418.452-.931 1.005-1.244 1.726-.312.717-.369 1.47-.416 2.088l-.046 1z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_USER_EDIT;