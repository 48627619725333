// The file contains helper functions for use in frontend.

import axios from "axios";
import moment from "moment";

/**
 * Returns the current user object of specified access token.
 */
export async function DiscordGetMe(token: string) {
  const resp = await axios.get("https://discord.com/api/v10/users/@me", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return resp.data;
}

export function parseStripeAmount(cents: number) {
  const dollars = Math.floor(cents / 100);
  const remainingCents = Math.floor((cents % 100) / 10); // Ensure only the first digit of the cents
  return {
    dollars,
    cents: Math.abs(remainingCents),
  };
}
export function getCurrencySymbol(currencyCode: string): string {
  try {
    // Use the Intl.NumberFormat to get the currency symbol for the given currency code.
    const currencyFormatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
    });
    const parts = currencyFormatter.formatToParts(1);

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === "currency") {
        return parts[i].value;
      }
    }
  } catch (error) {
    // Handle any errors, such as unsupported currency codes.
    console.error(
      `Error getting currency symbol for ${currencyCode}: ${error}`
    );
  }

  // Return an empty string if the currency symbol couldn't be determined.
  return "";
}

export function GetValueForValues(
  options: { option: any; value: any }[],
  value: any
) {
  const _selected = options.find((o) => o.option === value);
  return _selected?.value;
}

/* Randomize array in-place using Durstenfeld shuffle algorithm
https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
*/
export function shuffleArray(array: string[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export function formatVideoDuration(totalSeconds: number): string {
  const duration = moment.duration(totalSeconds, "seconds");
  const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
  const minutes = duration.minutes().toString().padStart(2, "0");
  const seconds = duration.seconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname: string) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie(cname: string) {
  let user = getCookie(cname);
  if (user != "") {
    return true;
  } else {
    return false;
  }
}
