import React from 'react';

function Ic_admin(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M17.875 11.875a1 1 0 0 1 1 1v.399c.74.17 1.41.525 1.951 1.015l.508-.327a1 1 0 1 1 1.082 1.683l-.464.298a4.105 4.105 0 0 1 0 2.864l.463.298a1 1 0 0 1-1.081 1.683l-.508-.327c-.541.49-1.21.845-1.95 1.015v.399a1 1 0 1 1-2 0v-.399a4.366 4.366 0 0 1-1.952-1.015l-.508.327a1 1 0 1 1-1.081-1.683l.464-.298a4.106 4.106 0 0 1 0-2.864l-.465-.298a1 1 0 0 1 1.082-1.683l.508.327a4.366 4.366 0 0 1 1.951-1.015v-.399a1 1 0 0 1 1-1zm0 3.286c-.825 0-1.538.404-1.952 1a2.12 2.12 0 0 0 0 2.428c.414.596 1.127 1 1.952 1s1.539-.404 1.952-1a2.119 2.119 0 0 0 0-2.428 2.364 2.364 0 0 0-1.952-1z" fill={fill} fillRule="evenodd"/>
		<path d="M5.625 6.375a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0z" fill={fill}/>
		<path d="M11.636 13.15a11.494 11.494 0 0 0-2.366.088 11.291 11.291 0 0 0-4.201 1.474c-.116.07-.26.15-.422.242-.713.402-1.79 1.01-2.53 1.733-.46.452-.9 1.047-.98 1.777-.084.776.254 1.504.933 2.15 1.172 1.117 2.578 2.011 4.396 2.011h5.118c.714 0 1.07 0 1.098-.056.028-.057-.228-.382-.74-1.032a3.09 3.09 0 0 1-.17-.239 2.501 2.501 0 0 1 .13-2.888c.1-.129.151-.193.167-.248.016-.055.01-.128-.004-.274a5.648 5.648 0 0 1 0-1.025c.014-.147.02-.22.004-.275-.016-.054-.066-.119-.167-.248a2.501 2.501 0 0 1-.13-2.888c.078-.121.007-.292-.136-.302z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_admin;