import React from 'react';

function Ic_reports(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M14.338 1.905c-.086-.15-.372-.15-.946-.153-.424-.002-.869-.002-1.335-.002h-.114c-2.19 0-3.912 0-5.255.18-1.377.186-2.469.573-3.327 1.43-.858.86-1.245 1.951-1.43 3.328-.181 1.343-.181 3.064-.181 5.255v.114c0 2.19 0 3.912.18 5.255.186 1.377.573 2.469 1.43 3.327.86.858 1.951 1.245 3.328 1.43 1.343.181 3.064.181 5.255.181h.114c2.19 0 3.912 0 5.255-.18 1.377-.186 2.469-.573 3.327-1.43.858-.86 1.245-1.951 1.43-3.328.181-1.343.181-3.064.181-5.255v-.114c0-.466 0-.911-.002-1.335-.002-.574-.003-.86-.153-.946-.15-.086-.427.073-.98.392a5.25 5.25 0 0 1-7.168-7.168c.318-.554.477-.832.391-.981zM12.75 9.5a.75.75 0 0 0-1.5 0v8a.75.75 0 0 0 1.5 0v-8zm5 4a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0v-4zm-10 1a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0v-3z" fill={fill} fillRule="evenodd"/>
		<path d="M14.75 5.5a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_reports;