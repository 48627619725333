import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TRUSTPILOT } from "../../../assets";
import StaticTestimonialCard from "./testimonialCard";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function StaticTestimonials() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [slideIndex, setSlideIndex] = useState(0);
  return (
    <Stack alignItems={"center"} sx={{ position: "relative" }}>
      <Stack
        spacing={"48px"}
        sx={{
          [theme.breakpoints.up("md")]: {
            maxWidth: "1320px",
          },
          [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
          },
          width: "100%",
          zIndex: 1,
        }}
      >
        {/* Rating  */}
        <Stack
          direction={"row"}
          spacing={"16px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            sx={{
              fontSize: isDesktop ? "20px" : "16px",
              fontWeight: "500",
              fontFamily: "Helvetica-Medium",
            }}
          >
            Excellent
          </Typography>
          <Typography
            sx={{
              fontSize: isDesktop ? "20px" : "16px",
              fontWeight: "300",
              fontFamily: "Helvetica",
            }}
          >
            4.3 out of 5.0
          </Typography>
          <img src={TRUSTPILOT} />
        </Stack>

        {!isDesktop && (
          <Carousel
            axis="horizontal"
            autoPlay={true}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            renderArrowNext={() => <></>}
            renderArrowPrev={() => <></>}
            renderIndicator={() => <></>}
            swipeable
            emulateTouch
            onChange={(i) => setSlideIndex(i)}
            selectedItem={slideIndex}
          >
            <StaticTestimonialCard
              name="Patricia Byrne, 2 days ago"
              message="In this application is really easy to trade but it’s so boring to make an account. "
            />
            <StaticTestimonialCard
              name="Mirche Janev, 2 days ago"
              message="I’ve had a very positive experience, both applications, web and android are very quick, precise and easy to use."
            />
            <StaticTestimonialCard
              name="Tony M, 2 days ago"
              message="Great app great demo option and very valuable lessons for beginners. Just have to pluck up the courage to start."
            />
            <StaticTestimonialCard
              name="Daniel, 2 days ago"
              message="Recommend if you’re trading forex, this broker is excellent. It’s compatible with tradingview, which was one of the key points for me."
            />
          </Carousel>
        )}

        {!isDesktop && (
          <Stack direction={"row"} justifyContent={"center"} spacing="20px">
            <IconButton
              onClick={() => setSlideIndex(slideIndex - 1)}
              disabled={slideIndex === 0}
              color="secondary"
              size="large"
              sx={{
                width: "48px",
                height: "48px",
                background: "rgba(14, 11, 52, 0.25)",
                border: "1px solid rgba(60, 215, 205, 0.15)",
              }}
            >
              <KeyboardArrowLeft sx={{ width: "24px", height: "24px" }} />
            </IconButton>
            <IconButton
              onClick={() => setSlideIndex(slideIndex + 1)}
              disabled={slideIndex === 3}
              size="large"
              color="secondary"
              sx={{
                width: "48px",
                height: "48px",
                background: "rgba(14, 11, 52, 0.25)",
                border: "1px solid rgba(60, 215, 205, 0.15)",
              }}
            >
              <KeyboardArrowRight sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Stack>
        )}

        {isDesktop && (
          <Carousel
            axis="horizontal"
            autoPlay={true}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            renderArrowNext={() => <></>}
            renderArrowPrev={() => <></>}
            renderIndicator={() => <></>}
            swipeable
            emulateTouch
            onChange={(i) => setSlideIndex(i)}
            selectedItem={slideIndex}
          >
            <Stack direction={"row"} spacing={"25px"}>
              <StaticTestimonialCard
                name="Patricia Byrne, 2 days ago"
                message="In this application is really easy to trade but it’s so boring to make an account. "
              />
              <StaticTestimonialCard
                name="Mirche Janev, 2 days ago"
                message="I’ve had a very positive experience, both applications, web and android are very quick, precise and easy to use."
              />
              <StaticTestimonialCard
                name="Tony M, 2 days ago"
                message="Great app great demo option and very valuable lessons for beginners. Just have to pluck up the courage to start."
              />
              <StaticTestimonialCard
                name="Daniel, 2 days ago"
                message="Recommend if you’re trading forex, this broker is excellent. It’s compatible with tradingview, which was one of the key points for me."
              />
            </Stack>

            <Stack direction={"row"} spacing={"25px"}>
              <StaticTestimonialCard
                name="Patricia Byrne, 2 days ago"
                message="In this application is really easy to trade but it’s so boring to make an account. "
              />
              <StaticTestimonialCard
                name="Mirche Janev, 2 days ago"
                message="I’ve had a very positive experience, both applications, web and android are very quick, precise and easy to use."
              />
              <StaticTestimonialCard
                name="Tony M, 2 days ago"
                message="Great app great demo option and very valuable lessons for beginners. Just have to pluck up the courage to start."
              />
              <StaticTestimonialCard
                name="Daniel, 2 days ago"
                message="Recommend if you’re trading forex, this broker is excellent. It’s compatible with tradingview, which was one of the key points for me."
              />
            </Stack>
          </Carousel>
        )}

        {isDesktop && (
          <Stack direction={"row"} justifyContent={"center"} spacing="20px">
            <IconButton
              onClick={() => setSlideIndex(slideIndex - 1)}
              disabled={slideIndex === 0}
              color="secondary"
              size="large"
              sx={{
                width: "48px",
                height: "48px",
                background: "rgba(14, 11, 52, 0.25)",
                border: "1px solid rgba(60, 215, 205, 0.15)",
              }}
            >
              <KeyboardArrowLeft sx={{ width: "24px", height: "24px" }} />
            </IconButton>
            <IconButton
              onClick={() => setSlideIndex(slideIndex + 1)}
              disabled={slideIndex === 1}
              size="large"
              color="secondary"
              sx={{
                width: "48px",
                height: "48px",
                background: "rgba(14, 11, 52, 0.25)",
                border: "1px solid rgba(60, 215, 205, 0.15)",
              }}
            >
              <KeyboardArrowRight sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
