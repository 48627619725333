import React from 'react';

function IC_USER_ADD(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M6.625 6.375a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0z" fill={fill}/>
		<path d="M18.375 13.875a1 1 0 0 1 1 1v2.5h2.5a1 1 0 1 1 0 2h-2.5v2.5a1 1 0 1 1-2 0v-2.5h-2.5a1 1 0 1 1 0-2h2.5v-2.5a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M15.726 14.204a.32.32 0 0 1 .171.337c-.014.11-.022.22-.022.334v.7a.3.3 0 0 1-.3.3h-.7a2.5 2.5 0 0 0 0 5h.7a.3.3 0 0 1 .3.3v.7c0 .123.009.244.026.363.027.182-.09.365-.274.376-.113.007-.227.011-.343.011H6.466c-1.818 0-3.224-.894-4.396-2.01-.679-.647-1.017-1.375-.932-2.151.08-.73.518-1.325.98-1.777.738-.723 1.816-1.33 2.528-1.733.163-.091.307-.173.423-.242a11.291 11.291 0 0 1 4.201-1.474c1.064-.15 2.146-.15 3.21 0 1.115.157 2.21.479 3.246.966z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_USER_ADD;