import React from 'react';

function IC_LOCK(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M12 3.75a3.5 3.5 0 0 0-3.5 3.5v1.56c1.118-.04 2.265-.06 3.5-.06 1.235 0 2.382.02 3.5.06V7.25a3.5 3.5 0 0 0-3.5-3.5zm-5.5 3.5v1.777a4.295 4.295 0 0 0-2.975 3.528c-.149 1.1-.275 2.257-.275 3.445 0 1.188.126 2.344.275 3.445.271 2.017 1.942 3.61 4 3.705 1.429.066 2.88.1 4.475.1 1.596 0 3.046-.034 4.475-.1 2.058-.095 3.729-1.688 4-3.705.149-1.1.275-2.257.275-3.445 0-1.188-.126-2.344-.274-3.445A4.295 4.295 0 0 0 17.5 9.027V7.25a5.5 5.5 0 1 0-11 0zM13 15a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_LOCK;