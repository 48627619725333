import React from 'react';

function IC_NOTE_EDIT(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M6 1.125a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M12.556 2.375H9.444c-1.602 0-2.881 0-3.884.135-1.037.14-1.89.435-2.565 1.11s-.97 1.528-1.11 2.565c-.135 1.003-.135 2.282-.135 3.884v5.112c0 1.602 0 2.881.135 3.884.14 1.037.435 1.89 1.11 2.565s1.528.97 2.565 1.11c1.003.135 2.282.135 3.884.135h1.344l.26-.001c.119-.002.178-.003.266-.118.088-.115.066-.204.021-.383a3.096 3.096 0 0 1-.078-.938c.015-.235.088-.65.14-.934.057-.31.12-.632.158-.812.04-.229.093-.52.17-.822.16-.63.47-1.486 1.205-2.221l3.411-3.412c.236-.237.597-.6 1.036-.854a3.77 3.77 0 0 1 2.313-.48c.24.027.36.04.46-.05.1-.089.1-.185.1-.378v-1.403c0-1.602 0-2.881-.135-3.884-.14-1.037-.435-1.89-1.11-2.565s-1.528-.97-2.565-1.11c-1.003-.135-2.282-.135-3.884-.135zM7 9.375a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H7zm0 5a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H7z" fill={fill} fillRule="evenodd"/>
		<path d="M18.127 13.68a2.27 2.27 0 0 1 2.27 0c.262.15.556.445.83.719.273.273.568.567.719.828a2.271 2.271 0 0 1 0 2.271c-.151.262-.386.496-.66.77l-3.308 3.307c-.465.465-1.087.742-1.591.871-.253.065-.506.11-.735.15-.306.066-1.304.259-1.557.275-.28.018-.684-.01-1.008-.334-.323-.323-.35-.728-.333-1.007.016-.253.21-1.25.275-1.557.04-.23.085-.483.15-.735.128-.504.346-1.066.811-1.532l3.368-3.367c.273-.274.507-.509.769-.66z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_NOTE_EDIT;