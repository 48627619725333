import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { RtmGetConfigA, RtmSetConfigA } from "../../../core/rtm/admin";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import Ic_add from "../../../assets/icons/ui/ic_add";
import { useNavigate } from "react-router";
import { PlatformPricing } from "../../../types/PlatformPricing";
import AddPlanDialog from "../../../dialogs/AddPlan";
import { getCurrencySymbol, parseStripeAmount } from "../../../core/helper";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";

export default function SectionPlansA() {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [busy, setBusy] = useState(false);
  const [pricing, setPricing] = useState<PlatformPricing[]>([]);

  function showAddDialog() {
    const modal = showModal(AddPlanDialog, {
      onClose(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
    });
  }

  function showEditDialog(index: number) {
    const modal = showModal(AddPlanDialog, {
      editing: pricing[index],
      editingIndex: index,
      onClose(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
    });
  }

  function showDeleteDialog(price: PlatformPricing, index: number) {
    const modal = showModal(ConfirmationDialog, {
      title: `Delete Price Plan: ${price.name}?`,
      body: "Are you sure you want to delete this price plan? ",
      async onClose(result) {
        modal.destroy();
        if (result) {
          pricing.splice(index, 1);
          await RtmSetConfigA("pricing", pricing);
          load();
        }
      },
    });
  }

  async function load() {
    try {
      setBusy(true);
      const _plansConfig = await RtmGetConfigA<PlatformPricing[]>("pricing");
      if (_plansConfig && _plansConfig.value) {
        setPricing(_plansConfig.value);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading configuration. ", err);
    }
    setBusy(false);
  }

  function RenderPrice(price: PlatformPricing, index: number) {
    let _priceInfo = parseStripeAmount(price.amount || 0);
    let _schedule = "lifetime";
    if (price.kind === "recurring") {
      switch (price.schedule) {
        case "3-month":
          _schedule = "every 3 months";
          break;
        case "daily":
          _schedule = "daily";
          break;
        case "6-month":
          _schedule = "every 6 months";
          break;
        case "monthly":
          _schedule = "month";
          break;
        case "weekly":
          _schedule = "week";
          break;
        case "yearly":
          _schedule = "year";
          break;
      }
    }
    const _price = `${getCurrencySymbol(price.currency || "usd")}${
      _priceInfo.dollars
    }.${_priceInfo.cents}`;
    return (
      <Stack
        sx={{
          p: "24px",
          borderRadius: "8px",
          background: "#222",
          width: "300px",
        }}
        spacing="8px"
      >
        <Stack>
          <Typography fontSize={22} fontFamily={"Space Grotesk"}>
            {price.name}
          </Typography>
          <Typography>{price.description}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {_price}
          </Typography>
          <Chip
            label={_schedule}
            sx={{ textTransform: "capitalize" }}
            size="small"
          />
        </Stack>
        {Boolean(price.trial) && price.trial! > 0 && (
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography fontSize={12}>Trial Period:</Typography>
            <Chip
              label={price.trial + " days"}
              sx={{ textTransform: "capitalize" }}
              size="small"
            />
          </Stack>
        )}
        <Stack direction={"row"} spacing="8px">
          <Button
            onClick={() => showEditDialog(index)}
            variant="contained"
            sx={{ height: "40px" }}
          >
            Edit
          </Button>
          <Button
            onClick={() => showDeleteDialog(price, index)}
            color="error"
            variant="outlined"
            sx={{ height: "40px" }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    );
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack sx={{ p: "24px" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Subscription Plans
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            onClick={load}
            color="secondary"
            variant="outlined"
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
          <Button
            disabled={busy}
            onClick={showAddDialog}
            color="primary"
            variant="contained"
            startIcon={<Ic_add width={24} height={24} />}
          >
            Add Plan
          </Button>
        </Stack>
      </Stack>
      <Divider />
      {/* List of prices */}
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        columnGap={"8px"}
        rowGap={"8px"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        {pricing.map((p, i) => RenderPrice(p, i))}
      </Stack>
    </Stack>
  );
}
