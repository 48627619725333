import React from 'react';

function IC_SORT_Z(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M15.594 16.272c.176-.022.392-.022.568-.022h.588V4a1 1 0 1 1 2 0v12.25h.588c.176 0 .392 0 .568.022h.004c.126.016.702.088.976.653.275.567-.027 1.067-.092 1.176l-.34.468c-.294.376-.7.89-1.08 1.281-.19.195-.407.397-.638.555-.205.142-.555.345-.986.345-.43 0-.78-.203-.986-.345a4.396 4.396 0 0 1-.638-.555c-.38-.39-.786-.905-1.08-1.281l-.34-.468c-.065-.109-.367-.609-.092-1.176.274-.565.85-.637.976-.653h.004z" fill={fill}/>
		<path d="M8.276 4H4a1 1 0 1 1 0-2h4.482c.412 0 .822 0 1.14.045.303.042.944.178 1.233.83.291.66-.05 1.227-.226 1.477-.184.263-.465.56-.744.857l-.042.045L6.315 9H10a1 1 0 1 1 0 2H6.11c-.412 0-.822 0-1.141-.045-.302-.042-.943-.178-1.232-.83-.292-.66.05-1.227.225-1.477.185-.263.465-.56.745-.857l.042-.045L8.276 4z" fill={fill} fillRule="evenodd"/>
		<path d="M7 16.755l2.088 4.654a1 1 0 0 0 1.825-.818l-2.106-4.695a67.593 67.593 0 0 1-.025-.056c-.173-.386-.344-.767-.517-1.044C8.103 14.538 7.717 14 7 14c-.717 0-1.103.538-1.264.796-.174.277-.345.658-.518 1.045l-.025.055-2.105 4.695a1 1 0 1 0 1.825.818L7 16.755z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_SORT_Z;