import React from 'react';

function IC_NOTE_SAVE(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M5.875 1a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0V2a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M12.43 2.25H9.32c-1.603 0-2.882 0-3.885.135-1.036.14-1.89.435-2.565 1.11S1.9 5.023 1.76 6.06c-.135 1.003-.134 2.282-.134 3.884v5.112c0 1.602 0 2.881.134 3.884.14 1.037.435 1.89 1.11 2.565s1.529.97 2.565 1.11c1.003.135 2.282.135 3.885.135h3.3c.048 0 .072 0 .103-.006.104-.023.109-.158.044-.242a2.298 2.298 0 0 0-.026-.033.29.29 0 0 0-.138-.091 2.5 2.5 0 0 1 .772-4.878c.428 0 .735.09.86.127.203.059.418.14.641.248a.965.965 0 0 0 .16.07.282.282 0 0 0 .253-.053c.026-.02.053-.053.108-.119.623-.746 1.376-1.57 2.201-2.293.614-.538 1.349-1.094 2.17-1.506.178-.09.268-.134.312-.206.045-.073.045-.167.045-.354v-3.47c0-1.602 0-2.881-.134-3.884-.14-1.037-.435-1.89-1.11-2.565s-1.529-.97-2.565-1.11c-1.003-.135-2.282-.135-3.885-.135zm-5.555 7a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8zm0 5a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4z" fill={fill} fillRule="evenodd"/>
		<path d="M22.318 15.666a1 1 0 0 1-.61 1.277c-.533.189-1.15.596-1.802 1.168-.643.564-1.268 1.241-1.822 1.905a28.04 28.04 0 0 0-1.865 2.52 1 1 0 0 1-1.738-.088c-.441-.882-.836-1.233-1.036-1.366a.734.734 0 0 0-.164-.086A1 1 0 0 1 13.375 19c.234 0 .343.04.447.07.208.06.458.165.733.348.274.183.562.436.85.782.316-.431.704-.939 1.143-1.465.594-.712 1.292-1.472 2.039-2.127.737-.646 1.576-1.24 2.454-1.55a1 1 0 0 1 1.277.608z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_NOTE_SAVE;