import {
  Badge,
  Button,
  Divider,
  Pagination,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Notification } from "../types/Notification";
import { RtmClearNotifications, RtmGetNotifications } from "../core/rtm";
import { enqueueSnackbar } from "notistack";
import Ic_notification from "../assets/icons/ui/ic_notification";

export default function NotificationsPanel() {
  const [busy, setBusy] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [data, setData] = useState<Notification[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  async function load(page: number) {
    try {
      setBusy(true);
      const _notis = await RtmGetNotifications(page);
      if (_notis) {
        setTotalPages(_notis.pageCount);
        setPageNumber(_notis.page);
        setData(_notis.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading notifications. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);
    }
    setBusy(false);
  }

  async function clearNotifications() {
    try {
      setBusy(true);
      await RtmClearNotifications();
      load(pageNumber);
    } catch (err: any) {
      console.error("Error clearing notifications. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load(0);
  }, []);

  function RenderNotification(noti: Notification) {
    return (
      <Stack
        direction={"row"}
        spacing={"8px"}
        alignItems={"flex-start"}
        sx={{
          p: "16px",
          background: noti.status === "read" ? "rgba(36, 36, 36, 1)" : "#333",
          border: "1px solid rgba(56, 56, 56, 0.50)",
          borderRadius: "8px",
        }}
      >
        <Badge
          variant="dot"
          color={noti.status === "read" ? undefined : "primary"}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <SvgIcon
            sx={{
              height: "48px",
              width: "48px",
              p: "12px",
            }}
          >
            <Ic_notification />
          </SvgIcon>
        </Badge>
        <Typography fontSize={14}>{noti.message}</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        p: "16px",
        width: "550px",
        maxHeight: "70vh",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          maxHeight: "70vh",
        },
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
          <Typography
            fontSize={20}
            fontWeight={500}
            fontFamily={"Space Grotesk"}
          >
            Notifications
          </Typography>
        </Stack>
        <Button
          onClick={clearNotifications}
          disabled={
            busy ||
            data.length === 0 ||
            data.filter((d) => d.status === "unread").length === 0
          }
          color="inherit"
          sx={{ p: "8px", width: "70px", height: "40px" }}
        >
          Clear
        </Button>
      </Stack>
      <Divider />
      <Stack spacing={"8px"} sx={{ my: "8px", overflow: "auto" }}>
        {data.map((d) => RenderNotification(d))}
        {data.length <= 0 && (
          <Typography alignItems={"center"}>
            You do not have any notifications yet. Check back later!
          </Typography>
        )}
      </Stack>
      <Stack
        sx={{ width: "100%" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Pagination
          page={pageNumber + 1}
          count={totalPages}
          onChange={(e, n) => load(n - 1)}
        />
      </Stack>
    </Stack>
  );
}
