import { Player } from "@lottiefiles/react-lottie-player";
import { Stack } from "@mui/material";
import { AppConfig } from "../config";

export default function LoadingView() {
  return (
    <Stack
      sx={{ height: "100%", width: "100%", background: "transparent" }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Player
        style={{
          width: "150px",
          height: "150px",
        }}
        autoplay
        loop
        src={AppConfig.loadingAnimation}
      />
    </Stack>
  );
}
