import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ZInput from "../components/ZInput";
import { LOGO } from "../assets";
import Ic_book from "../assets/icons/ui/ic_book";
import Ic_bookEdit from "../assets/icons/ui/ic_book-edit";
import IC_UPLOAD from "../assets/icons/ui/IC_UPLOAD";
import { RtmGetLessonA, RtmUpdateLessonA } from "../core/rtm/admin";
import { Lesson } from "../types/Lesson";
import { enqueueSnackbar } from "notistack";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbStorage } from "../core/firebase";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import EditQuestionDialog from "./AddQuestion";
import { Quiz } from "../types/Quiz";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget";
import { AppConfig } from "../config";

export default function EditLessonDialog(props: any) {
  const { showModal } = useModal();
  const courseId = props.course;
  const lessonId = props.id;
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [thumbnail, setThumbnail] = useState("");
  const [content, setContent] = useState("");
  const [video, setVideo] = useState("");
  const [quiz, setQuiz] = useState<Quiz>();
  const [saveState, setSaveState] = useState<"saving" | "saved" | "error">();
  const editor = useCreateBlockNote();

  // Loaded from server. The sidebar shows some information from this object, such as created timestamp.
  const [remoteLesson, setRemoteLesson] = useState<Lesson>();

  const [busy, setBusy] = useState(false);

  // Video upload
  // File upload
  // Upload Widget Configuration
  // Remove the comments from the code below to add
  // additional functionality.
  // Note that these are only a few examples, to see
  // the full list of possible parameters that you
  // can add see:
  //   https://cloudinary.com/documentation/upload_widget_reference

  const [uwConfig] = useState({
    cloudName: AppConfig.cloudinary.name,
    uploadPreset: AppConfig.cloudinary.preset,
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    sources: ["local", "url"], // restrict the upload sources to URL and local files
    multiple: false, //restrict upload to a single file
    // folder: "user_images", //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    clientAllowedFormats: ["video"],
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
    // theme: "purple", //change to a purple theme
  });

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function load() {
    try {
      if (!props.course || !props.id) {
        // Course ID must be specified
        props.closeHandler();
        return;
      }
      setBusy(true);
      // Load lesson details.
      const _lesson = await RtmGetLessonA(props.id || lessonId, props.course);
      if (!_lesson) {
        // Not found. We exit.
        enqueueSnackbar("Error loading lesson details. ", {
          variant: "error",
        });
        props.closeHandler();
        return;
      }
      setRemoteLesson(_lesson);
      // Also load local props
      setTitle(_lesson.title || "");
      setContent(_lesson.content || "");
      if (_lesson.content) {
        editor.replaceBlocks(editor.document, JSON.parse(_lesson.content));
      }
      setThumbnail(_lesson.thumbnail || "");
      setExcerpt(_lesson.excerpt || "");
      setVideo(_lesson.video || "");
      setTags(_lesson.tags || []);
      setQuiz(_lesson.quiz);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading lesson data. ", err);
    }
    setBusy(false);
  }

  async function saveTitle() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateLessonA({
        id: lessonId,
        title: title,
      });
      load();
      setSaveState("saved");
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving lesson. ", err);
    }
    setBusy(false);
  }

  async function saveExcerpt() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateLessonA({
        id: lessonId,
        excerpt: excerpt,
      });
      load();
      setSaveState("saved");
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving lesson. ", err);
    }
    setBusy(false);
  }

  async function saveContent() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateLessonA({
        id: lessonId,
        content: content,
      });
      load();
      setSaveState("saved");
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving lesson. ", err);
    }
    setBusy(false);
  }

  async function saveTags() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateLessonA({
        id: lessonId,
        tags: tags,
      });
      load();
      setSaveState("saved");
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving lesson. ", err);
    }
    setBusy(false);
  }

  async function saveThumbnail(attachment: File) {
    try {
      setBusy(true);
      setSaveState("saving");
      const r = ref(
        FbStorage,
        `/courses/${courseId}/lessons${lessonId}/${attachment.name}`
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          course: courseId!,
          lesson: lessonId!,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
          setSaveState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          // Here we update course
          await RtmUpdateLessonA({
            id: lessonId,
            thumbnail: url,
          });
          enqueueSnackbar("Thumbnail updated successfully.", {
            variant: "success",
          });
          setSaveState("saved");
          load();
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function uploadComplete(info: any) {
    // Here we update course
    await RtmUpdateLessonA({
      id: lessonId,
      video: info.secure_url,
      duration: info.duration,
    });
    enqueueSnackbar("Video uploaded successfully.", {
      variant: "success",
    });
    setSaveState("saved");
    load();
  }

  function addQuestionDialog() {
    const modal = showModal(EditQuestionDialog, {
      async onClose(result) {
        modal.destroy();
        if (result) {
          await RtmUpdateLessonA({
            id: lessonId,
            quiz: {
              questions: [
                ...(quiz?.questions || []),
                {
                  answer: 0,
                  choices: result.choices,
                  question: result.question,
                },
              ],
            },
          });
          load();
        }
      },
    });
  }

  function editQuestionDialog(index: number) {
    const modal = showModal(EditQuestionDialog, {
      editIndex: index,
      quiz: quiz,
      async onClose(result) {
        modal.destroy();
        if (result) {
          quiz!.questions[index] = {
            answer: 0,
            choices: result.choices,
            question: result.question,
          };
          await RtmUpdateLessonA({
            id: lessonId,
            quiz: {
              questions: [...(quiz?.questions || [])],
            },
          });
          load();
        }
      },
    });
  }

  async function deleteQuestionDialog(index: number) {
    quiz!.questions.splice(index, 1);
    await RtmUpdateLessonA({
      id: lessonId,
      quiz: {
        questions: [...(quiz?.questions || [])],
      },
    });
    load();
  }

  function RenderQuestion(
    index: number,
    question: { question: string; choices: string[]; answer: number }
  ) {
    return (
      <Stack
        spacing={"8px"}
        alignItems={"center"}
        direction={"row"}
        sx={{
          background: "#222",
          borderRadius: "8px",
          border: "1px solid #333",
          p: "8px",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "48px" }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontFamily: "Space Grotesk",
              fontWeight: "500",
            }}
          >
            #{index + 1}
          </Typography>
        </Stack>
        <Typography
          sx={{ fontSize: 20, fontFamily: "Space Grotesk", fontWeight: "500" }}
        >
          {question.question}
        </Typography>
        <Typography sx={{ fontSize: 16, opacity: 0.6 }}>
          {question.choices.length} choices
        </Typography>
        <Stack flex={1} />
        <Button color="error" onClick={() => deleteQuestionDialog(index)}>
          Delete
        </Button>
        <Button onClick={() => editQuestionDialog(index)}>Edit</Button>
      </Stack>
    );
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog open fullScreen hideBackdrop onClose={props.closeHandler}>
      <DialogTitle sx={{ width: "100%" }}>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px", width: "100%" }}
        >
          <SvgIcon>
            {!lessonId && <Ic_book />}
            {lessonId && <Ic_bookEdit />}
          </SvgIcon>
          <Stack flex={1}>
            <Typography fontSize={18} fontWeight={600}>
              {lessonId ? "Update Lesson" : "Add a Lesson"}
            </Typography>
            <TextField
              required
              onBlur={saveTitle}
              disabled={busy}
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              variant="standard"
              fullWidth
              placeholder="Write a title..."
            />
          </Stack>
          <IconButton onClick={props.closeHandler}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "row" }}>
        <Stack flex={1} spacing="12px" sx={{ overflow: "auto" }}>
          <Stack
            sx={{
              width: "100%",
              height: "900px",
              minHeight: "600px",
              background: "#333",
              borderRadius: "16px",
            }}
          >
            {!video && (
              <Stack
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ cursor: "pointer" }}
              >
                <IC_UPLOAD height={256} width={256} />
                <Typography>
                  Click the button below to upload a video.
                </Typography>
              </Stack>
            )}
            {video && (
              <video
                src={video}
                autoPlay
                loop
                controls
                muted
                style={{ height: "100%", borderRadius: "16px" }}
              />
            )}
          </Stack>

          <CloudinaryUploadWidget
            uwConfig={uwConfig}
            onUploadComplete={uploadComplete}
          />
          <Stack>
            <Typography
              sx={{
                fontSize: 32,
                fontWeight: 600,
                fontFamily: "Space Grotesk",
              }}
            >
              Supplementry Material / Transcript
            </Typography>
            <Typography sx={{ opacity: 0.8 }}>
              Markdown is supported. You can insert images, videos, etc. using
              Markdown format.{" "}
            </Typography>
          </Stack>

          <BlockNoteView
            editor={editor}
            onBlur={saveContent}
            theme={darkDefaultTheme}
            onChange={() => {
              setContent(JSON.stringify(editor.document));
            }}
          />
          <Stack>
            <Typography
              sx={{
                fontSize: 32,
                fontWeight: 600,
                fontFamily: "Space Grotesk",
              }}
            >
              Quiz
            </Typography>
            <Typography sx={{ opacity: 0.8 }}>
              Multiple Choice Questions after lesson completion.
            </Typography>
          </Stack>
          <Button sx={{ alignSelf: "flex-start" }} onClick={addQuestionDialog}>
            Add a Question
          </Button>
          {/* Show list of all MCQs. */}
          {quiz?.questions.map((q, i) => RenderQuestion(i, q))}
        </Stack>
        <Stack spacing={"8px"} sx={{ px: "14px", overflow: "auto" }}>
          <Stack spacing={"8px"} position={"relative"}>
            <Typography fontWeight={500} fontSize={14}>
              Thumbnail
            </Typography>
            <img
              src={thumbnail || LOGO}
              style={{
                width: "360px",
                height: "300px",
                borderRadius: "8px",
                opacity: 0.5,
                objectFit: "contain",
              }}
            />

            <input
              accept=".png, .jpg, .jpeg"
              id="file-input-2"
              style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
              type="file"
              onChange={(f) => {
                if (f.target.files && f.target.files?.length > 0) {
                  saveThumbnail(f.target.files[0]);
                }
              }}
            />
            <Button
              onClick={() => document.getElementById("file-input-2")?.click()}
              sx={{ alignSelf: "flex-end" }}
              variant="contained"
            >
              Upload a Picture
            </Button>
          </Stack>
          <ZInput
            sx={{ height: "100px" }}
            label="Excerpt"
            important
            onBlur={saveExcerpt}
            text={excerpt}
            onUpdate={(t) => setExcerpt(t)}
            placeholder="Write a short description..."
            helperText="The excerpt text will be shown in the blog post's card. "
          />
          <Stack spacing={"8px"}>
            <Typography fontWeight={500} fontSize={14}>
              Tags
            </Typography>
            <Autocomplete
              options={[]}
              disablePortal
              freeSolo
              onBlur={saveTags}
              autoSelect
              multiple
              onChange={(ev, values) => {
                setTags(values);
              }}
              defaultValue={tags}
              value={tags}
              fullWidth
              ListboxProps={{
                sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
              }}
              renderTags={(tags, tProps, state) =>
                tags.map((t: any) => (
                  <Chip
                    deleteIcon={<Close height="16px" width="16px" />}
                    onDelete={(de) => {
                      // Remove from local
                      const _new = tags?.filter?.((s: any) => s !== t);
                      setTags(_new);
                    }}
                    size="small"
                    label={t}
                    variant="filled"
                    color="primary"
                    sx={{
                      color: "#FFF",
                      borderRadius: "24px",
                      mx: "2px",
                    }}
                  />
                ))
              }
              sx={{
                borderRadius: "50px",
              }}
              size="small"
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  {...(params as any)}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    hiddenLabel: true,
                    sx: { borderRadius: "8px" },
                  }}
                  hiddenLabel
                  helperText={
                    "Tags are useful when filtering posts of certain types, or in search."
                  }
                  placeholder={"Enter tags for the post. "}
                />
              )}
            />
          </Stack>

          {remoteLesson && (
            <Stack>
              <Typography fontWeight={500} fontSize={14}>
                Created
              </Typography>
              <Typography fontSize={14}>
                {moment
                  .unix(remoteLesson?.created)
                  .format("DD/MM/YYYY hh:mm A")}{" "}
                ({moment.unix(remoteLesson.created).fromNow()})
              </Typography>
            </Stack>
          )}

          {remoteLesson && (
            <Stack>
              <Typography fontWeight={500} fontSize={14}>
                Last updated
              </Typography>
              <Typography fontSize={14}>
                {moment
                  .unix(remoteLesson?.updated)
                  .format("DD/MM/YYYY hh:mm A")}{" "}
                ({moment.unix(remoteLesson.updated).fromNow()})
              </Typography>
            </Stack>
          )}

          {saveState === "saving" && (
            <Stack flex={1} justifyContent={"flex-end"}>
              <Typography fontWeight={500} fontSize={14}>
                Auto saving...
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
