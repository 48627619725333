import {
  Button,
  Chip,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NAV_LOGO } from "../../assets";
import ZInput from "../../components/ZInput";
import ZCheckbox from "../../components/ZCheckBox";
import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  CheckCircle,
  Code,
  Email,
  Password,
  Person,
} from "@mui/icons-material";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FbAuth } from "../../core/firebase";
import { enqueueSnackbar } from "notistack";
import { RestInitiateCheckout, RestPostSignup } from "../../core/rest";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  getCookie,
  getCurrencySymbol,
  parseStripeAmount,
  setCookie,
} from "../../core/helper";
import { PlatformPricing } from "../../types/PlatformPricing";
import { AppConfig } from "../../config";
import { RtmGetProfile } from "../../core/rtm";
import { Role } from "../../types/Role";
export default function PageSubscriptionExpired(props: {
  plan: PlatformPricing;
}) {
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const theme = useTheme();

  const [checkoutError, setCheckoutError] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  /**
   * Called upon form submission.
   * @param fe
   * @returns
   */
  async function getStarted(fe: any) {
    fe?.preventDefault?.();
    setError("");

    const err = await elements?.submit();
    if (err?.error) {
      return;
    }
    setBusy(true);

    await checkout();

    setBusy(false);
  }

  /**
   * Checks out the currently logged in user.
   */
  async function checkout() {
    try {
      if (!FbAuth.currentUser) {
        throw new Error("User is not logged in.");
      }
      setBusy(true);
      // Create subscription
      const sub = await RestInitiateCheckout(
        "basic-plan",
        FbAuth.currentUser!.email!,
        FbAuth.currentUser!.displayName!,
        FbAuth.currentUser!.uid
      );
      const confirmIntent =
        sub.type === "setup" ? stripe?.confirmSetup : stripe?.confirmPayment;

      const res = await confirmIntent!({
        clientSecret: sub.clientSecret!,
        elements: elements!,
        confirmParams: {
          return_url: AppConfig.stripe.returnUrl,
        },
      });
      if (
        res?.error.type === "card_error" ||
        res?.error.type === "validation_error"
      ) {
        setCheckoutError(res?.error?.message || "");
        enqueueSnackbar(res.error.message, { variant: "error" });
      } else {
        setCheckoutError("An unexpected error occurred.");
        enqueueSnackbar("Unknown error during checkout. ", {
          variant: "error",
        });
      }
      setError("checkout");
    } catch (err: any) {
      enqueueSnackbar("Error checking out. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  function RenderPrice(price: PlatformPricing) {
    let _priceInfo = parseStripeAmount(price.amount || 0);
    let _schedule = "lifetime";
    if (price.kind === "recurring") {
      switch (price.schedule) {
        case "3-month":
          _schedule = "every 3 months";
          break;
        case "daily":
          _schedule = "daily";
          break;
        case "6-month":
          _schedule = "every 6 months";
          break;
        case "monthly":
          _schedule = "monthly";
          break;
        case "weekly":
          _schedule = "weekly";
          break;
        case "yearly":
          _schedule = "yearly";
          break;
      }
    }
    const _price = `${getCurrencySymbol(price.currency || "usd")}${
      _priceInfo.dollars
    }.${_priceInfo.cents}`;
    return (
      <Stack
        flex={1}
        sx={{
          p: "24px",
          borderRadius: "8px",
          background: "#2222",
          border: "1px solid #333",
          borderColor: theme.palette.secondary.main,
          position: "relative",
        }}
        spacing="8px"
      >
        <CheckCircle
          color="secondary"
          sx={{ position: "absolute", top: "12px", right: "12px" }}
        />
        <Stack>
          <Typography fontSize={22} fontFamily={"Space Grotesk"}>
            {price.name}
          </Typography>
          <Typography>{price.description}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {_price}
          </Typography>
          <Chip
            label={_schedule}
            sx={{ textTransform: "capitalize" }}
            size="small"
          />
        </Stack>
        {Boolean(price.trial) && price.trial! > 0 && (
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography fontSize={12}>Trial Period:</Typography>
            <Chip
              label={price.trial + " days"}
              sx={{ textTransform: "capitalize" }}
              size="small"
            />
          </Stack>
        )}
      </Stack>
    );
  }

  // Renders user profile, when they are already logged in, along with signout button.
  function RenderUser() {
    return (
      <Stack>
        <Typography
          fontSize={"30px"}
          fontWeight={"600"}
          sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
        >
          Hello, {FbAuth.currentUser?.displayName}!
        </Typography>

        <Typography sx={{ fontSize: "16px", opacity: 0.8 }}>
          Your subscription has expired, and after multiple failed attempts,
          your account has been restricted. <br />
          Please provide a valid billing details to continue your subscription.
        </Typography>
        <Stack
          justifyContent={"flex-start"}
          direction={"row"}
          alignItems={"flex-start"}
          sx={{ mt: "48px" }}
          spacing="8px"
        >
          <Typography color="#94969C" fontSize={"14px"}>
            Not {FbAuth.currentUser?.displayName}?
          </Typography>
          <Link
            onClick={() => signOut(FbAuth)}
            underline="none"
            sx={{ cursor: "pointer" }}
            color="secondary"
            fontSize={"14px"}
          >
            Logout
          </Link>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      p={"24px"}
      sx={{ minHeight: "100vh", position: "relative", overflow: "hidden" }}
      alignItems={"center"}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          maxWidth: "1320px",
          width: "100%",
        }}
      >
        <img src={NAV_LOGO} alt="Alpha Trading University Logo" />
      </Stack>

      <Stack
        flex={1}
        alignItems={"center"}
        justifyContent={"space-between"}
        direction={"row"}
        sx={{
          maxWidth: "1320px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "start",
          },
        }}
      >
        <div
          style={{
            background: "#3CD7CD",
            height: "500px",
            width: "500px",
            position: "absolute",
            filter: "blur(350px)",
            left: -400,
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <RenderUser />

        <Stack spacing={"18px"}>
          <Stack spacing="8px">
            <Typography>Choose a Plan</Typography>
            <Stack direction={"row"} spacing={"12px"}>
              {RenderPrice(props.plan)}
            </Stack>
          </Stack>

          <PaymentElement />

          {error === "checkout" && (
            <Typography color={"error"}>
              {checkoutError ||
                "An error occured during checkout. Please try again."}
            </Typography>
          )}

          <Button
            size="large"
            type="submit"
            id="submit"
            disabled={busy}
            sx={{ mt: "4px", height: "42px" }}
            variant="contained"
            onClick={getStarted}
          >
            {busy && (
              <Player
                autoplay
                loop
                src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                style={{ height: "100px" }}
              />
            )}
            {!busy && "Get started"}
          </Button>
          <div
            style={{
              background: "#3CD7CD",
              height: "500px",
              width: "500px",
              position: "absolute",
              filter: "blur(350px)",
              zIndex: -1,
              pointerEvents: "none",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
