import {
  Badge,
  Button,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LOGO } from "../../assets";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Player } from "@lottiefiles/react-lottie-player";
import ZInput from "../../components/ZInput";
import { Code, Email, Password } from "@mui/icons-material";
import { RestRequestPasswordReset } from "../../core/rest";
import { useLocation } from "react-router";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { FbAuth } from "../../core/firebase";
import ZCheckbox from "../../components/ZCheckBox";

export default function PagePasswordReset() {
  const [verified, setVerified] = useState(false);
  const [busy, setBusy] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);

  const [isChanged, setIsChanged] = useState(false);
  const loc = useLocation();
  const code = loc.state?.code;

  async function updatePassword() {
    try {
      if (password !== passwordConfirm) {
        setError("password");
        return;
      }
      const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
      if (!symPat.test(password)) {
        setError("password");
        return;
      }
      if (password.length < 8) {
        setError("password");
        return;
      }
      setBusy(true);
      await confirmPasswordReset(FbAuth, code, password);
      // Send verification email.
      enqueueSnackbar("Your password has been updated.", {
        variant: "success",
      });
      // IF continue url is set, we navigatte.
      setIsChanged(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error updating your password. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
      setError("error");
    }
    setBusy(false);
  }

  async function verifyCode() {
    try {
      setBusy(true);
      // Proceed.
      const email = await verifyPasswordResetCode(FbAuth, code);
      setEmail(email);
      setVerified(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying password reset code. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
      setError("error");
      setVerified(false);
    }
    setBusy(false);
  }

  useEffect(() => {
    verifyCode();
  }, []);

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  return (
    <Stack
      sx={{ minHeight: "100vh", transition: "all .2s", opacity: 1 }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack position={"relative"}>
        <img
          style={{
            top: "64px",
            left: "80px",
            pointerEvents: "none",
            height: isDesktop ? "128px" : "64px",
            margin: "48px",
          }}
          alt="Logo"
          src={LOGO}
        />
        <div
          style={{
            background: "#3CD7CD",
            height: "300px",
            width: "300px",
            position: "absolute",
            filter: "blur(220px)",
            zIndex: -1,
            bottom: 0,
            pointerEvents: "none",
          }}
        />
      </Stack>

      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderRadius: "38px",
          background:
            "linear-gradient(180deg, rgba(14, 11, 52, 0.1) 10%, rgba(60, 215, 205, 0.06) 100%)",
          border: "1px solid rgba(60, 215, 205, 0.2)",
          backdropFilter: "blur(10.5px)",
          px: "32px",
          py: "48px",
        }}
      >
        {busy && !verified && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Password sx={{ height: "64px", width: "64px" }} />
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Verifying
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                Please wait while we perform a few background checks...
              </Typography>
            </Stack>
          </>
        )}
        {verified && !isChanged && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Password sx={{ height: "64px", width: "64px" }} />
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Update Password
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                Please enter your new password and click save.
              </Typography>

              <ZInput
                text={password}
                errorText={
                  error === "password" ? "Please enter a valid password." : ""
                }
                onUpdate={(t) => setPassword(t)}
                label="Password"
                disabled={busy}
                important
                password
                placeholder="Create a password"
                startIcon={<Password />}
              />
              <ZInput
                text={passwordConfirm}
                errorText={
                  error === "password" ? "Please enter a valid password." : ""
                }
                onUpdate={(t) => setPasswordConfirm(t)}
                label="Confirm Password"
                disabled={busy}
                important
                password
                placeholder="Confirm your password"
                startIcon={<Password />}
              />
              <Stack spacing={"2px"} sx={{ alignSelf: "flex-start" }}>
                <ZCheckbox
                  value={passwordHasEnoughCharacters}
                  label="Must be at least 8 characters"
                />
                <ZCheckbox
                  value={passwordHasSymbol}
                  label="Must contain one special character"
                />
              </Stack>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={busy}
                onClick={updatePassword}
              >
                Update
              </Button>
            </Stack>
          </>
        )}
        {isChanged && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Password sx={{ height: "64px", width: "64px" }} />
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Password Updated
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
                Your password has been changed successfully.
              </Typography>
              <Button color="secondary" disabled={busy} href={"/login"}>
                Login
              </Button>
            </Stack>
          </>
        )}
        {error && (
          <>
            <Stack alignItems={"center"} spacing="12px">
              <Password sx={{ height: "64px", width: "64px" }} />
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6", fontFamily: "Space Grotesk" }}
              >
                Error
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#94969C",
                  textAlign: "center",
                }}
              >
                An error occured while updating your password. <br />
                Please try again.
              </Typography>
              <Button color="secondary" disabled={busy} href={"/login"}>
                Login
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}
