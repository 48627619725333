import React from 'react';

function IC_ALERT(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M10.362 2.015a5.195 5.195 0 0 1 3.276 0c1.06.352 1.892 1.186 2.728 2.359.834 1.169 1.755 2.798 2.942 4.899l.046.082c1.188 2.1 2.108 3.73 2.682 5.051.577 1.328.864 2.475.635 3.576a5.408 5.408 0 0 1-1.628 2.88c-.827.76-1.952 1.08-3.37 1.235-1.408.153-3.252.153-5.624.153h-.098c-2.372 0-4.215 0-5.625-.153-1.417-.154-2.542-.475-3.369-1.235a5.408 5.408 0 0 1-1.628-2.88c-.23-1.101.058-2.248.635-3.575.574-1.322 1.494-2.951 2.682-5.052l.046-.082c1.187-2.1 2.108-3.73 2.941-4.899.837-1.173 1.669-2.007 2.729-2.359zM11 17c0-.552.446-1 .995-1h.01c.55 0 .995.448.995 1s-.446 1-.995 1h-.01A.998.998 0 0 1 11 17zm0-4a1 1 0 1 0 2 0V9a1 1 0 1 0-2 0v4z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_ALERT;