import React from 'react';

function IC_FILTER_EDIT(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M6.184 2.25c-.771 0-1.426 0-1.942.07-.546.075-1.069.243-1.469.683-.404.443-.514.978-.522 1.525-.008.51.073 1.145.169 1.884l.007.054c.033.262.084.515.19.768.106.257.253.477.437.694.979 1.162 2.797 3.26 5.357 5.173a.302.302 0 0 1 .103.215c.282 3.432.54 5.78.677 6.94.066.56.38 1.05.855 1.309.493.269 1.104.255 1.617-.108.187-.132.415-.27.688-.436l.08-.048c.295-.18.63-.385.946-.614.601-.435 1.308-1.072 1.48-1.986a34.59 34.59 0 0 0 .322-2.146c.039-.313.058-.47-.011-.566-.07-.095-.261-.133-.644-.207a2.5 2.5 0 0 1-1.287-4.221l.525-.526c.334-.333.5-.5.5-.707 0-.207-.166-.374-.5-.707l-.525-.525a2.5 2.5 0 0 1 0-3.536 2.502 2.502 0 0 1 3.537 0l.667.667c.267.266.4.4.566.4.165 0 .299-.134.566-.4l.666-.667a2.496 2.496 0 0 1 1.988-.722c.274.023.411.035.478-.036.066-.071.049-.18.014-.4-.062-.393-.206-.766-.484-1.071-.4-.44-.922-.608-1.469-.683-.516-.07-1.17-.07-1.942-.07H6.184z" fill={fill}/>
		<path d="M14.293 6.293a1 1 0 0 1 1.414 0L18 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L19.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L18 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 0 1 0-1.414z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_FILTER_EDIT;