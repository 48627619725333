import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import StaticAboutCard from "./aboutCard";
import {
  IC_ONLINE_MEETING,
  IC_ONLINE_TRAINING,
  IC_SCHOOL,
  IMG_ARROW,
} from "../../../assets";
import { motion, useScroll } from "framer-motion";

export default function StaticAbout() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const scrollInfo = useScroll();
  return (
    <Stack
      alignItems={"center"}
      spacing={"100px"}
      sx={{ position: "relative" }}
    >
      <Stack
        spacing={isDesktop ? "30px" : "15px"}
        sx={{
          maxWidth: "860px",
          width: "100%",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h4"
          component={"div"}
          id="learn-more"
          color={"secondary"}
          sx={{
            fontSize: 24,
            fontWeight: 500,
            fontFamily: "Helvetica-Medium",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
              textAlign: "center",
            },
          }}
        >
          WHAT IS ATU?
        </Typography>
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 400,
            fontFamily: "Space Grotesk",
            letterSpacing: "-1.08px",
            [theme.breakpoints.down("md")]: {
              fontSize: "20px",
              textAlign: "center",
              paddingRight: "24px",
              paddingLeft: "24px",
            },
          }}
        >
          Join ATU, where you'll master fundamentals and psychology, analyze
          markets and charts on macro and micro levels and perfect your trading
          strategies. <br />
          <br />
          Engage in our live market reports and calls alongside professional
          traders.
        </Typography>

        {/* Cards  */}
        <Stack
          spacing="16px"
          direction={isDesktop ? "row" : "column"}
          sx={{
            pt: "30px",
            [theme.breakpoints.down("md")]: {
              alignItems: "center",
            },
          }}
        >
          <StaticAboutCard
            img={IC_SCHOOL}
            title="Education"
            bulletPoints={[
              "Psychology",
              "Fundamentals",
              "Currencies",
              "Commodities",
              "Strategies",
            ]}
            learnMoreUrl="/about"
          />

          <StaticAboutCard
            img={IC_ONLINE_MEETING}
            title="Live calls"
            bulletPoints={[
              "Live Lessons",
              "Chart Analysis",
              "Trades Break Downs",
              "Weekly Report Breakdowns",
            ]}
            learnMoreUrl="/about"
          />

          <StaticAboutCard
            img={IC_ONLINE_TRAINING}
            title="Reports & analysis"
            bulletPoints={[
              "Market updates",
              "Pairs Bias'",
              "News events and impacts",
            ]}
            learnMoreUrl="/about"
          />
        </Stack>

        {/* CTA */}
        <Stack>
          <Stack sx={{ alignSelf: "center", position: "relative" }}>
            <div
              style={{
                background: "#3CD7CD",
                height: "500px",
                width: "500px",
                position: "absolute",
                filter: "blur(350px)",
                zIndex: 0,
                alignSelf: "center",
                pointerEvents: "none",
                opacity: isDesktop ? 1 : 0.4,
              }}
            />
          </Stack>
          <Button
            href="/join"
            color="secondary"
            variant="contained"
            size="large"
            sx={{
              alignSelf: "center",
              width: "209px",
              fontSize: 20,
              fontFamily: "Helvetica-Medium",
              marginTop: "30px",
            }}
          >
            Join ATU
          </Button>
        </Stack>
      </Stack>
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute" }}
        initial={{ left: -100, bottom: 0, opacity: 0 }}
        animate={{ left: 1000, bottom: 1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute", rotate: "180deg" }}
        initial={{ right: -100, bottom: 0, opacity: 0 }}
        animate={{ right: 1000, bottom: -1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
    </Stack>
  );
}
