import React from 'react';

function Ic_folders(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M10.913 1.75h2.174c.87 0 1.627 0 2.222.085.632.091 1.29.305 1.75.91.37.483.463 1.027.437 1.556a1 1 0 1 1-1.998-.102.706.706 0 0 0-.01-.202.095.095 0 0 0-.02-.041c-.007-.01-.018-.025-.066-.047a1.392 1.392 0 0 0-.378-.094c-.434-.063-1.047-.065-2.009-.065h-2.03c-.962 0-1.575.002-2.01.065-.205.03-.317.066-.377.094-.048.022-.06.037-.067.047a.096.096 0 0 0-.018.04.71.71 0 0 0-.011.203 1 1 0 0 1-1.998.102c-.027-.529.067-1.073.436-1.557.46-.604 1.119-.818 1.751-.909.594-.085 1.353-.085 2.222-.085z" fill={fill} fillRule="evenodd"/>
		<path d="M9.416 5.75h5.169c1.145 0 2.117 0 2.872.113.799.12 1.566.394 2.085 1.118.247.343.389.721.448 1.125a1 1 0 0 1-1.98.288.523.523 0 0 0-.092-.246c-.065-.091-.197-.223-.758-.307-.588-.088-1.408-.091-2.654-.091H9.495c-1.246 0-2.067.003-2.655.09-.56.085-.693.217-.758.308a.523.523 0 0 0-.092.246 1 1 0 1 1-1.98-.288c.06-.404.201-.782.448-1.125.52-.724 1.286-.998 2.086-1.118.754-.113 1.726-.113 2.872-.113z" fill={fill} fillRule="evenodd"/>
		<path d="M16.505 9.75c1.174 0 2.14 0 2.882.11.775.116 1.468.374 1.933 1.022.462.645.492 1.387.366 2.167-.121.752-.413 1.688-.77 2.83l-.736 2.36c-.448 1.434-.918 2.523-1.808 3.194-.895.674-2.068.817-3.582.817H9.21c-1.514 0-2.687-.143-3.582-.817-.89-.67-1.36-1.76-1.808-3.194l-.736-2.36c-.357-1.143-.649-2.078-.77-2.83-.126-.78-.096-1.522.366-2.167.465-.648 1.158-.906 1.933-1.021.742-.111 1.708-.111 2.882-.111h9.01z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_folders;