import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  RadioGroup,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Ic_bookEdit from "../assets/icons/ui/ic_book-edit";
import ZInput from "../components/ZInput";
import { useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { RtmGetConfigA, RtmSetConfigA } from "../core/rtm/admin";
import ZAutoComplete, { AutocompleteOption } from "../components/ZAutoComplete";
import { PlatformPricing } from "../types/PlatformPricing";
import ZRadio from "../components/ZRadio";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";

const CURRENCIES: AutocompleteOption[] = [
  {
    id: "usd",
    label: "USD $",
  },
  {
    id: "gbp",
    label: "GBP £",
  },
  {
    id: "eur",
    label: "EUR €",
  },
  {
    id: "aud",
    label: "AUD $",
  },
];

const SCHEDULES: AutocompleteOption[] = [
  { label: "Daily", id: "daily" },
  { label: "Weekly", id: "weekly" },
  { label: "Monthly", id: "monthly" },
  { label: "Every 3 months", id: "3-month" },
  { label: "Every 6 months", id: "6-month" },
  { label: "Yearly", id: "yearly" },
];

export default function AddPlanDialog(props: {
  onClose?: (result?: boolean) => void;
  editing?: PlatformPricing;
  editingIndex?: number;
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [currency, setCurrency] = useState<AutocompleteOption>();
  const [scheduleKind, setScheduleKind] = useState<"lifetime" | "recurring">(
    "lifetime"
  );
  const [schedule, setSchedule] = useState<AutocompleteOption>();
  const [price, setPrice] = useState("");
  const isPriceValid = useMemo(() => {
    return Math.floor(parseFloat(price) * 100) >= 300;
  }, [price]);
  const [error, setError] = useState("");
  const [trial, setTrial] = useState(0);
  const [busy, setBusy] = useState(false);

  async function add() {
    try {
      setError("");
      if (!name) {
        setError("title");
        return;
      }
      if (!description) {
        setError("description");
        return;
      }
      if (!isPriceValid) {
        setError("price");
        return;
      }
      if (scheduleKind === "recurring" && !schedule) {
        setError("schedule");
        return;
      }
      setBusy(true);
      // Get config
      const _pricingConfig = await RtmGetConfigA<PlatformPricing[]>("pricing");
      const _existingPrices = (_pricingConfig.value as PlatformPricing[]) || [];
      const _price: PlatformPricing = {
        amount: Math.floor(parseFloat(price) * 100),
        currency: currency?.id!,
        description: description,
        id: name.toLowerCase().replaceAll(" ", "-"),
        name: name,
        trial: trial,
        kind: scheduleKind,
        schedule: schedule?.id,
      };
      if (props.editingIndex! > -1) {
        _existingPrices.splice(props.editingIndex!, 1, _price);
      } else {
        _existingPrices.push(_price);
      }

      const _r = await RtmSetConfigA("pricing", _existingPrices);
      if (Boolean(_r)) {
        enqueueSnackbar("Price plan added.  ", { variant: "success" });
        props.onClose?.(_r);
      }
    } catch (err: any) {
      console.error("Error creating a price plan. ", err);
      enqueueSnackbar("Error adding new price plan. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  function load() {
    if (props.editing && props.editingIndex! > -1) {
      // load
      setName(props.editing.name);
      setDescription(props.editing.description);
      const _priceInfo = parseStripeAmount(props.editing.amount || 0);

      setPrice(`${_priceInfo.dollars}.${_priceInfo.cents}`);
      setSchedule(SCHEDULES.find((s) => s.id === props.editing?.schedule));
      setScheduleKind(props.editing.kind);
      setTrial(props.editing.trial || 0);
      setCurrency(CURRENCIES.find((c) => c.id === props.editing?.currency)!);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog fullWidth open>
      {busy && <LinearProgress />}
      <DialogTitle>
        <Stack direction="row" spacing={"10px"} alignItems={"center"}>
          <SvgIcon>
            <Ic_bookEdit />
          </SvgIcon>
          <Typography
            sx={{ fontSize: 22, fontWeight: 500, fontFamily: "Space Grotesk" }}
          >
            Add a Plan
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={"8px"}>
          <Typography>Basic information</Typography>
          <ZInput
            text={name}
            onUpdate={(t) => setName(t)}
            errorText={error === "title" ? "Name cannot be blank." : undefined}
            label="Plan Name"
            placeholder="e.g. Basic"
          />
          <ZInput
            text={description}
            onUpdate={(t) => setDescription(t)}
            errorText={
              error === "description"
                ? "Short description cannot be blank."
                : undefined
            }
            multiline
            rows={2}
            label="Short Description"
            placeholder="Description will be shown on plan's card."
          />
          <Typography>Pricing</Typography>
          {/* Currency  */}
          <Stack sx={{ minWidth: "300px" }}>
            <ZAutoComplete
              important
              key={currency as any}
              label="Select Currency"
              options={CURRENCIES}
              value={currency}
              disabled={busy}
              onUpdate={(l) => setCurrency(l as any)}
              helperText="Invoices will be created in this currency."
            />
          </Stack>

          {/* Schedule  */}
          <RadioGroup radioGroup="subscriptionKind">
            <Typography>Type of Subscription</Typography>
            <ZRadio
              checked={scheduleKind === "lifetime"}
              onUpdate={(c) => setScheduleKind("lifetime")}
              label="Lifetime"
              group="subscriptionKind"
              labelProps={{ fontSize: 16, fontWeight: 500 }}
            />
            <ZRadio
              checked={scheduleKind === "recurring"}
              onUpdate={(c) => setScheduleKind("recurring")}
              label="Recurring"
              group="subscriptionKind"
              labelProps={{ fontSize: 16, fontWeight: 500 }}
            />
          </RadioGroup>

          {scheduleKind === "recurring" && (
            <Stack sx={{ minWidth: "300px" }}>
              <ZAutoComplete
                options={SCHEDULES}
                value={schedule}
                key={schedule as any}
                onUpdate={(v: any) => setSchedule(v)}
                label="Recurring Schedule"
                placeholder="Select a schedule..."
                helperText="Select how often to charge the user."
                important
              />
            </Stack>
          )}

          {/* Amount  */}
          <ZInput
            startAdornment={
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ width: "48px", height: "100%" }}
              >
                <Typography>
                  {getCurrencySymbol(currency?.id || "usd")}
                </Typography>
              </Stack>
            }
            text={price}
            onUpdate={(p) => setPrice(p)}
            label="Subscription Price"
            placeholder="5.0"
            helperText="Price must be at least $3."
            important
            errorText={isPriceValid ? undefined : "Invalid price. "}
          />

          <ZInput
            endAdornment={
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ width: "100px", height: "100%", opacity: 0.6 }}
              >
                <Typography>days</Typography>
              </Stack>
            }
            text={trial.toString()}
            onUpdate={(p) => setTrial(parseInt(p || "0"))}
            label="Trial Period"
            placeholder="0"
            helperText="Set trial period in number of days or 0 to disable."
            important
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "14px" }} spacing={"8px"}>
          <Button
            disabled={busy}
            onClick={() => props.onClose?.(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={busy || Boolean(error) || !isPriceValid}
            variant="contained"
            onClick={add}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
