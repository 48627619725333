import React from 'react';

function Ic_customers(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M7.503 13.147c-.287.154-.568.315-.775.443-.494.29-1.426.834-2.1 1.518-.433.442-1.004 1.176-1.11 2.185a3.06 3.06 0 0 0 .035.894c.054.284.08.426.009.5-.072.075-.192.056-.43.02-1.025-.156-1.717-.714-2.284-1.323-.425-.454-.644-.978-.59-1.538.051-.525.332-.948.613-1.258.444-.488 1.097-.902 1.489-1.15.083-.053.155-.098.21-.135a6.113 6.113 0 0 1 4.885-.876c.388.096.582.143.6.28.016.136-.173.237-.552.44z" fill={fill}/>
		<path d="M6.487 5.286c.19.029.285.043.334.122.048.079.014.183-.053.393A5.496 5.496 0 0 0 6.5 7.5c0 1.24.41 2.384 1.103 3.304.132.175.198.263.177.354-.02.09-.105.134-.275.223a3.25 3.25 0 1 1-1.018-6.095z" fill={fill}/>
		<path d="M16.497 13.147c.287.154.569.315.775.443.495.29 1.427.834 2.1 1.518.434.442 1.005 1.176 1.111 2.185.033.312.018.61-.036.894-.053.284-.08.426-.008.5.071.075.191.056.43.02 1.024-.156 1.716-.714 2.284-1.323.424-.454.644-.978.59-1.538-.052-.525-.333-.948-.614-1.258-.443-.488-1.097-.902-1.488-1.15a10.61 10.61 0 0 1-.21-.135 6.113 6.113 0 0 0-4.886-.876c-.388.096-.582.143-.599.28-.017.136.173.237.551.44z" fill={fill}/>
		<path d="M16.398 10.804c-.132.175-.198.263-.178.354.02.09.105.134.275.223a3.25 3.25 0 1 0 1.018-6.095c-.19.029-.285.043-.333.122-.05.079-.015.183.053.393.174.535.267 1.106.267 1.699 0 1.24-.41 2.384-1.102 3.304z" fill={fill}/>
		<path d="M7.691 14.473a8.206 8.206 0 0 1 8.622 0c.078.048.176.106.29.172.511.3 1.325.776 1.88 1.34.348.354.696.837.759 1.44.068.643-.206 1.24-.727 1.756-.862.852-1.93 1.569-3.329 1.569H8.819c-1.4 0-2.467-.717-3.329-1.57-.521-.515-.795-1.112-.727-1.756.064-.602.411-1.085.76-1.44.555-.563 1.368-1.039 1.88-1.339.113-.066.212-.124.29-.172z" fill={fill}/>
		<path d="M7.752 7.5a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_customers;