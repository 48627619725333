import { Button, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import AppBar from "../../components/AppBar";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { aCurrentUser } from "../../core/atoms";
import { RtmGetProfile } from "../../core/rtm";
import { enqueueSnackbar } from "notistack";
import { Route, Routes, useNavigate } from "react-router";
import { Role } from "../../types/Role";
import SectionDashboard from "./user";
import SectionPlansA from "./admin/plans";
import SectionUsersA from "./admin/users";
import SectionChapterA from "./admin/chapter";
import SectionChaptersA from "./admin/chapters";
import SectionInvoicesA from "./admin/invoices";
import SectionAdminsA from "./admin/admins";
import SectionProfile from "./profile";
import SectionFundamentalsA from "./admin/fundamentals";
import SectionLibrary from "./user/library";
import SectionChapter from "./user/chapter";
import SectionFundamentals from "./user/fundamentals";
import SectionEventsA from "./admin/events";
import SectionEvents from "./user/events";
import SectionPlatformConfigurationA from "./admin/configuration";
import SectionDiscordConfigurationA from "./admin/discord";
import { Logout } from "@mui/icons-material";
import { RtmSignout } from "../../core/rtm/user";
import SectionAdminDashboard from "./admin";
import SectionAffiliatesA from "./admin/affiliates";
import SectionActivitiesA from "./admin/activity";
import { Helmet } from "react-helmet";
import SectionSalesA from "./admin/sales";
import SectionReportsA from "./admin/reports";

export default function PageDashboard() {
  const theme = useTheme();
  const [currentUser, setCurrentUser] = useRecoilState(aCurrentUser);
  const nav = useNavigate();

  async function loadProfile() {
    try {
      const _profile = await RtmGetProfile();
      if (
        _profile &&
        _profile.subscription === null &&
        _profile.role === Role.User
      ) {
        nav("/expired");
      }
      setCurrentUser(_profile);
    } catch (err: any) {
      enqueueSnackbar("Error loading profile. ", { variant: "error" });
    }
  }
  async function signOut() {
    try {
      await RtmSignout();
      window.location.reload();
    } catch (err: any) {
      console.error("Error signing out. ", err);
    }
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack sx={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      <Helmet>
        <title>Dashboard - Alpha Trading University</title>
      </Helmet>
      {currentUser?.impersonator && (
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ p: "8px", background: theme.palette.warning.dark }}
        >
          <Typography fontSize={20} fontFamily={"Space Grotesk"}>
            Impersonation Mode Enabled
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
            <Typography>
              Signed in as <b>{currentUser.email}</b> by{" "}
              <b>{currentUser.impersonator.email}</b>
            </Typography>
            <Button
              endIcon={
                <SvgIcon>
                  <Logout />
                </SvgIcon>
              }
              variant="contained"
              color="inherit"
              sx={{ height: "40px" }}
              onClick={signOut}
            >
              Exit
            </Button>
          </Stack>
        </Stack>
      )}
      <Stack
        direction={"row"}
        flex={1}
        sx={{
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background: "#3CD7CD",
            height: "200px",
            width: "200px",
            position: "absolute",
            filter: "blur(150px)",
            zIndex: 0,
            alignSelf: "center",
            pointerEvents: "none",
          }}
        />
        {/* The sidebar  */}
        <Sidebar />
        {/* Content Box: Contains the app bar and content view  */}
        <Stack
          flex={1}
          sx={{ background: "#111", zIndex: 1, overflow: "hidden" }}
        >
          <AppBar />
          <Stack
            flex={1}
            sx={{
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                overflow: "hidden",
                width: "100%",
                maxWidth: "1920px",
              }}
              flex={1}
            >
              {/* The content  */}
              {currentUser?.role === Role.Administrator && (
                <Routes>
                  <Route
                    path="/lms/chapters/:id"
                    element={<SectionChapterA />}
                  />
                  <Route path="/lms/chapters" element={<SectionChaptersA />} />
                  <Route
                    path="/lms/fundamentals"
                    element={<SectionFundamentalsA />}
                  />
                  <Route path="/lms/news" element={<SectionEventsA />} />
                  <Route path="/payments/plans" element={<SectionPlansA />} />
                  <Route
                    path="/payments/invoices"
                    element={<SectionInvoicesA />}
                  />
                  <Route path="/analytics/sales" element={<SectionSalesA />} />
                  <Route
                    path="/analytics/progress"
                    element={<SectionReportsA />}
                  />
                  <Route path="/iam/users" element={<SectionUsersA />} />
                  <Route path="/iam/admins" element={<SectionAdminsA />} />
                  <Route
                    path="/iam/affiliates"
                    element={<SectionAffiliatesA />}
                  />
                  <Route
                    path="/iam/activity"
                    element={<SectionActivitiesA />}
                  />
                  <Route
                    path="/settings/platform"
                    element={<SectionPlatformConfigurationA />}
                  />
                  <Route
                    path="/settings/discord"
                    element={<SectionDiscordConfigurationA />}
                  />
                  <Route path="/profile" element={<SectionProfile />} />
                  <Route path="/" element={<SectionAdminDashboard />} />
                </Routes>
              )}
              {currentUser?.role === Role.Moderator && (
                <Routes>
                  <Route
                    path="/lms/chapters/:id"
                    element={<SectionChapterA />}
                  />
                  <Route path="/lms/chapters" element={<SectionChaptersA />} />
                  <Route
                    path="/lms/fundamentals"
                    element={<SectionFundamentalsA />}
                  />
                  <Route path="/lms/news" element={<SectionEventsA />} />

                  <Route path="/profile" element={<SectionProfile />} />
                  <Route path="/" element={<SectionAdminDashboard />} />
                </Routes>
              )}
              {currentUser?.role === Role.User && (
                <Routes>
                  {/* <Route path="/lms/courses/:id" element={<SectionCourseA />} />
              <Route path="/lms/courses" element={<SectionCoursesA />} /> */}
                  <Route
                    path="/fundamentals"
                    element={<SectionFundamentals />}
                  />
                  <Route path="/events" element={<SectionEvents />} />
                  <Route
                    path="/library/:id/:lesson"
                    element={<SectionChapter />}
                  />
                  <Route path="/library/:id" element={<SectionChapter />} />
                  <Route path="/library" element={<SectionLibrary />} />
                  <Route path="/profile" element={<SectionProfile />} />
                  <Route path="/" element={<SectionDashboard />} />
                </Routes>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
