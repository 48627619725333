import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { User } from "../types/User";
import { RtmCancelSubscriptionA } from "../core/rtm/admin";
import IC_USER_EDIT from "../assets/icons/ui/IC_USER_EDIT";
import moment from "moment";
import IC_ALERT from "../assets/icons/ui/IC_ALERT";

export default function CancelSubscriptionADialog(props: {
  user: User;
  onClose?: (result?: boolean) => void;
}) {
  const [busy, setBusy] = useState(false);

  async function cancel() {
    try {
      setBusy(true);
      await RtmCancelSubscriptionA(props.user!.uid!);
      enqueueSnackbar(
        `Subscription for user ${props.user.email} has been cancelled.`,
        {
          variant: "success",
        }
      );
      props.onClose?.(true);
    } catch (err: any) {
      enqueueSnackbar("Error cancelling subscription. Error: " + err.message, {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  return (
    <Dialog open hideBackdrop onClose={() => props.onClose?.(false)} fullWidth>
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IC_USER_EDIT width={24} height={24} />
          <Typography>Cancel User Subscription</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="16px" sx={{ px: "32px", py: "24px" }}>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={14}
              fontWeight={400}
              sx={{ minWidth: "150px" }}
            >
              Name
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              {props.user?.firstName} {props.user?.lastName}
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={14}
              fontWeight={400}
              sx={{ minWidth: "150px" }}
            >
              Email
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              {props.user?.email}
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={14}
              fontWeight={400}
              sx={{ minWidth: "150px" }}
            >
              Current Role
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              {props.user?.role}
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={14}
              fontWeight={400}
              sx={{ minWidth: "150px" }}
            >
              Subscriber Since
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              {moment
                .unix(props.user?.subscription?.created!)
                .format("MMM DD, YYYY hh:mm A")}
            </Typography>
          </Stack>

          <Alert
            color="warning"
            variant="filled"
            icon={<IC_ALERT width={24} height={24} />}
          >
            <AlertTitle>Warning</AlertTitle>
            <Typography>
              The user's subscription will be cancelled immediately and this
              action cannot be undone.
            </Typography>
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ px: "14px", py: "12px" }} spacing="12px">
          <Button
            disabled={busy}
            onClick={() => props.onClose?.(false)}
            size="large"
            color="inherit"
          >
            Nevermind
          </Button>
          <Button
            onClick={cancel}
            disabled={busy}
            size="large"
            variant="contained"
            color="error"
          >
            Cancel Subscription
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
