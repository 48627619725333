import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { User } from "../types/User";
import { RtmAddUserA } from "../core/rtm/admin";
import ZInput from "../components/ZInput";
import ZAutoComplete from "../components/ZAutoComplete";
import IC_COPY from "../assets/icons/ui/IC_COPY";
import IC_USER_ADD from "../assets/icons/ui/IC_USER_ADD";

export default function AddUserDialog(props: { onClose?: () => void }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const [busy, setBusy] = useState(false);

  const [addedUser, setAddedUser] = useState<{
    user: User;
    password: string;
  }>();

  async function addUser() {
    try {
      setBusy(true);
      const u = await RtmAddUserA({
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: role as any,
      });
      if (u) {
        enqueueSnackbar("User added successfully. ", { variant: "success" });
        setAddedUser(u);
      }
    } catch (err: any) {
      enqueueSnackbar("Error adding a new user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog open hideBackdrop onClose={props.onClose} fullWidth>
      <DialogTitle>
        <Stack direction={"row"} spacing={"24px"} sx={{ py: "12px" }}>
          <IC_USER_ADD width={24} height={24} />
          <Typography>Add a user</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="16px" sx={{ px: "14px", pt: "24px" }}>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              className="required"
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Name
            </Typography>
            <Stack direction={"row"} flex={1} spacing={"8px"}>
              <ZInput
                text={firstName}
                onUpdate={(t) => setFirstName(t)}
                fullWidth
                placeholder="John"
              />
              <ZInput
                text={lastName}
                onUpdate={(t) => setLastName(t)}
                fullWidth
                placeholder="Doe"
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              className="required"
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Email Address
            </Typography>
            <ZInput
              text={email}
              onUpdate={(t) => setEmail(t)}
              fullWidth
              placeholder="someone@example.com"
            />
          </Stack>

          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              className="required"
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Role
            </Typography>
            <ZAutoComplete
              options={[
                { label: "Administrator", id: "administrator" },
                { label: "User", id: "user" },
                { label: "Moderator", id: "moderator" },
              ]}
              placeholder="Select a role.."
              onUpdate={(v) => setRole((v as any)?.label || "")}
              value={{ label: role } as any}
              important
            />
          </Stack>

          {addedUser && (
            <>
              <Alert color="info">
                <Typography>User added successfully!</Typography>
                <Typography>Password</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <Typography fontFamily={"Space Grotesk"}>
                    {addedUser.password}
                  </Typography>
                  <IconButton
                    onClick={(te) => {
                      navigator.clipboard?.writeText(addedUser.password);
                      enqueueSnackbar("Password copied to clipboard.");
                    }}
                  >
                    <IC_COPY width={24} height={24} />
                  </IconButton>
                </Stack>
              </Alert>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "32px" }} spacing="12px">
          <Button
            disabled={busy}
            onClick={props.onClose}
            size="large"
            variant="text"
            color="secondary"
          >
            {addedUser ? "Close" : "Cancel"}
          </Button>
          {!addedUser && (
            <Button
              disabled={busy}
              onClick={addUser}
              size="large"
              variant="contained"
              color="primary"
            >
              Add User
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
