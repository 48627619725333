import {
  Badge,
  Button,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { FbAuth } from "../core/firebase";
import Ic_notification from "../assets/icons/ui/ic_notification";
import Ic_profile from "../assets/icons/ui/ic_profile";
import { useNavigate } from "react-router";
import NotificationsPanel from "./NotificationsPanel";
import { RtmGetUnreadNotificationsCount } from "../core/rtm";
import { useRecoilState } from "recoil";
import { aCurrentUser, aShowSidebar } from "../core/atoms";
import Ic_dashboard from "../assets/icons/ui/ic_dashboard";
import Ic_library from "../assets/icons/ui/ic_library";
import Ic_reports from "../assets/icons/ui/ic_reports";
import Ic_book from "../assets/icons/ui/ic_book";
import IC_NOTEBOOK from "../assets/icons/ui/IC_NOTEBOOK";
import IC_NEWS from "../assets/icons/ui/IC_NEWS";
import IC_MENU from "../assets/icons/ui/IC_MENU";
import IC_REMOVE from "../assets/icons/ui/IC_REMOVE";
import IC_USER_ADD from "../assets/icons/ui/IC_USER_ADD";
import { enqueueSnackbar } from "notistack";

export default function AppBar() {
  const nav = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState<any>(null);
  const [showNotifications, setShowNotifications] = useState<any>(null);
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);
  const [profile, setProfile] = useRecoilState(aCurrentUser);
  const [unreadCount, setUnreadCount] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  async function loadCount() {
    try {
      const _count = await RtmGetUnreadNotificationsCount();
      setUnreadCount(_count);
    } catch (err: any) {
      console.error("Error loading notifications count. ", err);
    }
  }

  async function logout() {
    setProfile(undefined);
    signOut(FbAuth);
    window.location.href = "/login";
  }

  useEffect(() => {
    loadCount();
  }, []);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        background: "#0E0B3424",
        backdropFilter: "blur(12px)",
        borderBottom: "1px solid #3CD7CD22",
        width: "100%",
        height: "73px",
        px: "24px",
      }}
    >
      <Stack direction={"row"}>
        {!isDesktop && (
          <>
            <IconButton onClick={() => setShowSidebar(!showSidebar)}>
              <SvgIcon>
                {showSidebar && <IC_REMOVE />}
                {!showSidebar && <IC_MENU />}
              </SvgIcon>
            </IconButton>
          </>
        )}
      </Stack>
      <Stack direction={"row"} sx={{ position: "relative" }} spacing={"12px"}>
        {profile?.referralCode && (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                "https://alphatradinguniversity.com/join?ref=" +
                  profile?.referralCode
              );
              enqueueSnackbar("Invite link copied to clipboard. ", {
                variant: "success",
              });
            }}
            color="inherit"
            startIcon={
              <SvgIcon>
                <IC_USER_ADD />
              </SvgIcon>
            }
          >
            Invite a Friend
          </Button>
        )}
        <IconButton
          size="large"
          sx={{ background: "rgba(14, 11, 52, 0.25)" }}
          onClick={(e) => setShowNotifications(e.currentTarget)}
        >
          <Badge
            variant="standard"
            badgeContent={unreadCount}
            color={unreadCount > 0 ? "primary" : undefined}
          >
            <Ic_notification width={24} height={24} />
          </Badge>
        </IconButton>
        <Popover
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => setShowNotifications(null)}
          open={Boolean(showNotifications)}
          anchorEl={showNotifications}
          slotProps={{
            paper: {
              sx: {
                background: "#222",
              },
            },
          }}
        >
          <NotificationsPanel />
        </Popover>
        <IconButton
          onClick={(e) => setShowProfileMenu(e.currentTarget)}
          size="large"
          sx={{ background: "rgba(14, 11, 52, 0.25)" }}
        >
          <Ic_profile width={24} height={24} />
        </IconButton>
        <Popover
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => setShowProfileMenu(null)}
          open={Boolean(showProfileMenu)}
          anchorEl={showProfileMenu}
        >
          <MenuItem
            onClick={() => {
              nav("/dashboard/profile");
              setShowProfileMenu(null);
            }}
          >
            Profile
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Popover>
      </Stack>
    </Stack>
  );
}
