import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Pagination,
  Popover,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { RtmGetEvents } from "../../../core/rtm/user";
import moment from "moment-timezone";
import { ArrowOutward, ChevronLeft } from "@mui/icons-material";
import ZInput from "../../../components/ZInput";
import Ic_search from "../../../assets/icons/ui/ic_search";
import { CalendarIcon, DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DailyEvent } from "../../../types/DailyEvent";
import NamedChip from "../../../components/NamedChip";
import { GetValueForValues } from "../../../core/helper";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import LoadingView from "../../../components/Loading";
import { aShowSidebar } from "../../../core/atoms";
import { useRecoilState } from "recoil";

export function EventItem(props: { data: DailyEvent }) {
  const event = props.data;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const editor = useCreateBlockNote({
    initialContent: event.comments ? JSON.parse(event.comments) : undefined,
  });

  const expandable = Boolean(event.author && event.comments);

  const [expanded, setExpanded] = useState(false);

  return (
    <Stack sx={{ borderRadius: "8px", overflow: "hidden" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          height: "60px",
          background: expanded ? "#333" : "#222",
          p: "8px",
          transition: "all .3s",
          cursor: "pointer",
          ":hover": {
            background: "#333",
          },
          [theme.breakpoints.down("md")]: {
            height: "auto",
          },
        }}
        spacing="8px"
        onClick={() => setExpanded(!expanded && expandable)}
      >
        <Typography
          sx={{
            opacity: 0.8,
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
              maxWidth: "60px",
            },
          }}
        >
          {moment.unix(event.date).format("ddd, MMM DD, YYYY hh:mm A")}
        </Typography>
        <Divider orientation="vertical" sx={{ height: "24px" }} />
        <Stack
          direction={"row"}
          flex={1}
          alignItems={"center"}
          rowGap={"8px"}
          columnGap={"8px"}
          sx={{
            [theme.breakpoints.down("md")]: {
              flexWrap: "wrap",
              alignItems: "flex-start",
            },
          }}
        >
          <Typography
            fontFamily={"Space Grotesk"}
            fontSize={18}
            fontWeight={500}
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
            }}
          >
            {event.title}
          </Typography>

          <NamedChip
            label="Impact"
            value={event.impact}
            color={GetValueForValues(
              [
                { option: "Low", value: undefined },
                { option: "Medium", value: theme.palette.info.main },
                { option: "High", value: theme.palette.error.main },
              ],
              event.impact
            )}
          />
          {event.previous && (
            <NamedChip label="Previous" value={event.previous} />
          )}
          {event.forecast && (
            <NamedChip label="Forecast" value={event.forecast} />
          )}
          {event.country && (
            <NamedChip label="Currency" value={event.country} />
          )}
        </Stack>

        {expandable && (
          <SvgIcon
            sx={{
              transition: "all .5s",
              transform: expanded ? undefined : "rotate(-90deg)",
            }}
          >
            <ChevronLeft />
          </SvgIcon>
        )}
      </Stack>
      {expandable && expanded && (
        <Stack sx={{ background: "#222", pb: "24px" }}>
          <BlockNoteView
            theme={darkDefaultTheme}
            editor={editor}
            editable={false}
          />

          <Stack spacing={"8px"} sx={{ mt: "48px", px: "24px" }}>
            <Typography>Source</Typography>
            <Typography
              sx={{
                cursor: "pointer",
                ":hover": { textDecoration: "underline" },
              }}
              onClick={() => window.open(event.url, "_blank")}
            >
              {event.url}
              <ArrowOutward />
            </Typography>
          </Stack>
          {event.tags?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px", px: "24px" }}>
              <Typography>Tags</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                {event.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            </Stack>
          )}
          {typeof event.author !== "string" && (
            <Stack spacing={"8px"} sx={{ mt: "18px", px: "24px" }}>
              <Typography>Authored By</Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  alt={event.author.name}
                  src={event.author.image}
                  sx={{ background: "#444" }}
                >
                  {event.author.name[0]}
                </Avatar>
                <Typography>{event.author.name}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default function SectionEvents() {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [data, setData] = useState<DailyEvent[]>([]);
  const [busy, setBusy] = useState(false);
  const [sort, setSort] = useState<{
    type: "created" | "title" | "date";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    date: "today" | "week" | "month" | number;
  }>({ date: "week" });

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  async function load(page: number) {
    try {
      setBusy(true);
      const _posts = await RtmGetEvents({
        page: page,
        sort: sort,
        filter: {
          date: filter?.date,
          now: new Date().toISOString(),
        },
        search: search,
      });
      if (_posts) {
        setTotalPages(_posts.pageCount);
        setPageNumber(_posts.page);
        setTotalPosts(_posts.size);
        setData(_posts.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load(0);
    setShowSidebar(false);
  }, []);

  useEffect(() => {
    setSearch(searchRaw);
  }, [searchRaw]);

  useEffect(() => {
    load(0);
  }, [filter]);

  return (
    <Stack flex={1} sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "stretch",
            rowGap: "8px",
          },
        }}
        spacing="8px"
      >
        <Typography
          sx={{
            fontFamily: "Space Grotesk",
            fontWeight: 600,
            fontSize: 28,
            [theme.breakpoints.down("md")]: {
              fontSize: 20,
            },
          }}
        >
          Events
        </Typography>
        <Stack
          flex={1}
          direction={"row"}
          alignItems={"center"}
          spacing={"8px"}
          justifyContent={"space-between"}
        >
          {/* Date Filters  */}

          <Stack direction={"row"} alignItems={"center"}>
            <Button
              onClick={() => setFilter({ date: "today" })}
              variant={filter?.date === "today" ? "contained" : "text"}
              size="small"
              sx={{
                borderRadius: "8px",
                px: "8px",
                py: "4px",
                height: "44px",
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                  height: "40px",
                },
              }}
              color="inherit"
            >
              Today
            </Button>
            <Button
              onClick={() => setFilter({ date: "week" })}
              variant={filter?.date === "week" ? "contained" : "text"}
              size="small"
              color="inherit"
              sx={{
                borderRadius: "8px",
                px: "8px",
                py: "4px",
                height: "44px",
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                  height: "40px",
                },
              }}
            >
              This Week
            </Button>

            <Button
              onClick={() => setFilter({ date: "month" })}
              variant={filter?.date === "month" ? "contained" : "text"}
              size="small"
              color="inherit"
              sx={{
                borderRadius: "8px",
                px: "8px",
                py: "4px",
                height: "44px",
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                  height: "40px",
                },
              }}
            >
              This Month
            </Button>

            <IconButton onClick={handleClick}>
              <CalendarIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack spacing={"8px"}>
                <Typography
                  fontFamily={"Space Grotesk"}
                  fontSize={14}
                  sx={{ px: "14px", py: "20px" }}
                >
                  Filter By Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    value={
                      filter?.date && typeof filter.date === "number"
                        ? moment.unix(filter.date)
                        : undefined
                    }
                    onChange={(v) =>
                      setFilter({
                        ...filter,
                        date: moment(v).unix(),
                      } as any)
                    }
                  />
                </LocalizationProvider>
              </Stack>
            </Popover>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            [theme.breakpoints.down("md")]: {
              flex: 1,
            },
          }}
        >
          <ZInput
            text={searchRaw}
            onUpdate={(r) => setSearchRaw(r)}
            onReturn={() => load(0)}
            placeholder="Search by title, tags, content..."
            sx={{
              transition: "all .4s",
              width: "120px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
              ":hover": {
                width: "400px",
              },
              ":focus-within": {
                width: "400px",
              },
            }}
            startIcon={
              <SvgIcon>
                <Ic_search />
              </SvgIcon>
            }
          />
        </Stack>
      </Stack>
      <Divider />
      {busy && <LoadingView />}

      {!busy && (
        <Stack
          direction={"row"}
          flex={1}
          sx={{ overflow: "hidden" }}
          spacing={"8px"}
        >
          {/* The content area */}
          <Stack flex={1} sx={{ overflow: "auto" }}>
            <Stack flex={1} spacing={"8px"}>
              {data.map((d) => (
                <EventItem data={d} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack
        sx={{ width: "100%" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Pagination
          page={pageNumber + 1}
          count={totalPages}
          onChange={(e, n) => load(n - 1)}
        />
      </Stack>
    </Stack>
  );
}
