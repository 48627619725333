import React from 'react';

function Ic_book(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M16.952 3.491c-.186.078-.427.245-.884.572-1.752 1.254-2.843 3.077-3.318 3.922v10.589c.823-1.177 1.983-2.502 3.495-3.353.381-.215.632-.357.807-.473.099-.065.146-.104.165-.121a.906.906 0 0 0 .019-.133c.013-.156.014-.367.014-.713v-8.84c0-.648-.003-1.022-.054-1.275-.034-.174-.066-.18-.078-.184h-.004a.24.24 0 0 0-.057-.022c-.002 0-.031 0-.105.031zm.26 11.152v-.001zM17.069 1.96c.262.002.506.071.731.187.535.275.77.735.868 1.228.082.416.082.942.082 1.5v8.936c0 .307 0 .585-.02.813-.021.242-.07.512-.22.767-.16.275-.413.465-.632.61-.225.148-.522.316-.873.513l-.024.014c-2.222 1.251-3.589 3.77-4.19 4.877l-.135.249a.75.75 0 0 1-1.406-.364V7.79a.75.75 0 0 1 .094-.365l.007-.012c.423-.761 1.677-3.018 3.844-4.57l.054-.038c.38-.272.761-.546 1.122-.697.207-.087.44-.15.698-.148z" fill={fill} fillRule="evenodd"/>
		<path d="M6.054 3.81l.06.011c3.537.636 5.664 2.454 6.43 3.257a.75.75 0 0 1 .206.517v10.979c.823-1.177 1.983-2.502 3.495-3.353.381-.215.632-.357.807-.473.099-.065.146-.104.165-.121a.901.901 0 0 0 .019-.133c.013-.156.014-.367.014-.713V5.29a.75.75 0 0 1 .308-.606 5.596 5.596 0 0 1 2.026-.921c.672-.148 1.535-.172 2.167.34.548.444.79 1.02.899 1.682.1.608.1 1.37.1 2.265v6.915c0 .827 0 1.513-.063 2.057-.065.565-.208 1.09-.58 1.524-.381.446-.934.676-1.535.845-.602.169-1.395.312-2.38.489l-.04.007c-1.747.314-3.093.811-4.05 1.3l-.019.009c-.49.249-.88.448-1.178.584-.152.069-.298.13-.433.174a1.522 1.522 0 0 1-.472.086c-.179 0-.344-.044-.472-.086a4.404 4.404 0 0 1-.433-.174c-.298-.136-.689-.335-1.178-.584l-.018-.01c-.958-.488-2.304-.985-4.051-1.3l-.04-.006c-.985-.177-1.778-.32-2.38-.489-.601-.169-1.154-.4-1.535-.845-.372-.434-.515-.959-.58-1.524-.063-.544-.063-1.23-.063-2.057V8.05c0-.895 0-1.657.1-2.265.11-.661.35-1.238.899-1.682.528-.427 1.102-.567 1.742-.563.578.003 1.267.127 2.063.27z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_book;