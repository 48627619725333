import { GetRtmClient } from ".";
import { WeeklyReport } from "../../types/WeeklyReport";
import { PaginatedResponse } from "../../types/IPaginatedResponse";
import { DailyEvent } from "../../types/DailyEvent";

export async function RtmGetDashboardAnalyticsA() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<{
    watchTime: number;
    users: number;
    weekSignups: number;
    previousWeekSignups: number;
    monthSignups: number;
    previousMonthSignups: number;
    monthIncome: number;
    previousMonthIncome: number;
    currency: string;
  }>("RtmGetDashboardAnalyticsA");
  return _result;
}

export async function RtmGetPostsA(props: {
  page: number;
  filter?: {
    date?: "today" | "week" | "month" | number;
    now: string;
  };
  sort?: {
    type: "id" | "title" | "date" | "updated" | "status";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _posts = await _rtm.CallWait<PaginatedResponse<WeeklyReport[]>>(
    "RtmGetPostsA",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _posts;
}

/**
 * Updates the specified properties of a blog post.
 */
export async function RtmUpdatePostA(post: Partial<WeeklyReport>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmUpdatePostA", post);
  return _result;
}

/**
 * Creates a new post with specified content, title, and other properties.
 * @param post
 * @returns
 */
export async function RtmCreatePostA(post: Partial<WeeklyReport>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmCreatePostA", post);
  return _result;
}

/**
 * Deletes the specified post.
 * @param post
 * @returns
 */
export async function RtmDeletePostA(postId: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmDeletePostA", postId);
  return _result;
}

/**
 * Returns the paginated list of created events.
 * @param props
 * @returns
 */
export async function RtmGetEventsA(props: {
  page: number;
  filter?: {
    date?: "today" | "week" | "month" | number;
    now: string;
  };
  sort?: {
    type: "id" | "title" | "date" | "created" | "status";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _events = await _rtm.CallWait<PaginatedResponse<DailyEvent[]>>(
    "RtmGetEventsA",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _events;
}

/**
 * Creates a new weekly event from specified event.
 * @param event
 * @returns
 */
export async function RtmCreateEventA(event: Partial<DailyEvent>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmCreateEventA", event);
  return _result;
}

/**
 * Updates the specified event.
 * @param event
 * @returns
 */
export async function RtmUpdateEventA(event: Partial<DailyEvent>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmUpdateEventA", event);
  return _result;
}

/**
 * Deletes the specified event.
 * @param eventId
 * @returns
 */
export async function RtmDeleteEventA(eventId: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmDeleteEventA", eventId);
  return _result;
}

/**
 * Fetches the weekly events from the economy api. Events returned by this endpoint do not have IDs or status.
 * @returns
 */
export async function RtmFetchWeeklyEventsA() {
  const _rtm = await GetRtmClient();
  const _events = await _rtm.CallWait<DailyEvent[]>("RtmFetchWeeklyEventsA");
  return _events;
}

export async function RtmGetEventA(id: string) {
  const _rtm = await GetRtmClient();
  const _event = await _rtm.CallWait<DailyEvent | false>("RtmGetEventA", id);
  return _event;
}
