import { GetRtmClient } from ".";
import { WeeklyReport } from "../../types/WeeklyReport";
import { Chapter } from "../../types/Chapter";
import { PaginatedResponse } from "../../types/IPaginatedResponse";
import { Lesson } from "../../types/Lesson";
import { LessonProgress } from "../../types/LessonProgress";
import { DailyEvent } from "../../types/DailyEvent";

export async function RtmGetOnboardingVideo() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<string>("RtmGetOnboardingVideo");
  return _result;
}

/**
 * Returns a list of all the chapters, sorted by index.
 */
export async function RtmListChapters() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Chapter[]>("RtmListChapters");
  return _result;
}

/**
 * Returns the chapter details, along with all lessons.
 * @param id
 * @returns
 */
export async function RtmGetChapter(id: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Chapter & { lessons: Lesson[] }>(
    "RtmGetChapter",
    id
  );
  return _result;
}

/**
 * Returns the fundamental blog posts for user.
 * @param props
 * @returns
 */
export async function RtmGetFundamentals(props: {
  page: number;
  filter?: {
    date?: "today" | "week" | "month" | number;
    now: string;
  };
  sort?: {
    type: "title" | "date" | "updated";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _posts = await _rtm.CallWait<PaginatedResponse<WeeklyReport[]>>(
    "RtmGetFundamentals",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _posts;
}

export async function RtmGetEvents(props: {
  page: number;
  filter?: {
    date?: "today" | "week" | "month" | number;
    now: string;
  };
  sort?: {
    type: "title" | "created" | "date";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _events = await _rtm.CallWait<PaginatedResponse<DailyEvent[]>>(
    "RtmGetEvents",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _events;
}

/**
 * Returns the chapter's progress, as well as state of the chapter (locked/unlocked) based on user's current progress.
 * @param chapterId
 * @returns
 */
export async function RtmGetChapterProgress(chapterId: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<{
    lessons: number;
    completed: number;
    current: Lesson;
    locked: boolean;
  }>("RtmGetChapterProgress", chapterId);
  return _result;
}

/**
 * Sets the specified lessons' progress.
 * @param progress
 * @returns
 */
export async function RtmSetLessonProgress(progress: Partial<LessonProgress>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>(
    "RtmSetLessonProgress",
    progress
  );
  return _result;
}

/**
 * Updates the users' discord profile.
 * @param profile
 * @returns
 */
export async function RtmSetDiscordProfile(profile: any) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmSetDiscordProfile", profile);
  return _result;
}

/**
 * Returns the user's discord membership info. If not a member, returns false.
 * @param profile
 * @returns
 */
export async function RtmGetDiscordMembership() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<any>("RtmGetDiscordMembership");
  return _result;
}

/**
 * Returns the users current progress.
 * @returns
 */
export async function RtmGetUserProgress() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<{
    completed: number;
    currentLesson: Lesson;
    currentChapter: Chapter;
    watchTime: number;
    totalLessons: number;
    percentage: number;
    totalQuizzes: number;
    quizzesCompleted: number;
  }>("RtmGetUserProgress");
  return _result;
}

/**
 * Returns the users billing information, including subscription details, payment method, and previous invoices.
 * @returns
 */
export async function RtmGetBillingInfo() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<{
    customer: any;
    invoices: any[];
    subscription: any;
    paymentMethod: any;
    nextInvoice: any;
  }>("RtmGetBillingInfo");
  return _result;
}

export async function RtmGetCustomerPortal() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<string | false>("RtmGetCustomerPortal");
  return _result;
}

export async function RtmSignout() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmSignout");
  return _result;
}
