import {
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function NamedChip(props: {
  label: string;
  value: string;
  color?: string;
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Stack
      sx={{
        background: props.color ? props.color + "33" : "#333",
        borderRadius: "25px",
        pl: "12px",
        border: props.color ? `1px solid ${props.color}55` : "1px solid #555",
        [theme.breakpoints.down("md")]: {
          pl: "8px",
        },
      }}
      direction={"row"}
      alignItems={"center"}
      spacing={"8px"}
    >
      <Typography
        fontSize={13}
        sx={{
          [theme.breakpoints.down("md")]: {
            fontSize: 10,
          },
        }}
      >
        {props.label}
      </Typography>
      <Chip
        sx={{
          background: props.color,
          [theme.breakpoints.down("md")]: {
            fontSize: 10,
          },
        }}
        size="small"
        label={props.value}
      />
    </Stack>
  );
}
