import React from 'react';

function Ic_income(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M12 3.702c-2.213 0-3.775.002-4.958.161-1.154.156-1.8.444-2.267.912-.468.467-.756 1.113-.912 2.267-.159 1.183-.16 2.745-.16 4.958s.001 3.775.16 4.958c.156 1.154.444 1.8.912 2.267.467.468 1.113.756 2.267.912 1.183.159 2.745.16 4.958.16s3.775-.002 4.958-.16c1.154-.156 1.8-.444 2.267-.912.468-.467.756-1.113.912-2.267.159-1.183.16-2.744.16-4.958v-1.464a.977.977 0 0 1 1.953 0v1.537c0 2.124 0 3.817-.178 5.145-.185 1.371-.575 2.497-1.466 3.388-.89.89-2.017 1.281-3.388 1.465-1.328.179-3.021.179-5.145.179h-.146c-2.124 0-3.817 0-5.145-.178-1.371-.185-2.497-.575-3.388-1.466-.89-.89-1.281-2.017-1.466-3.388-.178-1.328-.178-3.021-.178-5.145v-.146c0-2.124 0-3.817.178-5.145.185-1.371.575-2.497 1.466-3.388.89-.89 2.017-1.281 3.388-1.466 1.328-.178 3.021-.178 5.145-.178h1.537a.977.977 0 0 1 0 1.952H12z" fill={fill} fillRule="evenodd"/>
		<path d="M20.554 2.041a.993.993 0 1 1 1.405 1.405l-1.8 1.8.113.114c.205.204.42.42.57.61.11.14.51.653.294 1.291-.217.64-.84.778-1.01.815a7.22 7.22 0 0 1-.814.106l-.048.004a10.8 10.8 0 0 1-1.425.058 3.607 3.607 0 0 1-.725-.098 1.761 1.761 0 0 1-.819-.441 1.762 1.762 0 0 1-.441-.82 3.607 3.607 0 0 1-.098-.724c-.02-.461.016-.979.058-1.425l.004-.048c.027-.283.055-.582.106-.814.037-.169.176-.793.815-1.01.639-.217 1.151.184 1.29.294.191.15.407.366.61.57l.115.113 1.8-1.8z" fill={fill}/>
		<path d="M12 6.5a1 1 0 0 1 1 1v.278c.802.253 1.483.808 1.809 1.586a1 1 0 1 1-1.845.772c-.094-.224-.428-.511-.964-.511-.718 0-1 .46-1 .688 0 .227.282.687 1 .687 1.49 0 3 1.05 3 2.688 0 1.265-.902 2.18-2 2.53v.282a1 1 0 1 1-2 0v-.278c-.802-.253-1.483-.808-1.809-1.586a1 1 0 0 1 1.845-.772c.094.224.428.511.964.511.718 0 1-.46 1-.688 0-.227-.282-.687-1-.687-1.49 0-3-1.05-3-2.688 0-1.265.902-2.18 2-2.53V7.5a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_income;