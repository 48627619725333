import {
  Chip,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Chapter } from "../../../types/Chapter";
import { LOGO } from "../../../assets";
import { RtmGetChapterProgress, RtmListChapters } from "../../../core/rtm/user";
import { ArrowOutwardSharp, PlayArrow } from "@mui/icons-material";
import ZCircularProgress from "../../../components/ZCircularProgress";
import ViewAttachmentDialog from "../../../dialogs/AttachmentDialog";
import { Lesson } from "../../../types/Lesson";
import IC_LOCK from "../../../assets/icons/ui/IC_LOCK";
import LoadingView from "../../../components/Loading";
import { useRecoilState } from "recoil";
import { aShowSidebar } from "../../../core/atoms";

export function RenderChapter(props: { chapter: Chapter }) {
  const chapter = props.chapter;

  const { showModal } = useModal();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [progress, setProgress] = useState<{
    current: Lesson;
    lessons: number;
    completed: number;
    locked: boolean;
  }>();

  function showPreviewDialog() {
    const modal = showModal(ViewAttachmentDialog, {
      attachment: {
        name: chapter.title,
        type: "video",
        url: chapter.demo!,
      },
      onClose() {
        modal.destroy();
      },
    });
  }

  async function load() {
    try {
      const _prog = await RtmGetChapterProgress(chapter.id);
      setProgress(_prog);
    } catch (err: any) {
      console.error("Error loading chapter progress. ", err);
      enqueueSnackbar("Error loading data for chapter. ", { variant: "error" });
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      sx={{
        minHeight: "250px",
        maxHeight: "250px",
        background: "#222",
        borderRadius: "18px",
        p: "24px",
        position: "relative",
        [theme.breakpoints.down("md")]: {
          p: "12px",
          minHeight: 0,
        },
      }}
      direction={"row"}
      spacing={"24px"}
    >
      {/* Image  */}
      <Stack
        sx={{
          width: "350px",
          position: "relative",
          alignItems: "flex-start",
          justifyContent: "center",

          [theme.breakpoints.down("md")]: {
            width: "100px",
          },
        }}
      >
        <img
          src={chapter?.cover || LOGO}
          alt={chapter?.title}
          style={{
            objectFit: "contain",
            width: "100%",
            maxHeight: "100%",
            borderRadius: "12px",
            background: "#1112",
            padding: "24px",
          }}
        />

        {chapter.demo !== chapter.demo && (
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "8px",
              transition: "all .4s",
              "& .MuiIconButton-root": {
                opacity: 0,
              },
              ":hover": {
                background: "#0007",
                "& .MuiIconButton-root": {
                  opacity: 1,
                },
              },
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton
              onClick={showPreviewDialog}
              sx={{
                width: "64px",
                height: "64px",
              }}
            >
              <PlayArrow sx={{ width: "48px", height: "48px" }} />
            </IconButton>
          </Stack>
        )}
      </Stack>

      {/* Chapter Details  */}
      <Stack
        flex={1}
        alignItems={"flex-start"}
        spacing={"12px"}
        sx={{
          height: "80%",
          alignSelf: "center",
          maxHeight: "290px",
        }}
      >
        <Stack flex={1} alignItems={"flex-start"}>
          <Typography
            sx={{
              opacity: 0.6,
              [theme.breakpoints.down("md")]: {
                fontSize: 12,
              },
            }}
          >
            Chapter # {chapter.index}
          </Typography>
          <Typography
            sx={{
              ":hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
              [theme.breakpoints.down("md")]: {
                fontSize: 18,
              },
            }}
            fontFamily={"Space Grotesk"}
            fontSize={28}
            onClick={() => navigate("/dashboard/library/" + chapter.id)}
          >
            {chapter.title}
            <ArrowOutwardSharp />
          </Typography>
          <Typography
            fontSize={18}
            sx={{
              overflow: "hidden",
              lineHeight: "1.5em",
              height: "3em",
              opacity: 0.8,
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
            }}
          >
            {chapter.description}
          </Typography>
        </Stack>
        <Stack spacing={"8px"} direction={"row"}>
          {chapter.tags?.map((t) => (
            <Chip label={t} size="small" />
          ))}
        </Stack>
      </Stack>

      {progress && isDesktop && (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <ZCircularProgress
            value={(progress.completed / progress.lessons) * 100}
            size={128}
            variant="determinate"
            label={`${Math.ceil(
              (progress.completed / progress.lessons) * 100
            )}%`}
            icon={
              progress.locked ? (
                <SvgIcon
                  sx={{ position: "absolute", height: "48px", width: "48px" }}
                >
                  <IC_LOCK />
                </SvgIcon>
              ) : undefined
            }
          />
        </Stack>
      )}
    </Stack>
  );
}

export default function SectionLibrary() {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [focusedVideo, setFocusedVideo] = useState("");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);

  const [data, setData] = useState<Chapter[]>([]);

  const [busy, setBusy] = useState(false);

  async function load() {
    try {
      setBusy(true);
      const _chapters = await RtmListChapters();
      if (_chapters) {
        setData(_chapters);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
    setShowSidebar(false);
  }, []);

  return (
    <Stack sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            fontFamily: "Space Grotesk",
            fontWeight: 600,
            fontSize: 28,
            [theme.breakpoints.down("md")]: {
              fontSize: 20,
            },
          }}
        >
          Your Library
        </Typography>
      </Stack>
      <Divider />
      {busy && <LoadingView />}
      {/* List of chapters */}
      {!busy && (
        <Stack sx={{ overflow: "auto" }} rowGap={"8px"}>
          {data.map((d) => (
            <RenderChapter chapter={d} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
