import React from 'react';

function Ic_invoice(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M9.945 1.25h4.11c1.367 0 2.47 0 3.338.118.901.122 1.66.384 2.261.99.6.607.859 1.37.98 2.276.116.874.116 1.985.116 3.366v10.7c0 .71 0 1.32-.065 1.787-.066.479-.227 1.024-.74 1.354-.76.489-1.6.15-2.072-.096a7.267 7.267 0 0 1-.735-.454 33.79 33.79 0 0 1-.41-.266c-.263-.173-.543-.357-.614-.392-.105-.058-.181-.03-.206-.008a5.733 5.733 0 0 0-.537.317l-1.938 1.222a7.775 7.775 0 0 1-.657.387c-.224.11-.477.199-.776.199-.3 0-.552-.089-.776-.2-.2-.098-.423-.238-.657-.386l-1.938-1.222c-.255-.161-.4-.252-.515-.31-.113-.061-.184-.03-.206-.007a5.731 5.731 0 0 0-.537.317c-.21.145-.974.661-1.244.803-.472.247-1.313.585-2.071.096-.514-.33-.674-.875-.741-1.354-.065-.467-.065-1.077-.065-1.786V8c0-1.381 0-2.492.116-3.366.121-.907.38-1.67.98-2.275.602-.607 1.36-.87 2.261-.991.868-.118 1.97-.118 3.338-.118zM16 6.75a.75.75 0 0 0 0-1.5H8a.75.75 0 0 0 0 1.5h8zM10.75 10a.75.75 0 0 1-.75.75H8a.75.75 0 0 1 0-1.5h2a.75.75 0 0 1 .75.75zm4.5-1a.75.75 0 0 0-1.5 0v.245c-.834.272-1.5.987-1.5 1.943 0 1.23 1.105 2.062 2.25 2.062.511 0 .75.343.75.563 0 .22-.239.562-.75.562-.386 0-.642-.214-.72-.406a.75.75 0 0 0-1.39.562c.24.595.75 1.03 1.36 1.226V16a.75.75 0 0 0 1.5 0v-.245c.834-.272 1.5-.987 1.5-1.943 0-1.23-1.105-2.062-2.25-2.062-.511 0-.75-.343-.75-.563 0-.22.239-.562.75-.562.386 0 .642.214.72.406a.75.75 0 0 0 1.39-.562c-.24-.595-.75-1.03-1.36-1.226V9z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_invoice;