import React from 'react';

function Ic_profile(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M16.907 16.635c.552.3 1.419.771 2.011 1.33.37.349.751.835.821 1.453.076.67-.232 1.273-.786 1.782-.929.853-2.058 1.55-3.523 1.55H8.57c-1.464 0-2.594-.697-3.523-1.55-.553-.509-.861-1.113-.786-1.782.07-.618.452-1.104.821-1.453.593-.559 1.46-1.03 2.012-1.33.123-.067.23-.125.315-.174 2.812-1.615 6.37-1.615 9.182 0 .085.049.193.107.316.174z" fill={fill}/>
		<path d="M7.75 10a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0z" fill={fill}/>
		<path d="M12 3.187c-4.86 0-8.795 3.892-8.795 8.685 0 1.253.268 2.442.75 3.515a.965.965 0 0 1-.496 1.28.98.98 0 0 1-1.29-.492 10.48 10.48 0 0 1-.919-4.303C1.25 6.002 6.066 1.25 12 1.25s10.75 4.752 10.75 10.622c0 1.53-.328 2.986-.919 4.303a.98.98 0 0 1-1.29.491.965.965 0 0 1-.496-1.279 8.557 8.557 0 0 0 .75-3.515c0-4.793-3.934-8.685-8.795-8.685z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_profile;