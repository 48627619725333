import React from 'react';

function Ic_refresh(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M12 3.205A8.795 8.795 0 1 0 20.796 12a.977.977 0 1 1 1.954 0c0 5.937-4.813 10.75-10.75 10.75S1.25 17.937 1.25 12 6.063 1.25 12 1.25c2.125 0 4.107.617 5.775 1.682L19 1.707a.976.976 0 0 1 1.805.52v3.061c0 1.194-1.484 1.738-2.257.84A8.77 8.77 0 0 0 12 3.205z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_refresh;