import React from 'react';

function Ic_notification(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M3.928 9.277C3.928 4.84 7.544 1.25 12 1.25c4.456 0 8.072 3.591 8.072 8.027 0 1.032.07 1.81.545 2.51.181.263.524.706.753 1.063.255.4.505.882.591 1.444.28 1.833-1.012 3.02-2.298 3.551-4.533 1.873-10.793 1.873-15.326 0-1.286-.531-2.579-1.718-2.298-3.55.086-.563.336-1.045.591-1.445.229-.357.572-.8.753-1.063.475-.7.545-1.479.545-2.51z" fill={fill}/>
		<path d="M14.779 21.953a5.208 5.208 0 0 1-2.78.797 5.208 5.208 0 0 1-2.779-.797c-.713-.448-1.07-.673-.944-1.045.124-.373.616-.331 1.6-.248 1.407.12 2.84.12 4.247 0 .984-.083 1.476-.125 1.6.248.125.372-.231.596-.944 1.045z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_notification;