import React from 'react';

function Ic_add(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M12.09 2.25c.433 0 .79 0 1.103.083a2.437 2.437 0 0 1 1.724 1.724c.084.312.083.67.083 1.103V7.5c0 .552.002.89.034 1.134.03.216.07.252.075.256h.001c.004.005.04.047.255.075.245.033.583.035 1.135.035h2.34c.433 0 .79 0 1.103.083a2.437 2.437 0 0 1 1.724 1.724c.084.312.083.67.083 1.103v.18c0 .434 0 .79-.083 1.103a2.438 2.438 0 0 1-1.724 1.724c-.313.084-.67.083-1.104.083H16.5c-.55 0-.889.002-1.134.034-.215.03-.25.07-.255.075v.001c-.006.004-.047.04-.076.256-.033.244-.034.582-.034 1.134v2.34c0 .434 0 .79-.083 1.103a2.437 2.437 0 0 1-1.724 1.724c-.313.084-.67.083-1.104.083h-.179c-.434 0-.79 0-1.104-.083a2.437 2.437 0 0 1-1.723-1.724c-.084-.312-.084-.67-.083-1.103V16.5c0-.552-.002-.89-.035-1.134-.029-.216-.07-.252-.075-.256-.005-.005-.04-.047-.256-.075C8.39 15.002 8.051 15 7.5 15H5.16c-.434 0-.79 0-1.104-.083a2.438 2.438 0 0 1-1.723-1.724c-.084-.312-.084-.67-.083-1.103v-.18c0-.434 0-.79.083-1.103a2.437 2.437 0 0 1 1.723-1.724C4.37 8.999 4.726 9 5.16 9H7.5c.551 0 .89-.002 1.134-.035.215-.028.251-.07.256-.074V8.89c.005-.004.046-.04.075-.256C8.998 8.39 9 8.052 9 7.5V5.16c0-.434 0-.79.083-1.103a2.437 2.437 0 0 1 1.723-1.724c.313-.084.67-.083 1.104-.083h.18z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_add;