/**
 * Defines the roles of the user accounts.
 */

export enum Role {
  /**
   * Standard user/customer.
   */
  User = "user",

  /**
   * Administrator can manage the entire platform through admin portal.
   */
  Administrator = "administrator",

  /**
   * User is a moderator who has access to few moderation tools.
   */
  Moderator = "moderator",
}
