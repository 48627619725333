import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentUser } from "../../../core/atoms";
import ZCircularProgress from "../../../components/ZCircularProgress";
import {
  ArrowRightAlt,
  ChevronLeft,
  Newspaper,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import { LOGO } from "../../../assets";
import IC_PLAY from "../../../assets/icons/ui/IC_PLAY";
import IC_EVENT from "../../../assets/icons/ui/IC_EVENT";
import { DailyEvent } from "../../../types/DailyEvent";
import NamedChip from "../../../components/NamedChip";
import { useEffect, useState } from "react";
import {
  RtmGetEvents,
  RtmGetFundamentals,
  RtmGetUserProgress,
} from "../../../core/rtm/user";
import {
  GetValueForValues,
  getCurrencySymbol,
  parseStripeAmount,
} from "../../../core/helper";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { WeeklyReport } from "../../../types/WeeklyReport";
import { Lesson } from "../../../types/Lesson";
import { Chapter } from "../../../types/Chapter";
import { useNavigate } from "react-router";
import { ClockIcon, TimeIcon } from "@mui/x-date-pickers";
import { RtmGetDashboardAnalyticsA } from "../../../core/rtm/moderator";
import Ic_users from "../../../assets/icons/ui/ic_users";
import IC_USER_ADD from "../../../assets/icons/ui/IC_USER_ADD";
import Ic_income from "../../../assets/icons/ui/ic_income";
import { RtmGetConfigA } from "../../../core/rtm/admin";
import { useModal } from "mui-modal-provider";
import EditPostDialog from "../../../dialogs/AddFundamentalPost";
import IC_NOTE_ADD from "../../../assets/icons/ui/IC_NOTE_ADD";
import EditEventDialog from "../../../dialogs/AddEvent";
import ViewWeeklyReportDialog from "../../../dialogs/ViewReport";
import { Role } from "../../../types/Role";

function WeekReport() {
  const { showModal } = useModal();
  const [report, setReport] = useState<WeeklyReport>();
  const editor = useCreateBlockNote({});
  async function load() {
    try {
      const _data = await RtmGetFundamentals({
        page: 0,
        filter: { date: "week", now: new Date().toISOString() },
      });
      if (_data && _data.data.length > 0) {
        setReport(_data.data[0]);
        editor.replaceBlocks(
          editor.document,
          JSON.parse(_data.data[0].content)
        );
      }
    } catch (err: any) {
      console.error("Error loading data for todays events. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      flex={1}
      sx={{
        border: "1px solid #404040",
        borderRadius: "12px",
        p: "24px",
        alignSelf: "flex-start",
        overflow: "hidden",
      }}
      spacing={"24px"}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
        <Newspaper />
        <Typography fontSize={16} fontFamily={"Space Grotesk"} fontWeight={500}>
          Weekly Report
        </Typography>
      </Stack>
      {report && (
        <Stack spacing={"8px"}>
          <Typography fontSize={20}>{report?.title}</Typography>
          {/* <BlockNoteView editor={editor} editable={false} /> */}
          <Typography fontSize={14}>{report?.summary}</Typography>
          <Stack direction={"row"} spacing={"8px"}>
            {report?.tags?.map((t) => (
              <Chip label={t} />
            ))}
          </Stack>
          {report.events!.length > 0 && (
            <Typography>Relevant Events</Typography>
          )}
          {report.events?.map((ev) => (
            <Typography
              fontSize={16}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {(ev as DailyEvent).title}
            </Typography>
          ))}
          <Button
            color="inherit"
            onClick={() => {
              const modal = showModal(ViewWeeklyReportDialog, {
                report: report,
                onClose() {
                  modal.destroy();
                },
              });
            }}
            endIcon={
              <SvgIcon>
                <ArrowRightAlt />
              </SvgIcon>
            }
            sx={{ alignSelf: "flex-start" }}
          >
            Read Full Report
          </Button>
        </Stack>
      )}
      {!report && (
        <Stack alignItems={"center"} spacing="8px">
          <SvgIcon sx={{ height: "48px", width: "48px", opacity: 0.5 }}>
            <ClockIcon />
          </SvgIcon>
          <Typography fontSize={14} sx={{ opacity: 0.7 }}>
            No data. Check back later!
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

function RenderEvent(props: { event: DailyEvent }) {
  const event = props.event;
  const { showModal } = useModal();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const expandable = Boolean(event.author && event.comments);
  const editor = useCreateBlockNote({
    initialContent: event.comments ? JSON.parse(event.comments) : undefined,
  });
  function showEditEventDialog() {
    const modal = showModal(EditEventDialog, {
      onClose(result) {
        modal.destroy();
      },
      event: event,
    });
  }
  return (
    <Stack
      spacing={"8px"}
      sx={{
        background: "rgba(36, 36, 36, 0.39)",
        border: "1px solid rgba(56, 56, 56, 0.50)",
        borderRadius: "8px",
        p: "8px",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          onClick={showEditEventDialog}
          fontSize={16}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {event.title}
        </Typography>
        {expandable && (
          <SvgIcon
            sx={{
              transition: "all .5s",
              transform: expanded ? undefined : "rotate(-90deg)",
            }}
          >
            <ChevronLeft />
          </SvgIcon>
        )}
      </Stack>

      {expandable && expanded && (
        <Stack sx={{ background: "#222", px: "24px", py: "12px" }}>
          <BlockNoteView
            editor={editor}
            editable={false}
            theme={darkDefaultTheme}
          />
          {event.tags?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px" }}>
              <Typography>Tags</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                {event.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            </Stack>
          )}
          {typeof event.author !== "string" && (
            <Stack spacing={"8px"} sx={{ mt: "18px" }}>
              <Typography>Authored By</Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  alt={event.author.name}
                  src={event.author.image}
                  sx={{ background: "#444" }}
                >
                  {event.author.name[0]}
                </Avatar>
                <Typography>{event.author.name}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}

      <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
        <NamedChip
          label="Impact"
          value={event.impact}
          color={GetValueForValues(
            [
              { option: "Low", value: undefined },
              { option: "Medium", value: theme.palette.info.main },
              { option: "High", value: theme.palette.error.main },
            ],
            event.impact
          )}
        />
        {event.previous && (
          <NamedChip label="Previous" value={event.previous} />
        )}
        {event.forecast && (
          <NamedChip label="Forecast" value={event.forecast} />
        )}
        {event.country && <NamedChip label="Currency" value={event.country} />}
      </Stack>
    </Stack>
  );
}

function TodaysEvents() {
  const [data, setData] = useState<DailyEvent[]>([]);

  async function load() {
    try {
      const _events = await RtmGetEvents({
        page: 0,
        filter: { date: "today", now: new Date().toISOString() },
      });
      if (_events) {
        setData(_events.data);
      }
    } catch (err: any) {
      console.error("Error loading data for todays events. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      flex={1}
      sx={{
        border: "1px solid #404040",
        borderRadius: "12px",
        p: "24px",
        alignSelf: "flex-start",
      }}
      spacing={"24px"}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
        <SvgIcon>
          <IC_EVENT />
        </SvgIcon>
        <Typography fontSize={16} fontFamily={"Space Grotesk"} fontWeight={500}>
          Today's Events
        </Typography>

        <Typography flex={1} textAlign={"end"} sx={{ opacity: 0.7 }}>
          Click on an event to write your analysis.
        </Typography>
      </Stack>
      {data.length === 0 && (
        <Stack alignItems={"center"} spacing="8px">
          <SvgIcon sx={{ height: "48px", width: "48px", opacity: 0.5 }}>
            <ClockIcon />
          </SvgIcon>
          <Typography fontSize={14} sx={{ opacity: 0.7 }}>
            No data. Check back later!
          </Typography>
        </Stack>
      )}
      <Stack spacing={"12px"}>
        {data.map((d) => (
          <RenderEvent event={d} />
        ))}
      </Stack>
    </Stack>
  );
}

export default function SectionAdminDashboard() {
  const { showModal } = useModal();
  const [user] = useRecoilState(aCurrentUser);
  const [data, setData] = useState<{
    watchTime: number;
    users: number;
    weekSignups: number;
    previousWeekSignups: number;
    monthSignups: number;
    previousMonthSignups: number;
    monthIncome: number;
    previousMonthIncome: number;
    currency: string;
  }>({
    currency: "usd",
    monthIncome: 0,
    monthSignups: 0,
    previousMonthIncome: 0,
    previousMonthSignups: 0,
    previousWeekSignups: 0,
    users: 0,
    watchTime: 0,
    weekSignups: 0,
  });

  async function load() {
    try {
      const _data = await RtmGetDashboardAnalyticsA();
      setData(_data);
    } catch (err: any) {
      console.error("Error loading data. ", err);
    }
  }

  function renderIncome(inc: number) {
    const _symbol = getCurrencySymbol(data?.currency || "usd");
    const _amnt = parseStripeAmount(inc);
    return `${_symbol}${_amnt.dollars}.${_amnt.cents}`;
  }

  function secondsToHumanReadable(seconds: number): string {
    // Ensure the input is a valid number
    if (isNaN(seconds) || seconds < 0) {
      throw new Error(
        "Invalid input: seconds should be a non-negative number."
      );
    }

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format each unit to always have two digits
    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = remainingSeconds.toString().padStart(2, "0");

    // Combine the formatted units into a human-readable string
    return `${hoursString}h:${minutesString}m:${secondsString}s`;
  }

  async function showAddReportDialog() {
    const _template = await RtmGetConfigA<string>("analysis_template");
    const _templateObj = JSON.parse(_template.value || "");
    const modal = showModal(EditPostDialog, {
      template: _template ? _templateObj : undefined,
      onClose(result) {
        modal.destroy();
      },
    });
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      flex={1}
      sx={{ overflowY: "auto", flex: 1, p: "24px" }}
      position={"relative"}
      spacing="24px"
    >
      <Typography fontSize={22} fontFamily={"Space Grotesk"} fontWeight={"500"}>
        Dashboard
      </Typography>
      <Typography fontSize={18} fontWeight={"400"}>
        Welcome back, {user?.firstName}!
      </Typography>
      <Stack
        flexWrap={"wrap"}
        direction={"row"}
        columnGap={"24px"}
        rowGap={"24px"}
      >
        {/* Total Users */}
        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <SvgIcon>
              <Ic_users />
            </SvgIcon>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Total Users
            </Typography>
          </Stack>
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {data?.users}
          </Typography>
        </Stack>

        {/* Signups this week, and previous  */}
        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <SvgIcon>
              <IC_USER_ADD />
            </SvgIcon>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Signups This Week
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontFamily={"Space Grotesk"} fontSize={26}>
              {data?.weekSignups}
            </Typography>
            {data!.weekSignups > data!.previousWeekSignups && (
              <>
                <TrendingUp color="success" />
                <Typography>
                  +{data!.weekSignups - data!.previousWeekSignups} than last
                  week.
                </Typography>
              </>
            )}
            {data!.weekSignups < data!.previousWeekSignups && (
              <>
                <TrendingDown color="error" />
                <Typography>
                  -{data!.previousWeekSignups - data!.weekSignups} than last
                  week.
                </Typography>
              </>
            )}
          </Stack>
        </Stack>

        {/* Monthly Signups */}
        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <SvgIcon>
              <IC_USER_ADD />
            </SvgIcon>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Signups This Month
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontFamily={"Space Grotesk"} fontSize={26}>
              {data?.monthSignups}
            </Typography>
            {data!.monthSignups > data!.previousMonthSignups && (
              <>
                <TrendingUp color="success" />
                <Typography>
                  +{data!.monthSignups - data!.previousMonthSignups} than last
                  month.
                </Typography>
              </>
            )}
            {data!.monthSignups < data!.previousMonthSignups && (
              <>
                <TrendingDown color="error" />
                <Typography>
                  -{data!.previousMonthSignups - data!.monthSignups} than last
                  month.
                </Typography>
              </>
            )}
          </Stack>
        </Stack>

        {/* Total Income */}
        {user?.role === Role.Administrator && (
          <Stack
            sx={{
              border: "1px solid #404040",
              borderRadius: "12px",
              p: "24px",
              alignSelf: "flex-start",
            }}
            spacing={"24px"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <SvgIcon>
                <Ic_income />
              </SvgIcon>
              <Typography
                fontSize={16}
                fontFamily={"Space Grotesk"}
                fontWeight={500}
              >
                This Month's Income
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontFamily={"Space Grotesk"} fontSize={26}>
                {renderIncome(data?.monthIncome!)}
              </Typography>
              {data!.monthIncome > data!.previousMonthIncome && (
                <>
                  <TrendingUp color="success" />
                  <Typography>
                    +
                    {renderIncome(
                      data!.monthIncome - data!.previousMonthIncome
                    )}{" "}
                    than last month.
                  </Typography>
                </>
              )}
              {data!.monthIncome < data!.previousMonthIncome && (
                <>
                  <TrendingDown color="error" />
                  <Typography>
                    -
                    {renderIncome(
                      data!.previousMonthIncome - data!.monthIncome
                    )}{" "}
                    than last month.
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        )}

        {/* Total WAthctime */}
        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <SvgIcon>
              <TimeIcon />
            </SvgIcon>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Total Watchtime
            </Typography>
          </Stack>
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {secondsToHumanReadable(data?.watchTime!)}
          </Typography>
        </Stack>
      </Stack>
      <Typography>Quick Access</Typography>
      <Stack direction={"row"}>
        <Button
          color="primary"
          onClick={showAddReportDialog}
          variant="contained"
          startIcon={<IC_NOTE_ADD width={24} height={24} />}
        >
          Create Weekly Report
        </Button>
      </Stack>
      <Stack direction={"row"} spacing={"24px"}>
        <WeekReport />
        <TodaysEvents />
      </Stack>
      <div
        style={{
          background: "#48F",
          height: "200px",
          width: "200px",
          position: "absolute",
          filter: "blur(200px)",
          left: 200,
          zIndex: -4,
          pointerEvents: "none",
        }}
      />
    </Stack>
  );
}
