import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Ic_bookEdit from "../assets/icons/ui/ic_book-edit";
import ZInput from "../components/ZInput";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { RtmCreateChapterA } from "../core/rtm/admin";
import { Quiz } from "../types/Quiz";

export default function EditQuestionDialog(props: {
  onClose?: (result?: false | { question: string; choices: string[] }) => void;
  quiz?: Quiz;
  editIndex?: number;
}) {
  const [question, setQuestion] = useState<string>("");
  const [choices, setChoices] = useState<string[]>(["", "", "", ""]);

  useEffect(() => {
    if (props.quiz && props.editIndex! > -1) {
      setQuestion(props.quiz.questions[props.editIndex!].question);
      setChoices(props.quiz.questions[props.editIndex!].choices);
    }
  }, []);

  return (
    <Dialog fullWidth open>
      <DialogTitle>
        <Stack direction="row" spacing={"10px"} alignItems={"center"}>
          <SvgIcon>
            <Ic_bookEdit />
          </SvgIcon>
          <Typography
            sx={{ fontSize: 22, fontWeight: 500, fontFamily: "Space Grotesk" }}
          >
            Add a Question
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={"8px"}>
          <ZInput
            text={question}
            onUpdate={(t) => setQuestion(t)}
            label="Question"
            placeholder="Write a question..."
          />
          <ZInput
            text={choices[0]}
            onUpdate={(t) => {
              choices[0] = t;
              setChoices([...choices]);
            }}
            label="Answer"
            placeholder="Write an actual answer..."
          />
          <Typography>Extra Choices</Typography>
          <ZInput
            text={choices[1]}
            onUpdate={(t) => {
              choices[1] = t;
              setChoices([...choices]);
            }}
            label="Choice 1"
            placeholder="Write an answer..."
          />
          <ZInput
            text={choices[2]}
            onUpdate={(t) => {
              choices[2] = t;
              setChoices([...choices]);
            }}
            label="Choice 2"
            placeholder="Write an answer..."
          />
          <ZInput
            text={choices[3]}
            onUpdate={(t) => {
              choices[3] = t;
              setChoices([...choices]);
            }}
            label="Choice 3"
            placeholder="Write an answer..."
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "14px" }} spacing={"8px"}>
          <Button onClick={() => props.onClose?.(false)} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              props.onClose?.({ choices: choices, question: question })
            }
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
