import React from 'react';

function IC_FILTER(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M6.182 2.25h11.636c.77 0 1.425 0 1.94.07.547.075 1.07.243 1.47.683.403.443.513.978.521 1.525.008.51-.073 1.145-.168 1.884l-.008.054a2.928 2.928 0 0 1-.189.768 2.632 2.632 0 0 1-.438.694c-.98 1.164-2.8 3.266-5.365 5.18a.304.304 0 0 0-.103.214c-.249 2.743-.461 4.168-.627 5.05-.171.915-.878 1.552-1.48 1.987-.315.229-.65.434-.945.614-.027.017-.053.033-.08.048-.272.166-.5.304-.687.436a1.508 1.508 0 0 1-1.616.108c-.474-.26-.789-.75-.855-1.31-.137-1.16-.395-3.506-.676-6.939a.302.302 0 0 0-.104-.216C5.85 11.189 4.032 9.09 3.054 7.929a2.633 2.633 0 0 1-.438-.694 2.93 2.93 0 0 1-.196-.822c-.096-.74-.177-1.373-.17-1.884.009-.547.12-1.082.523-1.525.4-.44.922-.608 1.468-.683.516-.07 1.17-.07 1.941-.07z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_FILTER;