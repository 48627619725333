import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import StaticPayoutCard from "./payoutCard";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function StaticPayouts() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Stack sx={{ position: "relative", alignItems: "center" }}>
      <Stack
        spacing="40px"
        sx={{
          maxWidth: "1320px",
          width: "100%",
          zIndex: 1,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={isDesktop ? "space-between" : "center"}
          alignItems={"flex-end"}
        >
          <Typography
            sx={{
              fontSize: 48,
              fontWeight: 500,
              fontFamily: "Space Grotesk",
              letterSpacing: "-2.4px",
              [theme.breakpoints.down("md")]: {
                fontSize: "36px",
                textAlign: "center",
              },
            }}
          >
            ATU Student Payouts
          </Typography>
          {isDesktop && (
            <Stack direction={"row"} justifyContent={"center"} spacing="20px">
              <IconButton
                onClick={() => setSlideIndex(slideIndex - 1)}
                disabled={slideIndex === 0}
                color="secondary"
                size="large"
                sx={{
                  width: "48px",
                  height: "48px",
                  background: "rgba(14, 11, 52, 0.25)",
                  border: "1px solid rgba(60, 215, 205, 0.15)",
                }}
              >
                <KeyboardArrowLeft sx={{ width: "24px", height: "24px" }} />
              </IconButton>
              <IconButton
                onClick={() => setSlideIndex(slideIndex + 1)}
                disabled={slideIndex === 1}
                size="large"
                color="secondary"
                sx={{
                  width: "48px",
                  height: "48px",
                  background: "rgba(14, 11, 52, 0.25)",
                  border: "1px solid rgba(60, 215, 205, 0.15)",
                }}
              >
                <KeyboardArrowRight sx={{ width: "24px", height: "24px" }} />
              </IconButton>
            </Stack>
          )}
        </Stack>
        {!isDesktop && (
          <Carousel
            axis="horizontal"
            autoPlay={true}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            renderArrowNext={() => <></>}
            renderArrowPrev={() => <></>}
            renderIndicator={() => <></>}
            swipeable
            emulateTouch
            onChange={(i) => setSlideIndex(i)}
            selectedItem={slideIndex}
          >
            <StaticPayoutCard name="Micheal J. White" profitShare="$3,880.15" />
            <StaticPayoutCard name="Henry Clinton" profitShare="$16,213.90" />
            <StaticPayoutCard name="Emiley Watson" profitShare="$2,500.05" />
            <StaticPayoutCard name="Ronaldo DiCaprio" profitShare="$3,670.76" />
          </Carousel>
        )}
        {!isDesktop && (
          <Stack direction={"row"} justifyContent={"center"} spacing="20px">
            <IconButton
              onClick={() => setSlideIndex(slideIndex - 1)}
              disabled={slideIndex === 0}
              color="secondary"
              size="large"
              sx={{
                width: "48px",
                height: "48px",
                background: "rgba(14, 11, 52, 0.25)",
                border: "1px solid rgba(60, 215, 205, 0.15)",
              }}
            >
              <KeyboardArrowLeft sx={{ width: "24px", height: "24px" }} />
            </IconButton>
            <IconButton
              onClick={() => setSlideIndex(slideIndex + 1)}
              disabled={slideIndex === 3}
              size="large"
              color="secondary"
              sx={{
                width: "48px",
                height: "48px",
                background: "rgba(14, 11, 52, 0.25)",
                border: "1px solid rgba(60, 215, 205, 0.15)",
              }}
            >
              <KeyboardArrowRight sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Stack>
        )}
        {isDesktop && (
          <Carousel
            axis="horizontal"
            autoPlay={true}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            renderArrowNext={() => <></>}
            renderArrowPrev={() => <></>}
            renderIndicator={() => <></>}
            swipeable
            emulateTouch
            onChange={(i) => setSlideIndex(i)}
            selectedItem={slideIndex}
          >
            <Stack direction={"row"} spacing={"25px"}>
              <StaticPayoutCard
                name="Micheal J. White"
                profitShare="$3,880.15"
              />
              <StaticPayoutCard name="Henry Clinton" profitShare="$16,213.90" />
              <StaticPayoutCard name="Emiley Watson" profitShare="$2,500.05" />
              <StaticPayoutCard
                name="Ronaldo DiCaprio"
                profitShare="$3,670.76"
              />
            </Stack>
            <Stack direction={"row"} spacing={"25px"}>
              <StaticPayoutCard
                name="Micheal J. White"
                profitShare="$3,880.15"
              />
              <StaticPayoutCard name="Henry Clinton" profitShare="$16,213.90" />
              <StaticPayoutCard name="Emiley Watson" profitShare="$2,500.05" />
              <StaticPayoutCard
                name="Ronaldo DiCaprio"
                profitShare="$3,670.76"
              />
            </Stack>
          </Carousel>
        )}
      </Stack>
    </Stack>
  );
}
