import {
  Button,
  Divider,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Quiz } from "../types/Quiz";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { shuffleArray } from "../core/helper";
/**
 * Shows a quiz to the user.
 */
export default function QuizView(props: {
  quiz?: Quiz;
  onComplete?: (result: "passed" | "failed", progress: Quiz) => void;
  onDismiss?: () => void;
  sx?: SxProps;
}) {
  const theme = useTheme();
  const quiz = props.quiz;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [quizProgress, setQuizProgress] = useState<Quiz>();
  const [finished, setFinished] = useState(false);
  const [result, setResult] = useState<{
    pass: boolean;
    score: number;

    correct: number;
    total: number;
  }>();
  const [choices, setChoices] = useState<string[]>([]);

  function saveAnswer() {
    const selectedAnswerText = choices[selectedAnswer];
    const prog = quizProgress || quiz;
    prog!.questions[questionNumber]!.selectedChoice = selectedAnswerText!;
    setQuizProgress(prog);

    // if no more questions, we show finished screen.
    if (questionNumber < quiz!.questions.length - 1) {
      // bring next question
      setQuestionNumber(questionNumber + 1);
    } else {
      // Finish
      setFinished(true);
      // Calculate results.
      let _correct = 0;
      for (let q of prog?.questions!) {
        if (q.selectedChoice === q.choices[q.answer]) {
          // correct
          _correct += 1;
        }
      }
      // score
      const _score = (_correct / prog?.questions.length!) * 100;
      setResult({
        pass: _score > 80,
        score: _score,
        correct: _correct,
        total: prog?.questions.length!,
      });
    }
  }

  useEffect(() => {
    const _choices = quiz?.questions[questionNumber].choices!.slice(0);
    shuffleArray(_choices!);
    setChoices(_choices!);
    setSelectedAnswer(-1);
  }, [questionNumber]);

  if (!props.quiz) return <></>;

  if (result && !result.pass) {
    return (
      <Stack
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          background: "#0005",
          zIndex: 100,
          backdropFilter: "blur(10px)",
        }}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={"14px"}
      >
        <Typography>Score: {Math.floor(result.score)}%</Typography>
        <Stack alignItems={"center"}>
          <Typography fontSize={32} fontFamily={"Space Grotesk"}>
            Quiz Failed!
          </Typography>
          <Typography>
            You failed to pass the quiz. You can try again in 15 minutes.
          </Typography>
          <Typography fontWeight={500}>
            {result.correct} out of {result.total} questions were correct.
          </Typography>
        </Stack>
        <Button onClick={() => props.onComplete?.("failed", quizProgress!)}>
          Back to Video
        </Button>
      </Stack>
    );
  }

  if (result && result.pass) {
    return (
      <Stack
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          background: "#0005",
          zIndex: 100,
          backdropFilter: "blur(10px)",
        }}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={"14px"}
      >
        <Typography>Score: {Math.floor(result.score)}%</Typography>
        <Stack alignItems={"center"}>
          <Typography fontSize={32} fontFamily={"Space Grotesk"}>
            You passed the quiz!
          </Typography>
          <Typography>You can continue watching the next lesson.</Typography>
          <Typography fontWeight={500}>
            {result.correct} out of {result.total} questions were correct.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          onClick={() => props.onComplete?.("passed", quizProgress!)}
        >
          Next Lesson
        </Button>
      </Stack>
    );
  }

  return (
    <motion.div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        background: "#0005",
        zIndex: 100,
      }}
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      whileInView={{ opacity: 1, backdropFilter: "blur(10px)" }}
      transition={{ duration: 1 }}
    >
      <Stack
        sx={{
          height: "100%",
        }}
      >
        <Stack flex={1} sx={{ px: "24px", py: "24px" }}>
          <Typography fontSize={22} fontFamily={"Space Grotesk"}>
            Quiz
          </Typography>
          <Typography>Complete the quiz to unlock next lesson.</Typography>
          <Stack flex={1} justifyContent={"center"}>
            <Typography>Question # {questionNumber + 1}</Typography>
            <Typography
              fontSize={32}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 22,
                },
              }}
            >
              {quiz!.questions[questionNumber].question}
            </Typography>
            <Divider />
            <RadioGroup
              name="quiz"
              sx={{
                mt: "48px",
                ml: "48px",
                [theme.breakpoints.down("md")]: {
                  ml: "24px",
                },
              }}
            >
              {choices.map((c, i) => (
                <FormControlLabel
                  control={
                    <Radio
                      radioGroup="quiz"
                      checked={selectedAnswer === i}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedAnswer(i);
                        }
                      }}
                    />
                  }
                  label={c}
                />
              ))}
            </RadioGroup>

            <Button
              onClick={saveAnswer}
              sx={{
                alignSelf: "flex-start",
                mt: "48px",
                ml: "48px",
                [theme.breakpoints.down("md")]: {
                  ml: "24px",
                },
              }}
              variant="contained"
            >
              Next
            </Button>
          </Stack>
        </Stack>
        <LinearProgress
          variant="buffer"
          valueBuffer={(questionNumber / quiz?.questions.length!) * 100}
          value={0}
        />
      </Stack>
    </motion.div>
  );
}
