import recoil from "recoil";
import { User } from "../types/User";
// The file defines the ATOMs used in the state-management library recoil.

/**
 * Stores the current user profile (from our own database, not firebase)
 */
export const aCurrentUser = recoil.atom<User | undefined>({
  key: "currentUser",
  default: undefined,
});

export const aShowSidebar = recoil.atom<boolean>({
  key: "showSidebar",
  default: false,
});
