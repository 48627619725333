import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Pagination,
  Popover,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { RtmGetFundamentals } from "../../../core/rtm/user";
import { WeeklyReport } from "../../../types/WeeklyReport";
import moment from "moment-timezone";
import {
  ArrowOutward,
  ChevronLeft,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import ZInput from "../../../components/ZInput";
import Ic_search from "../../../assets/icons/ui/ic_search";
import { CalendarIcon, DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GetValueForValues } from "../../../core/helper";
import IC_EVENT from "../../../assets/icons/ui/IC_EVENT";
import IC_FILE from "../../../assets/icons/ui/IC_FILE";
import ViewAttachmentDialog from "../../../dialogs/AttachmentDialog";
import { DailyEvent } from "../../../types/DailyEvent";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import LoadingView from "../../../components/Loading";
import { useRecoilState } from "recoil";
import { aShowSidebar } from "../../../core/atoms";

export function FundamentalPost(props: { data: WeeklyReport }) {
  const post = props.data;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const editor = useCreateBlockNote({
    initialContent: JSON.parse(post.content),
  });

  const [expanded, setExpanded] = useState(false);
  const { showModal } = useModal();

  function openAttachmentDialog(attach: {
    type: string;
    url: string;
    name: string;
  }) {
    // If the attachment is a video, we show video in a fullscreen dialog.
    // If its an image, we show image in a full screen dialog.
    // Otherwise, we open the file in new tab.
    if (attach.type.startsWith("image/")) {
      // Show image dialog
      const modal = showModal(ViewAttachmentDialog, {
        attachment: attach,
        onClose() {
          modal.destroy();
        },
      });
    } else if (attach.type.startsWith("video/")) {
      // show video dialog
      const modal = showModal(ViewAttachmentDialog, {
        attachment: attach,
        onClose() {
          modal.destroy();
        },
      });
    } else {
      // open link
      window.open(attach.url, "_blank");
    }
  }

  function RenderEventInfo(event: DailyEvent) {
    return (
      <Stack
        spacing={"8px"}
        sx={{
          background: "#333",
          p: "24px",
          borderRadius: "8px",
        }}
      >
        <Stack direction={"row"} spacing={"8px"}>
          <SvgIcon>
            <IC_EVENT />
          </SvgIcon>
          <Typography
            component={"a"}
            href={event.url}
            target="_blank"
            sx={{
              textDecoration: "none",
              color: theme.palette.text.primary,
              ":hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            fontSize={20}
            fontFamily={"Space Grotesk"}
          >
            {event.title} <ArrowOutward sx={{ fontSize: "20px" }} />
          </Typography>
        </Stack>
        <Stack spacing={"8px"} alignItems={"flex-start"}>
          <Stack
            direction={"row"}
            rowGap={"32px"}
            columnGap={"32px"}
            flexWrap={"wrap"}
          >
            {event.impact && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Impact</Typography>
                <Typography
                  fontSize={18}
                  fontFamily={"Space Grotesk"}
                  color={GetValueForValues(
                    [
                      { option: "Low", value: undefined },
                      {
                        option: "Medium",
                        value: theme.palette.info.main,
                      },
                      { option: "High", value: theme.palette.error.main },
                    ],
                    event.impact
                  )}
                >
                  {event.impact}
                </Typography>
              </Stack>
            )}
            {event.previous && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Previous</Typography>
                <Typography fontSize={18} fontFamily={"Space Grotesk"}>
                  {event.previous}
                </Typography>
              </Stack>
            )}
            {event.forecast && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Forecast</Typography>
                <Typography fontSize={18} fontFamily={"Space Grotesk"}>
                  {event.forecast}
                </Typography>
              </Stack>
            )}
            {event.country && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Currency</Typography>
                <Typography fontSize={18} fontFamily={"Space Grotesk"}>
                  {event.country}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  useEffect(() => {}, []);

  return (
    <Stack sx={{ borderRadius: "8px", overflow: "hidden" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          height: "60px",
          background: expanded ? "#333" : "#222",
          p: "8px",
          transition: "all .3s",
          cursor: "pointer",
          ":hover": {
            background: "#333",
          },
          [theme.breakpoints.down("md")]: {
            height: undefined,
          },
        }}
        spacing="8px"
        onClick={() => setExpanded(!expanded)}
      >
        <Typography
          sx={{
            opacity: 0.8,
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
        >
          {moment.unix(post.date).format("MMM DD, YYYY hh:mm A")}
        </Typography>
        <Divider orientation="vertical" sx={{ height: "24px" }} />
        <Stack direction={"row"} flex={1} alignItems={"center"} spacing={"8px"}>
          <Typography
            fontFamily={"Space Grotesk"}
            fontSize={18}
            fontWeight={500}
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 14,
              },
            }}
          >
            {post.title}
          </Typography>

          {isDesktop && (
            <>
              {post.tags?.map((t) => (
                <Chip label={t} size="small" />
              ))}
            </>
          )}
        </Stack>

        <SvgIcon
          sx={{
            transition: "all .5s",
            transform: expanded ? undefined : "rotate(-90deg)",
          }}
        >
          <ChevronLeft />
        </SvgIcon>
      </Stack>
      {expanded && (
        <Stack
          sx={{
            background: "#222",
            pb: "24px",
          }}
        >
          <BlockNoteView
            theme={darkDefaultTheme}
            editor={editor}
            editable={false}
          />

          {(post.short!.length > 0 || post.long!.length > 0) && (
            <Stack spacing={"8px"} sx={{ mt: "48px", px: "24px" }}>
              <Typography>Recommended Pairs</Typography>
              <Stack
                direction={"row"}
                flexWrap={"wrap"}
                rowGap={"8px"}
                columnGap={"8px"}
              >
                <Stack flex={1} alignItems={"flex-start"} spacing={"8px"}>
                  <Typography>
                    Long <TrendingUp />
                  </Typography>
                  <Stack
                    direction={"row"}
                    rowGap={"8px"}
                    columnGap={"8px"}
                    flexWrap={"wrap"}
                  >
                    {post.long?.map((l) => (
                      <Chip color="success" label={l} />
                    ))}
                  </Stack>
                </Stack>

                <Stack flex={1} alignItems={"flex-start"} spacing={"8px"}>
                  <Typography>
                    Shorts <TrendingDown />
                  </Typography>
                  <Stack
                    direction={"row"}
                    rowGap={"8px"}
                    columnGap={"8px"}
                    flexWrap={"wrap"}
                  >
                    {post.short?.map((s) => (
                      <Chip color="error" label={s} />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}

          <Stack spacing={"8px"} sx={{ mt: "48px", px: "24px" }}>
            <Typography>Related Events</Typography>
            {post.events?.map((ev) => RenderEventInfo(ev as DailyEvent))}
          </Stack>

          {post.attachments?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px", px: "24px" }}>
              <Typography>Attachments</Typography>
              <Stack
                direction={"row"}
                rowGap={"8px"}
                columnGap={"8px"}
                flexWrap={"wrap"}
              >
                {post.attachments?.map((t) => (
                  <Stack
                    sx={{
                      width: "200px",
                      height: "180px",
                      background: "#3336",
                      borderRadius: "8px",
                      py: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => openAttachmentDialog(t)}
                    alignItems={"center"}
                  >
                    {t.type.startsWith("image/") && (
                      <img
                        src={t.url}
                        alt={t.name}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    {t.type.startsWith("video/") && (
                      <video
                        src={t.url + "#t=0.1"}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    {!t.type.startsWith("video/") &&
                      !t.type.startsWith("image/") && (
                        <>
                          <SvgIcon
                            sx={{ flex: 1, height: "48px", width: "48px" }}
                          >
                            <IC_FILE />
                          </SvgIcon>
                          <Typography textAlign={"center"} fontSize={12}>
                            {t.name}
                          </Typography>
                          <Typography fontSize={12}>
                            {Math.ceil(t.size / 1024)} KB
                          </Typography>
                        </>
                      )}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {post.tags?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px", px: "24px" }}>
              <Typography>Tags</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                {post.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            </Stack>
          )}
          {typeof post.author !== "string" && (
            <Stack spacing={"8px"} sx={{ mt: "18px", px: "24px" }}>
              <Typography>Authored By</Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  alt={post.author.name}
                  src={post.author.image}
                  sx={{ background: "#444" }}
                >
                  {post.author.name[0]}
                </Avatar>
                <Typography>{post.author.name}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default function SectionFundamentals() {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [data, setData] = useState<WeeklyReport[]>([]);
  const [busy, setBusy] = useState(false);
  const [sort, setSort] = useState<{
    type: "updated" | "title" | "date";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    date: "today" | "week" | "month" | number;
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  async function load(page: number) {
    try {
      setBusy(true);
      const _posts = await RtmGetFundamentals({
        page: page,
        sort: sort,
        filter: { ...filter, now: new Date().toISOString() },
        search: search,
      });
      if (_posts) {
        setTotalPages(_posts.pageCount);
        setPageNumber(_posts.page);
        setTotalPosts(_posts.size);
        setData(_posts.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load(0);
    setShowSidebar(false);
  }, []);

  useEffect(() => {
    setSearch(searchRaw);
  }, [searchRaw]);

  useEffect(() => {
    load(0);
  }, [filter]);

  return (
    <Stack flex={1} sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "stretch",
            rowGap: "8px",
          },
        }}
        spacing={"8px"}
      >
        <Typography
          sx={{
            fontFamily: "Space Grotesk",
            fontWeight: 600,
            fontSize: 28,
            [theme.breakpoints.down("md")]: {
              fontSize: 20,
            },
          }}
        >
          Weekly Reports
        </Typography>
        <Stack
          flex={1}
          direction={"row"}
          alignItems={"center"}
          spacing={"8px"}
          justifyContent={"space-between"}
        >
          {/* Date Filters  */}

          <Stack direction={"row"} alignItems={"center"}>
            <Button
              onClick={() => setFilter({ date: "week" })}
              variant={filter?.date === "week" ? "contained" : "text"}
              size="small"
              color="inherit"
              sx={{
                borderRadius: "8px",
                px: "8px",
                py: "4px",
                height: "44px",
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                  height: "40px",
                },
              }}
            >
              This Week
            </Button>

            <Button
              onClick={() => setFilter({ date: "month" })}
              variant={filter?.date === "month" ? "contained" : "text"}
              size="small"
              color="inherit"
              sx={{
                borderRadius: "8px",
                px: "8px",
                py: "4px",
                height: "44px",
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                  height: "40px",
                },
              }}
            >
              This Month
            </Button>

            <IconButton onClick={handleClick}>
              <CalendarIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack spacing={"8px"}>
                <Typography
                  fontFamily={"Space Grotesk"}
                  fontSize={14}
                  sx={{ px: "14px", py: "20px" }}
                >
                  Filter By Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    value={
                      filter?.date && typeof filter.date === "number"
                        ? moment.unix(filter.date)
                        : undefined
                    }
                    onChange={(v) =>
                      setFilter({
                        ...filter,
                        date: moment(v).unix(),
                      } as any)
                    }
                  />
                </LocalizationProvider>
              </Stack>
            </Popover>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            [theme.breakpoints.down("md")]: {
              flex: 1,
            },
          }}
        >
          <ZInput
            text={searchRaw}
            onUpdate={(r) => setSearchRaw(r)}
            onReturn={() => load(0)}
            placeholder="Search by title, tags, content..."
            sx={{
              transition: "all .4s",
              width: "120px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
              ":hover": {
                width: "400px",
              },
              ":focus-within": {
                width: "400px",
              },
            }}
            startIcon={
              <SvgIcon>
                <Ic_search />
              </SvgIcon>
            }
          />
        </Stack>
      </Stack>
      <Divider />
      {busy && <LoadingView />}
      {!busy && (
        <Stack
          direction={"row"}
          flex={1}
          sx={{ overflow: "hidden" }}
          spacing={"8px"}
        >
          {/* The content area */}
          <Stack flex={1} sx={{ overflow: "auto" }}>
            <Stack flex={1} spacing={"8px"}>
              {data.map((d) => (
                <FundamentalPost data={d} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack
        sx={{ width: "100%" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Pagination
          page={pageNumber + 1}
          count={totalPages}
          onChange={(e, n) => load(n - 1)}
        />
      </Stack>
    </Stack>
  );
}
