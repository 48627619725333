import {
  Avatar,
  Button,
  Divider,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useEffect, useState } from "react";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import { useNavigate } from "react-router";
import { User } from "../../../types/User";
import { RtmGetProgressReportsA } from "../../../core/rtm/admin";
import { enqueueSnackbar } from "notistack";
import ZTable from "../../../components/ZTable";
import EditUserDialog from "../../../dialogs/EditUser";
import ZCircularProgress from "../../../components/ZCircularProgress";

export default function SectionReportsA() {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<
    {
      uid: string;
      user: User;
      totalWatchTime: number;
      totalQuizzesPassed: number;
      totalLessonsWatched: number;
      totalLessons: number;
      totalQuizzes: number;
      score: number;
    }[]
  >([]);
  const [busy, setBusy] = useState(false);

  async function loadUsers(page: number) {
    try {
      //Load data
      setBusy(true);
      const users = await RtmGetProgressReportsA(page);
      if (users) {
        setTotalPages(users.pageCount);
        setPageNumber(users.page);
        setTotalUsers(users.size);
        setData(users.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showEditDialog(usr: User) {
    const modal = showModal(EditUserDialog, {
      onClose() {
        modal.destroy();
        loadUsers(pageNumber);
      },
      user: usr,
    });
  }

  function secondsToHumanReadable(seconds: number): string {
    // Ensure the input is a valid number
    if (isNaN(seconds) || seconds < 0) {
      throw new Error(
        "Invalid input: seconds should be a non-negative number."
      );
    }

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format each unit to always have two digits
    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = remainingSeconds.toString().padStart(2, "0");

    // Combine the formatted units into a human-readable string
    return `${hoursString}h:${minutesString}m:${secondsString}s`;
  }

  useEffect(() => {
    // Load page 0
    loadUsers(0);
  }, []);

  function RenderUser(userData: {
    uid: string;
    user: User;
    totalWatchTime: number;
    totalQuizzesPassed: number;
    totalLessonsWatched: number;
    totalLessons: number;
    totalQuizzes: number;
    score: number;
  }) {
    const _user = userData.user;
    return (
      <TableRow
        onClick={() => showEditDialog(_user)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(_user.uid)}
          >
            {_user.uid.substring(0, 4)}...
            {_user.uid.substring(_user.uid.length - 4)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={_user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {_user.firstName} {_user.lastName}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {_user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Typography>
            {userData.totalLessonsWatched} out of {userData.totalLessons}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>
            {userData.totalQuizzesPassed} out of {userData.totalQuizzes}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography>
            {secondsToHumanReadable(userData.totalWatchTime)}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <LinearProgress
            color="info"
            value={Math.ceil(userData.score)}
            variant="determinate"
          />
          <Typography>{Math.ceil(userData.score)}%</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack flex={1} sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Progress Tracker
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            color="secondary"
            variant="outlined"
            onClick={() => loadUsers(pageNumber)}
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <ZTable
        sx={{ flex: 1 }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadUsers(pn)}
        prevPage={(pn) => loadUsers(pn)}
        titleBadge={totalUsers + " Users"}
        title="Users"
        description="List of all the users with their progress sorted by score."
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>User</Typography>
              </Stack>
            </TableCell>
            {/* Discord */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Lessons Completed</Typography>
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Quizzes Passed</Typography>
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Total Watch Time</Typography>
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Score</Typography>
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderUser(d))}</>}
      />
    </Stack>
  );
}
