import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  RtmCreateLessonA,
  RtmDeleteLessonA,
  RtmGetChapterA,
  RtmGetLessonsA,
  RtmUpdateChapterA,
  RtmUpdateLessonA,
} from "../../../core/rtm/admin";
import { LOGO, NO_RESULTS } from "../../../assets";
import ZInput from "../../../components/ZInput";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbStorage } from "../../../core/firebase";
import ZAutoComplete, {
  AutocompleteOption,
} from "../../../components/ZAutoComplete";
import Ic_back from "../../../assets/icons/ui/ic_back";
import { useModal } from "mui-modal-provider";
import EditLessonDialog from "../../../dialogs/EditLesson";
import { Lesson } from "../../../types/Lesson";
import moment from "moment";
import IC_UPLOAD from "../../../assets/icons/ui/IC_UPLOAD";
import { ArrowRight, Close } from "@mui/icons-material";
import Ic_add from "../../../assets/icons/ui/ic_add";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import TabPanel from "../../../components/TabPanel";
import { Chapter } from "../../../types/Chapter";
import { Quiz } from "../../../types/Quiz";
import EditQuestionDialog from "../../../dialogs/AddQuestion";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import CloudinaryUploadWidget from "../../../components/CloudinaryUploadWidget";
import { AppConfig } from "../../../config";
import { Reorder } from "framer-motion";

const LEVELS: AutocompleteOption[] = [
  {
    id: "beginner",
    label: "Beginners",
  },
  {
    id: "intermediate",
    label: "Intermediate",
  },
  {
    id: "advanced",
    label: "Advanced",
  },
  {
    id: "expert",
    label: "Expert",
  },
];

export default function SectionChapterA() {
  const navigate = useNavigate();
  const params = useParams();
  const chapterId = params.id;
  const theme = useTheme();
  const { showModal } = useModal();
  const [chapter, setChapter] = useState<Chapter>();
  const [tab, setTab] = useState("basic");
  const [busy, setBusy] = useState(false);
  const [saveState, setSaveState] = useState<"saving" | "saved" | "error">();

  const [title, setTitle] = useState(chapter?.title);
  const [shortDescription, setShortDescription] = useState(
    chapter?.description
  );
  const [longDescription, setLongDescription] = useState("");

  const editor = useCreateBlockNote();
  const [level, setLevel] = useState<AutocompleteOption>();
  const [tags, setTags] = useState<string[]>([]);

  // File upload

  // Upload Widget Configuration
  // Remove the comments from the code below to add
  // additional functionality.
  // Note that these are only a few examples, to see
  // the full list of possible parameters that you
  // can add see:
  //   https://cloudinary.com/documentation/upload_widget_reference

  const [uwConfig] = useState({
    cloudName: AppConfig.cloudinary.name,
    uploadPreset: AppConfig.cloudinary.preset,
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    sources: ["local", "url"], // restrict the upload sources to URL and local files
    multiple: false, //restrict upload to a single file
    // folder: "user_images", //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    clientAllowedFormats: ["video"],
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
    // theme: "purple", //change to a purple theme
  });

  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  // lessons
  const [lessons, setLessons] = useState<Lesson[]>([]);

  const [quiz, setQuiz] = useState<Quiz>();

  async function load() {
    try {
      if (!chapterId) {
        navigate("/dashboard/lms/chapters");
        return;
      }
      setBusy(true);
      const _r = await RtmGetChapterA(chapterId);
      setChapter(_r);
      if (_r) {
        setTitle(_r.title);
        setLongDescription(_r.details!);
        if (_r.details) {
          editor.replaceBlocks(editor.document, JSON.parse(_r.details));
        }
        setShortDescription(_r.description);
        setLevel(
          LEVELS.find((l) => l.id.toLowerCase() === _r.level?.toLowerCase())
        );
        setTags(_r.tags || []);
        // Load lessons
        const _lessons = await RtmGetLessonsA(_r.id);
        setLessons(_lessons);

        setQuiz(_r.quiz);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading lessons. ", err);
    }
    setBusy(false);
  }

  async function saveTitle() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateChapterA({
        id: chapterId,
        title: title,
      });
      load();
      setSaveState("saved");
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving chapter. ", err);
    }
    setBusy(false);
  }

  async function saveShortDescription() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateChapterA({
        id: chapterId,
        description: shortDescription,
      });
      setSaveState("saved");
      load();
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving chapter. ", err);
    }
    setBusy(false);
  }

  async function saveLongDescription() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateChapterA({
        id: chapterId,
        details: longDescription,
      });
      setSaveState("saved");
      load();
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving chapter. ", err);
    }
    setBusy(false);
  }

  async function uploadCoverPhoto(attachment: File) {
    try {
      setBusy(true);
      setSaveState("saving");
      const r = ref(
        FbStorage,
        `/chapters/${chapterId}/covers/${attachment.name}`
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          course: chapterId!,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
          setSaveState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          // Here we update course
          await RtmUpdateChapterA({
            id: chapterId,
            cover: url,
          });
          enqueueSnackbar("Cover photo updated successfully.", {
            variant: "success",
          });
          setSaveState("saved");
          load();
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function uploadComplete(info: any) {
    // Here we update course
    await RtmUpdateChapterA({
      id: chapterId,
      demo: info.secure_url,
    });
    enqueueSnackbar("Video updated successfully.", {
      variant: "success",
    });
    setSaveState("saved");
    load();
  }

  async function saveLevel() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateChapterA({
        id: chapterId,
        level: level?.id! as any,
      });
      setSaveState("saved");
      load();
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving chapter. ", err);
    }
    setBusy(false);
  }

  async function saveStatus(status: "published" | "unpublished" | "archived") {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateChapterA({
        id: chapterId,
        status: status,
      });
      setSaveState("saved");
      load();
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving course. ", err);
    }
    setBusy(false);
  }

  async function saveTags() {
    try {
      setBusy(true);
      setSaveState("saving");
      // update course
      await RtmUpdateChapterA({
        id: chapterId,
        tags: tags,
      });
      load();
      setSaveState("saved");
    } catch (err: any) {
      setSaveState("error");
      console.error("Error saving lesson. ", err);
    }
    setBusy(false);
  }

  async function addLesson() {
    const _newLesson = await RtmCreateLessonA(chapterId!);
    const modal = showModal(EditLessonDialog, {
      closeHandler() {
        modal.destroy();
        load();
      },
      course: chapterId,
      id: _newLesson.id,
    });
  }

  function editLesson(id: string) {
    const modal = showModal(EditLessonDialog, {
      closeHandler() {
        modal.destroy();
        load();
      },
      course: chapterId,
      id: id,
    });
  }

  function deleteLesson(lesson: Lesson) {
    const modal = showModal(ConfirmationDialog, {
      async onClose(result) {
        if (result) {
          await RtmDeleteLessonA(lesson.id);
          enqueueSnackbar("Lesson deleted!");
          load();
        }
        modal.destroy();
      },
      title: "Delete Lesson?",
      body:
        "Are you sure you want to delete '" +
        lesson.title +
        "'?\n\nThis cannot be undone!",
    });
  }

  function deleteChapter() {
    const modal = showModal(ConfirmationDialog, {
      async onClose(result) {
        if (result) {
          await saveStatus("archived");
          navigate("/dashboard/lms/chapters/");
        }
        modal.destroy();
      },
      title: "Delete Chapter?",
      body:
        "Are you sure you want to delete chapter '" +
        chapter?.title +
        "'?\n\nThis cannot be undone!",
    });
  }

  function addQuestionDialog() {
    const modal = showModal(EditQuestionDialog, {
      async onClose(result) {
        modal.destroy();
        if (result) {
          await RtmUpdateChapterA({
            id: chapterId,
            quiz: {
              questions: [
                ...(quiz?.questions || []),
                {
                  answer: 0,
                  choices: result.choices,
                  question: result.question,
                },
              ],
            },
          });
          load();
        }
      },
    });
  }

  function editQuestionDialog(index: number) {
    const modal = showModal(EditQuestionDialog, {
      editIndex: index,
      quiz: quiz,
      async onClose(result) {
        modal.destroy();
        if (result) {
          quiz!.questions[index] = {
            answer: 0,
            choices: result.choices,
            question: result.question,
          };
          await RtmUpdateChapterA({
            id: chapterId,
            quiz: {
              questions: [...(quiz?.questions || [])],
            },
          });
          load();
        }
      },
    });
  }

  async function deleteQuestionDialog(index: number) {
    quiz!.questions.splice(index, 1);
    await RtmUpdateChapterA({
      id: chapterId,
      quiz: {
        questions: [...(quiz?.questions || [])],
      },
    });
    load();
  }

  // Called after a re-order. Here we update each chapters' index to its index in array.
  async function onReordered(_lessons: Lesson[]) {
    setBusy(true);
    let i = 0;
    for (let _lesson of _lessons) {
      _lesson.index = i + 1;
      await RtmUpdateLessonA(_lesson);
      i += 1;
    }
    setLessons(_lessons);
    setBusy(false);
  }

  useEffect(() => {
    if (level?.id === chapter?.level) return;
    saveLevel();
  }, [level]);

  useEffect(() => {
    load();
  }, []);

  function RenderLesson(lesson: Lesson) {
    return (
      <Stack
        spacing={"8px"}
        alignItems={"center"}
        direction={"row"}
        sx={{
          background: "#222",
          borderRadius: "8px",
          border: "1px solid #333",
          p: "8px",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "48px" }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontFamily: "Space Grotesk",
              fontWeight: "500",
            }}
          >
            #{lesson.index}
          </Typography>
        </Stack>
        <img
          src={lesson.thumbnail || LOGO}
          style={{
            height: "48px",
            width: "100px",
            objectFit: "contain",
            border: "1px solid #6666",
            borderRadius: "8px",
            padding: "4px",
          }}
        />
        <Typography
          sx={{ fontSize: 20, fontFamily: "Space Grotesk", fontWeight: "500" }}
        >
          {lesson.title || "(Untitled Lesson)"}
        </Typography>
        :
        <Typography sx={{ fontSize: 16, opacity: 0.6 }}>
          {lesson.excerpt || "(No description)"}
        </Typography>
        {lesson.tags?.map((t) => (
          <Chip label={t} size="small" />
        ))}
        <Stack flex={1} />
        <Typography sx={{ opacity: 0.6 }}>Last updated:</Typography>
        <Typography sx={{ opacity: 0.9 }}>
          {moment.unix(lesson.updated).fromNow()}
        </Typography>
        <Divider orientation="vertical" sx={{ height: "40px", width: "1px" }} />
        <Button onClick={() => deleteLesson(lesson)} color="error">
          Delete
        </Button>
        <Button onClick={() => editLesson(lesson.id)}>Edit</Button>
      </Stack>
    );
  }

  function RenderQuestion(
    index: number,
    question: { question: string; choices: string[]; answer: number }
  ) {
    return (
      <Stack
        spacing={"8px"}
        alignItems={"center"}
        direction={"row"}
        sx={{
          background: "#222",
          borderRadius: "8px",
          border: "1px solid #333",
          p: "8px",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "48px" }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontFamily: "Space Grotesk",
              fontWeight: "500",
            }}
          >
            #{index + 1}
          </Typography>
        </Stack>
        <Typography
          sx={{ fontSize: 20, fontFamily: "Space Grotesk", fontWeight: "500" }}
        >
          {question.question}
        </Typography>
        <Typography sx={{ fontSize: 16, opacity: 0.6 }}>
          {question.choices.length} choices
        </Typography>
        <Stack flex={1} />
        <Button color="error" onClick={() => deleteQuestionDialog(index)}>
          Delete
        </Button>
        <Button onClick={() => editQuestionDialog(index)}>Edit</Button>
      </Stack>
    );
  }

  return (
    <Stack sx={{ p: "24px", overflow: "hidden", flex: 1 }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ overflow: "hidden" }}
      >
        <Stack direction="row">
          <IconButton onClick={() => navigate("/dashboard/lms/chapters/")}>
            <Ic_back width={24} height={24} />
          </IconButton>
          <Typography
            sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
          >
            Chapters <ArrowRight /> {chapter?.title}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          {saveState === "saving" && (
            <>
              <CircularProgress size={"24px"} />
              <Typography>Saving changes...</Typography>
              {uploadState === "uploading" && (
                <Typography>{Math.ceil(uploadProgress)}%</Typography>
              )}
            </>
          )}
          {saveState === "saved" && (
            <>
              <Typography>Changes are saved.</Typography>
            </>
          )}

          {saveState === "error" && (
            <>
              <Typography color={"error"}>Error saving changes!</Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack flex={1} sx={{ overflow: "hidden", flex: 1 }} spacing="8px">
        {/* Tabbed Navigation for Editing Course */}
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label="Basic" value={"basic"} />
          <Tab label="Lessons" value={"lessons"} />
          <Tab label="Quiz" value={"quiz"} />
        </Tabs>
        <TabPanel index={"basic"} value={tab}>
          <Stack flex={1} direction={"row"} sx={{ overflow: "hidden" }}>
            <Stack flex={1} spacing="12px" sx={{ overflow: "auto" }}>
              <Stack>
                <ZInput
                  onReturn={saveTitle}
                  onBlur={saveTitle}
                  text={title}
                  onUpdate={(t) => setTitle(t)}
                  label="Course Title"
                  disabled={busy}
                  placeholder="Write a course title."
                />
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  minHeight: "600px",
                  background: "#333",
                  borderRadius: "16px",
                }}
              >
                {!chapter?.demo && (
                  <Stack
                    flex={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* <input
                      id="file-input-1"
                      style={{
                        position: "absolute",
                        opacity: 0,
                        zIndex: -999999,
                      }}
                      type="file"
                      accept="video/*"
                      onChange={(f) => {
                        if (f.target.files && f.target.files?.length > 0) {
                          uploadVideo(f.target.files[0]);
                        }
                      }}
                    /> */}
                    <IC_UPLOAD height={256} width={256} />
                    <Typography>
                      Click the button below to upload a video.
                    </Typography>
                  </Stack>
                )}
                {chapter?.demo && (
                  <video
                    src={chapter.demo}
                    autoPlay
                    loop
                    controls
                    muted
                    style={{ height: "100%", borderRadius: "16px" }}
                  />
                )}
              </Stack>

              <CloudinaryUploadWidget
                uwConfig={uwConfig}
                onUploadComplete={uploadComplete}
              />

              <Stack>
                <Typography
                  sx={{
                    fontSize: 32,
                    fontWeight: 600,
                    fontFamily: "Space Grotesk",
                  }}
                >
                  Chapter Introduction
                </Typography>
                <Typography sx={{ opacity: 0.8 }}>
                  Write the chapter introduction for users. It will be visible
                  to users regardless of their progress.
                </Typography>
              </Stack>

              <BlockNoteView
                editor={editor}
                theme={darkDefaultTheme}
                onBlur={saveLongDescription}
                onChange={() => {
                  setLongDescription(JSON.stringify(editor.document));
                }}
              />
              {/* <MDEditor
                value={longDescription}
                onChange={(v, e) => setLongDescription(v!)}
                onBlur={saveLongDescription}
                style={{
                  background: "#222",
                  borderRadius: "8px",
                  overflow: "hidden",
                  minHeight: "600px",
                }}
                previewOptions={{
                  style: {
                    background: "#222",
                  },
                }}
              /> */}
            </Stack>

            {/* Sidebar: Chapter Dewtails  */}
            <Stack
              spacing={"8px"}
              sx={{ px: "14px", overflow: "auto", height: "100%" }}
            >
              <Stack spacing={"8px"}>
                {chapter?.status === "published" && (
                  <Button
                    onClick={() => saveStatus("unpublished")}
                    variant="outlined"
                    color="warning"
                  >
                    Unpublish
                  </Button>
                )}
                {(!chapter?.status || chapter?.status === "unpublished") && (
                  <Button
                    onClick={() => saveStatus("published")}
                    variant="contained"
                    color="primary"
                  >
                    Publish
                  </Button>
                )}
              </Stack>

              <Stack spacing={"8px"} position={"relative"}>
                <Typography fontWeight={500} fontSize={14}>
                  Thumbnail
                </Typography>
                <img
                  src={chapter?.cover || LOGO}
                  style={{
                    width: "360px",
                    height: "300px",
                    borderRadius: "8px",
                    opacity: 0.5,
                    objectFit: "contain",
                  }}
                />

                <input
                  accept=".png, .jpg, .jpeg"
                  id="file-input-2"
                  style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
                  type="file"
                  onChange={(f) => {
                    if (f.target.files && f.target.files?.length > 0) {
                      uploadCoverPhoto(f.target.files[0]);
                    }
                  }}
                />
                <Button
                  onClick={() =>
                    document.getElementById("file-input-2")?.click()
                  }
                  sx={{ alignSelf: "flex-end" }}
                  variant="contained"
                >
                  Upload a Picture
                </Button>
              </Stack>
              <Stack>
                <ZAutoComplete
                  key={level as any}
                  label="Level"
                  options={LEVELS}
                  value={level}
                  onUpdate={(l) => setLevel(l as any)}
                />
              </Stack>
              <ZInput
                sx={{ height: "100px" }}
                label="Excerpt"
                important
                onBlur={saveShortDescription}
                text={shortDescription}
                onUpdate={(t) => setShortDescription(t)}
                placeholder="Write a short description..."
                helperText="The excerpt text will be shown in the chapter's card. "
              />
              <Stack spacing={"8px"}>
                <Typography fontWeight={500} fontSize={14}>
                  Tags
                </Typography>
                <Autocomplete
                  options={[]}
                  disablePortal
                  freeSolo
                  onBlur={saveTags}
                  autoSelect
                  multiple
                  onChange={(ev, values) => {
                    setTags(values);
                  }}
                  defaultValue={tags}
                  value={tags}
                  fullWidth
                  ListboxProps={{
                    sx: {
                      background: "#FFF2",
                      backdropFilter: "blur(1px)",
                    },
                  }}
                  renderTags={(tags, tProps, state) =>
                    tags.map((t: any) => (
                      <Chip
                        deleteIcon={<Close height="16px" width="16px" />}
                        onDelete={(de) => {
                          // Remove from local
                          const _new = tags?.filter?.((s: any) => s !== t);
                          setTags(_new);
                        }}
                        size="small"
                        label={t}
                        variant="filled"
                        color="primary"
                        sx={{
                          color: "#FFF",
                          borderRadius: "24px",
                          mx: "2px",
                        }}
                      />
                    ))
                  }
                  sx={{
                    borderRadius: "50px",
                  }}
                  size="small"
                  getOptionLabel={(option: any) => option.label}
                  renderInput={(params) => (
                    <TextField
                      variant="filled"
                      {...(params as any)}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        hiddenLabel: true,
                        sx: {
                          borderRadius: "8px",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      }}
                      hiddenLabel
                      multiline
                      maxRows={3}
                      helperText={
                        "Tags are useful when filtering posts of certain types, or in search."
                      }
                      placeholder={"Enter tags for the post. "}
                    />
                  )}
                />
              </Stack>

              {chapter && (
                <Stack>
                  <Typography fontWeight={500} fontSize={14}>
                    Created
                  </Typography>
                  <Typography fontSize={14}>
                    {moment.unix(chapter.created).format("DD/MM/YYYY hh:mm A")}{" "}
                    ({moment.unix(chapter.created).fromNow()})
                  </Typography>
                </Stack>
              )}

              {chapter && (
                <Stack>
                  <Typography fontWeight={500} fontSize={14}>
                    Last updated
                  </Typography>
                  <Typography fontSize={14}>
                    {moment.unix(chapter?.updated).format("DD/MM/YYYY hh:mm A")}{" "}
                    ({moment.unix(chapter.updated).fromNow()})
                  </Typography>
                </Stack>
              )}

              {saveState === "saving" && (
                <Stack>
                  <Typography fontWeight={500} fontSize={14}>
                    Auto saving...
                  </Typography>
                </Stack>
              )}

              <Button onClick={deleteChapter} variant="contained" color="error">
                Delete Chapter
              </Button>
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel index={"lessons"} value={tab}>
          {lessons.length <= 0 && (
            <Stack
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={"12px"}
            >
              <img src={NO_RESULTS} />
              <Typography>
                No lessons in this chapter. Please add some.
              </Typography>
              <Button variant="contained" onClick={() => addLesson()}>
                Add a Lesson
              </Button>
            </Stack>
          )}
          {lessons.length > 0 && (
            <Stack flex={1} spacing={"8px"} sx={{ overflow: "hidden" }}>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button
                  onClick={addLesson}
                  startIcon={<Ic_add height={24} width={24} />}
                  variant="contained"
                >
                  Add a Lesson
                </Button>
              </Stack>
              <Stack flex={1} spacing={"8px"} sx={{ overflow: "auto" }}>
                <Reorder.Group
                  onReorder={onReordered}
                  values={lessons}
                  as="div"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "12px",
                    padding: 0,
                    pointerEvents: busy ? "none" : "all",
                  }}
                >
                  {lessons.map((l) => (
                    <Reorder.Item
                      key={l.id}
                      value={l}
                      id={l.id}
                      as="div"
                      style={{ cursor: "move" }}
                    >
                      <RenderLesson {...l} />
                    </Reorder.Item>
                  ))}
                </Reorder.Group>
              </Stack>
            </Stack>
          )}
        </TabPanel>
        <TabPanel index={"quiz"} value={tab}>
          <Stack>
            <Typography
              sx={{
                fontSize: 32,
                fontWeight: 600,
                fontFamily: "Space Grotesk",
              }}
            >
              Quiz
            </Typography>
            <Typography sx={{ opacity: 0.8 }}>
              Multiple Choice Questions after chapter completion.
            </Typography>
          </Stack>
          <Button sx={{ alignSelf: "flex-start" }} onClick={addQuestionDialog}>
            Add a Question
          </Button>
          <Stack spacing={"8px"} sx={{ overflow: "auto" }}>
            {/* Show list of all MCQs. */}
            {quiz?.questions.map((q, i) => RenderQuestion(i, q))}
          </Stack>
        </TabPanel>
      </Stack>
    </Stack>
  );
}
