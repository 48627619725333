import React from 'react';

function Ic_library(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M9.129 5.072a1 1 0 0 1 1.3.557l2 5a1 1 0 1 1-1.857.743l-2-5a1 1 0 0 1 .557-1.3z" fill={fill} fillRule="evenodd"/>
		<path d="M16.804 5.02a1 1 0 0 1 1.177.784l1 5a1 1 0 0 1-1.962.392l-1-5a1 1 0 0 1 .785-1.176z" fill={fill} fillRule="evenodd"/>
		<path d="M7 5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M14 5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M14.647 1.25H9.353c-1.404 0-2.55 0-3.455.115-.942.12-1.769.378-2.435 1.008-.675.637-.96 1.44-1.09 2.355-.123.865-.123 1.957-.123 3.275v5.994c0 1.318 0 2.41.123 3.275.13.915.415 1.718 1.09 2.355.666.63 1.493.889 2.435 1.008.905.115 2.051.115 3.455.115h5.294c1.404 0 2.55 0 3.455-.115.942-.12 1.769-.378 2.435-1.008.675-.637.96-1.44 1.09-2.355.123-.865.123-1.957.123-3.275V8.003c0-1.317 0-2.41-.123-3.275-.13-.915-.415-1.718-1.09-2.355-.666-.63-1.493-.889-2.435-1.008-.905-.115-2.051-.115-3.455-.115zm5.153 8.4V8.075c0-1.408-.002-2.36-.104-3.072-.096-.678-.265-.993-.497-1.212-.24-.228-.597-.397-1.342-.492-.768-.097-1.79-.1-3.276-.1H9.42c-1.486.001-2.508.003-3.275.1-.745.095-1.102.264-1.342.492-.233.22-.402.534-.498 1.212-.101.712-.104 1.664-.104 3.072V9.65c0 .283 0 .424.088.512.088.088.23.088.512.088h14.4c.283 0 .425 0 .512-.088.088-.088.088-.23.088-.512zM13 17a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2h2z" fill={fill} fillRule="evenodd"/>
		<path d="M5 18.75a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1zm14 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_library;