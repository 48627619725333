import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Ic_bookEdit from "../assets/icons/ui/ic_book-edit";
import ZInput from "../components/ZInput";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { RtmCreateChapterA } from "../core/rtm/admin";
import { Chapter } from "../types/Chapter";

export default function AddChapterDialog(props: {
  onClose?: (result?: false | Chapter) => void;
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");

  async function add() {
    try {
      setError("");
      if (!title) {
        setError("title");
        return;
      }
      if (!description) {
        setError("description");
        return;
      }
      setBusy(true);
      // ADd course
      const _r = await RtmCreateChapterA({
        description: description,
        title: title,
      });
      if (Boolean(_r)) {
        enqueueSnackbar("A new chapter has been added. ", {
          variant: "success",
        });
        props.onClose?.(_r);
      }
    } catch (err: any) {
      console.error("Error creating a new chapter. ", err);
      enqueueSnackbar("Error adding new chapter. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  return (
    <Dialog fullWidth open>
      {busy && <LinearProgress />}
      <DialogTitle>
        <Stack direction="row" spacing={"10px"} alignItems={"center"}>
          <SvgIcon>
            <Ic_bookEdit />
          </SvgIcon>
          <Typography
            sx={{ fontSize: 22, fontWeight: 500, fontFamily: "Space Grotesk" }}
          >
            Add a chapter
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={"8px"} sx={{ py: "24px" }}>
          <Typography>Basic information</Typography>
          <ZInput
            text={title}
            onUpdate={(t) => setTitle(t)}
            errorText={error === "title" ? "Title cannot be blank." : undefined}
            label="Chapter Name"
            placeholder="Enter name of the chapter..."
          />
          <ZInput
            text={description}
            onUpdate={(t) => setDescription(t)}
            errorText={
              error === "description"
                ? "Short description cannot be blank."
                : undefined
            }
            multiline
            rows={4}
            label="Short Description"
            placeholder="Wrte a short description of this chapter."
          />
          <Alert variant="outlined">
            <AlertTitle>Note</AlertTitle>
            <Typography>
              You can add more information in the next step.
            </Typography>
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "14px" }} spacing={"8px"}>
          <Button
            disabled={busy}
            onClick={() => props.onClose?.(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button disabled={busy} variant="contained" onClick={add}>
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
