import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IMG_APP_DEMO, IMG_ARROW } from "../../../assets";
import { motion } from "framer-motion";

export default function StaticDemo() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Stack
      alignItems={"center"}
      sx={{
        position: "relative",
        overflow: "visible",
        px: isDesktop ? "" : "24px",
      }}
    >
      <Stack
        justifyContent={"center"}
        spacing={"48px"}
        sx={{
          maxWidth: "1320px",
          width: "100%",
          height: isDesktop ? "480px" : "520px",
          zIndex: 1,
          borderRadius: "38px",
          background:
            "linear-gradient(180deg, rgba(14, 11, 52, 0.25) 0%, rgba(60, 215, 205, 0.25) 100%)",
          border: "2px solid rgba(60, 215, 205, 0.25)",
          backdropFilter: "blur(12.5px)",
        }}
      >
        <div
          style={{
            background: "#3CD7CD",
            height: "300px",
            width: "300px",
            position: "absolute",
            filter: "blur(240px)",
            opacity: isDesktop ? 0.5 : 0,
            zIndex: 1,
            left: -100,
            bottom: 0,
            pointerEvents: "none",
          }}
        />
        <Stack
          px={isDesktop ? "111px" : "44px"}
          spacing={"16px"}
          alignItems={isDesktop ? "" : "center"}
        >
          {!isDesktop && (
            <img
              src={IMG_APP_DEMO}
              alt=""
              style={{ height: "144px", objectFit: "contain" }}
            />
          )}
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
            <Typography
              sx={{
                fontFamily: "Space Grotesk",
                fontSize: "36px",
                fontWeight: "500",
                letterSpacing: "-1.8px",
                maxWidth: "434px",
                [theme.breakpoints.down("md")]: {
                  fontSize: 20,
                  textAlign: "center",
                },
              }}
            >
              Take your education seriously with ATU.
            </Typography>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
            <Typography
              sx={{
                fontFamily: "Helvetica",
                fontSize: "18px",
                letterSpacing: "-0.18px",
                maxWidth: "335px",
                opacity: 0.65,
                [theme.breakpoints.down("md")]: {
                  fontSize: 16,
                  textAlign: "center",
                },
              }}
            >
              Access ATU from anywhere on your phone or computer.
            </Typography>
          </motion.div>

          <Stack>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
              <Button
                href="/join"
                variant="contained"
                sx={{ width: "191px", mt: "16px" }}
              >
                Join ATU now
              </Button>
            </motion.div>
          </Stack>
        </Stack>
        {isDesktop && (
          <motion.img
            src={IMG_APP_DEMO}
            alt=""
            style={{ position: "absolute", right: 50 }}
            initial={{ top: -40 }}
            whileInView={{ top: 10 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
            }}
          />
        )}
      </Stack>
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute", rotate: "45deg" }}
        initial={{ left: -100, opacity: 0 }}
        animate={{ left: 1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute", rotate: "-145deg", bottom: 0 }}
        initial={{ right: -100, opacity: 0 }}
        animate={{ right: 1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
    </Stack>
  );
}
