import {
  Avatar,
  Button,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { RtmGetSalesAnalyticsA } from "../../../core/rtm/admin";
import moment from "moment";
import { parseStripeAmount } from "../../../core/helper";
import Ic_cardCheck from "../../../assets/icons/ui/ic_card-check";
import IC_CARD_ERROR from "../../../assets/icons/ui/IC_CARD_ERROR";
import Ic_invoice from "../../../assets/icons/ui/ic_invoice";
import { ClockIcon } from "@mui/x-date-pickers";
import { FailedPayment } from "../../../types/FailedPayment";
import { User } from "../../../types/User";
import { Invoice } from "../../../types/Invoice";
import { PaymentMethod } from "@stripe/stripe-js";

export default function SectionSalesA() {
  const [busy, setBusy] = useState(false);
  const [dailySales, setDailySales] = useState<{ [key: number]: number }>({});
  const [failedPayments, setFailedPayments] = useState<FailedPayment[]>([]);
  const [openInvoices, setOpenInvoices] = useState<
    { user: User; invoice: Invoice }[]
  >([]);
  const [totalSales, setTotalSales] = useState("$0.00");
  const [weeklySales, setWeeklySales] = useState("$0.00");
  const [monthlySales, setMonthlySales] = useState("$0.00");
  const [activeSubs, setActiveSubs] = useState(0);

  async function load() {
    try {
      setBusy(true);
      const _data = await RtmGetSalesAnalyticsA();
      if (_data) {
        setDailySales(_data.dailySales);
        setFailedPayments(_data.failedPayments);
        setOpenInvoices(_data.openInvoices);
        const _totalSales = parseStripeAmount(_data.totalSales);
        const _weekly = parseStripeAmount(_data.weeklySales);
        const _monthly = parseStripeAmount(_data.monthlySales);
        setMonthlySales(`$${_monthly.dollars}.${_monthly.cents}`);
        setWeeklySales(`$${_weekly.dollars}.${_weekly.cents}`);
        setTotalSales(`$${_totalSales.dollars}.${_totalSales.cents}`);
        setActiveSubs(_data.activeSubscriptions);
      }
    } catch (err: any) {
      console.error("Error loading data for analytics.", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  function RenderOpenInvoice(u: User, i: Invoice) {
    const _amnt = parseStripeAmount(i.data.amount_due);
    return (
      <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
        <Avatar src={u.image || ""} alt={u.firstName}>
          {u.firstName[0] + u.lastName[0]}
        </Avatar>
        <Stack flex={1}>
          <Typography
            fontSize={20}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
          >
            {u.firstName + " " + u.lastName}
          </Typography>
          <Typography fontSize={14}>{u.email}</Typography>
        </Stack>
        <Stack justifyContent={"center"}>
          <Typography color={"secondary"}>
            ${_amnt.dollars}.{_amnt.cents}
          </Typography>
          <Typography color={"secondary"} variant="button">
            {
              (u.subscription?.default_payment_method as PaymentMethod)?.card
                ?.brand
            }
            /
            {
              (u.subscription?.default_payment_method as PaymentMethod)?.card
                ?.last4
            }
          </Typography>
        </Stack>
      </Stack>
    );
  }

  function RenderFailedPayment(fp: FailedPayment) {
    const i = fp.invoice;
    const u = fp.user;
    if (!i || !u) return <></>;
    const _amnt = parseStripeAmount(i.data.amount_due);
    return (
      <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
        <Avatar src={u.image || ""} alt={u.firstName}>
          {u.firstName[0] + u.lastName[0]}
        </Avatar>
        <Stack flex={1}>
          <Typography
            fontSize={20}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
          >
            {u.firstName + " " + u.lastName}
          </Typography>
          <Typography fontSize={14}>{u.email}</Typography>
        </Stack>
        <Stack justifyContent={"center"}>
          <Typography color={"error"}>
            ${_amnt.dollars}.{_amnt.cents}
          </Typography>
          <Typography color={"error"} variant="button">
            {
              (u.subscription?.default_payment_method as PaymentMethod)?.card
                ?.brand
            }
            /
            {
              (u.subscription?.default_payment_method as PaymentMethod)?.card
                ?.last4
            }
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack flex={1} sx={{ p: "24px" }} gap={"8px"}>
      <Typography fontSize={22} fontFamily={"Space Grotesk"} fontWeight={"500"}>
        Sales Analytics
      </Typography>
      <Typography fontSize={18} fontWeight={"400"}>
        Overview
      </Typography>
      <Stack
        flexWrap={"wrap"}
        direction={"row"}
        columnGap={"24px"}
        rowGap={"24px"}
      >
        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
            minWidth: "240px",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            {/* <SvgIcon>
              <Ic_users />
            </SvgIcon> */}
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Total Sales
            </Typography>
          </Stack>
          <Typography fontFamily={"Space Grotesk"} fontSize={26}>
            {totalSales}
          </Typography>
        </Stack>

        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
            minWidth: "240px",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Last 7 Days
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontFamily={"Space Grotesk"} fontSize={26}>
              {weeklySales}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
            minWidth: "240px",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Last 30 Days
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontFamily={"Space Grotesk"} fontSize={26}>
              {monthlySales}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            border: "1px solid #404040",
            borderRadius: "12px",
            p: "24px",
            alignSelf: "flex-start",
            minWidth: "240px",
          }}
          spacing={"24px"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography
              fontSize={16}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
            >
              Active Subscribers
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontFamily={"Space Grotesk"} fontSize={26}>
              {activeSubs}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* daily sales chart and invoices*/}
      <Stack flex={1} direction={"row"}>
        {/* Chart */}
        <Stack flex={5}>
          <BarChart
            xAxis={[
              {
                axisId: "time",
                scaleType: "band",
                data: Object.keys(dailySales),
                valueFormatter: (n) => moment.unix(n!).format("MMM DD, YYYY"),
              },
            ]}
            yAxis={[
              {
                valueFormatter(value, context) {
                  const _v = parseStripeAmount(value!);
                  return `$${_v.dollars}.${_v.cents}`;
                },
              },
            ]}
            series={[
              {
                label: "Sales",
                data: Object.values(dailySales),
                valueFormatter: (v) => {
                  const _v = parseStripeAmount(v!);
                  return `$${_v.dollars}.${_v.cents}`;
                },
              },
            ]}
          />
        </Stack>
        {/* Failed Payments & Open Invoices  */}
        <Stack spacing={"18px"} sx={{ width: "400px" }}>
          {/* Failed Payments  */}
          <Stack
            sx={{
              border: "1px solid #404040",
              borderRadius: "12px",
              p: "24px",
              overflow: "hidden",
            }}
            spacing={"24px"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <SvgIcon>
                <IC_CARD_ERROR />
              </SvgIcon>
              <Typography
                fontSize={16}
                fontFamily={"Space Grotesk"}
                fontWeight={500}
              >
                Failed Payments
              </Typography>
            </Stack>

            {/* Render Failed Payment Users */}
            {failedPayments.length === 0 && (
              <Stack alignItems={"center"} spacing="8px">
                <SvgIcon sx={{ height: "48px", width: "48px", opacity: 0.5 }}>
                  <ClockIcon />
                </SvgIcon>
                <Typography fontSize={14} sx={{ opacity: 0.7 }}>
                  No failed payments yet.
                </Typography>
              </Stack>
            )}
          </Stack>

          {/* Open Invoices  */}
          <Stack
            sx={{
              border: "1px solid #404040",
              borderRadius: "12px",
              p: "24px",
              overflow: "hidden",
            }}
            spacing={"24px"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <SvgIcon>
                <Ic_invoice />
              </SvgIcon>
              <Typography
                fontSize={16}
                fontFamily={"Space Grotesk"}
                fontWeight={500}
              >
                Open Invoices
              </Typography>
            </Stack>

            {openInvoices.length === 0 && (
              <Stack alignItems={"center"} spacing="8px">
                <SvgIcon sx={{ height: "48px", width: "48px", opacity: 0.5 }}>
                  <ClockIcon />
                </SvgIcon>
                <Typography fontSize={14} sx={{ opacity: 0.7 }}>
                  No open invoices yet. Check back later!
                </Typography>
              </Stack>
            )}
            {openInvoices.map((oi) => RenderOpenInvoice(oi.user, oi.invoice))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
