import React from 'react';

function Ic_users(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M13.907 15.135c.552.3 1.418.771 2.011 1.33.37.349.751.835.821 1.453.076.67-.232 1.273-.786 1.782-.929.853-2.058 1.55-3.523 1.55H5.57c-1.464 0-2.594-.697-3.523-1.55-.553-.509-.861-1.113-.786-1.782.07-.618.452-1.104.821-1.453.593-.559 1.46-1.03 2.012-1.33.123-.067.23-.125.315-.174 2.812-1.615 6.37-1.615 9.182 0 .085.049.193.107.316.174z" fill={fill}/>
		<path d="M4.25 7.5a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0z" fill={fill}/>
		<path d="M14.169 11.014c-.182.266-.272.4-.238.531.034.132.155.192.397.313a3.75 3.75 0 1 0 1.01-7.05c-.267.048-.4.071-.47.188-.07.117-.02.27.079.578.196.607.302 1.254.302 1.926a6.22 6.22 0 0 1-1.08 3.514z" fill={fill}/>
		<path d="M18.683 20.25c1.352 0 2.404-.578 3.279-1.299.518-.427.857-.977.775-1.626-.073-.583-.467-1.017-.807-1.305-.557-.472-1.37-.868-1.882-1.117a15.547 15.547 0 0 1-.29-.144 9.19 9.19 0 0 0-3.216-.953c-.723-.078-1.084-.117-1.167.099-.082.216.24.447.883.91.245.175.481.364.689.56.467.44 1.152 1.235 1.282 2.374a3.22 3.22 0 0 1-.166 1.455c-.185.523-.278.785-.198.909a.254.254 0 0 0 .014.02c.09.117.328.117.804.117z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_users;