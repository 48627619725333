import React from 'react';

function IC_COPY(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M16.055 8.25c1.367 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337v1.11c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116h-1.11c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.602-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337v-1.11c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117h1.11z" fill={fill}/>
		<path d="M6.75 14.857c0-1.294 0-2.481.13-3.449.143-1.066.48-2.193 1.408-3.12.927-.928 2.054-1.265 3.12-1.408.968-.13 2.155-.13 3.449-.13h2.236c.296 0 .444 0 .533-.096.09-.096.078-.24.057-.528-.018-.25-.042-.487-.072-.71-.13-.96-.388-1.758-.94-2.43a4.751 4.751 0 0 0-.658-.658c-.709-.582-1.557-.837-2.588-.96-1.005-.118-2.274-.118-3.876-.118H9.45c-1.602 0-2.872 0-3.876.119-1.03.122-1.88.377-2.588.96a4.75 4.75 0 0 0-.659.658c-.582.709-.837 1.557-.96 2.588C1.25 6.58 1.25 7.85 1.25 9.451v.098c0 1.602 0 2.872.119 3.876.122 1.03.377 1.88.96 2.588.197.24.417.461.658.659.671.551 1.469.81 2.428.94.224.03.46.053.71.071.29.021.433.032.53-.057.095-.089.095-.237.095-.533v-2.236z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_COPY;