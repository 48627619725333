import { Stack, Typography } from "@mui/material";
import { LOGO } from "../../../assets";

export default function StaticPayoutCard(props: {
  name: string;
  profitShare: string;
}) {
  return (
    <Stack
      spacing={"35px"}
      sx={{
        width: "311px",
        height: "305px",
        borderRadius: "18px",
        border: "1px solid rgba(60, 215, 205, 0.15)",
        background: "rgba(14, 11, 52, 0.25)",
        backdropFilter: "blur(12.5px)",
        p: "38px",
        pointerEvents: "none",
      }}
    >
      {/* The logo  */}
      <Stack direction={"row"} spacing="20px" alignItems={"center"}>
        <img
          src={LOGO}
          alt=""
          style={{ width: "40px", objectFit: "contain" }}
        />
        <Typography
          color={"secondary"}
          sx={{
            fontFamily: "Helvetica-Medium",
            fontSize: 18,
            letterSpacing: "1.8px",
            pointerEvents: "none",
          }}
        >
          ATU PAYOUT
        </Typography>
      </Stack>

      {/* Name  */}
      <Stack>
        <Typography
          sx={{
            fontFamily: "Helvetica",
            fontSize: 16,
            opacity: 0.6,
            pointerEvents: "none",
          }}
        >
          Proudly presented to
        </Typography>
        <Typography
          sx={{
            fontFamily: "Space Grotesk",
            fontSize: 24,
            fontWeight: "500",
            pointerEvents: "none",
          }}
        >
          {props.name}
        </Typography>
      </Stack>

      {/* Profit Share  */}
      <Stack>
        <Typography
          sx={{
            fontFamily: "Helvetica",
            fontSize: 16,
            pointerEvents: "none",
            opacity: 0.6,
          }}
        >
          Your profit share
        </Typography>
        <Typography
          color="secondary"
          sx={{
            fontFamily: "Space Grotesk",
            fontSize: 36,
            pointerEvents: "none",
            fontWeight: "500",
          }}
        >
          {props.profitShare}
        </Typography>
      </Stack>
    </Stack>
  );
}
