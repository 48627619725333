import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentUser, aShowSidebar } from "../../../core/atoms";
import ZCircularProgress from "../../../components/ZCircularProgress";
import { ArrowRightAlt, ChevronLeft, Newspaper } from "@mui/icons-material";
import { LOGO } from "../../../assets";
import IC_PLAY from "../../../assets/icons/ui/IC_PLAY";
import IC_EVENT from "../../../assets/icons/ui/IC_EVENT";
import { DailyEvent } from "../../../types/DailyEvent";
import NamedChip from "../../../components/NamedChip";
import { useEffect, useState } from "react";
import {
  RtmGetEvents,
  RtmGetFundamentals,
  RtmGetUserProgress,
} from "../../../core/rtm/user";
import { GetValueForValues } from "../../../core/helper";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { WeeklyReport } from "../../../types/WeeklyReport";
import { Lesson } from "../../../types/Lesson";
import { Chapter } from "../../../types/Chapter";
import { useNavigate } from "react-router";
import { ClockIcon } from "@mui/x-date-pickers";
import ViewWeeklyReportDialog from "../../../dialogs/ViewReport";
import { useModal } from "mui-modal-provider";
import LoadingView from "../../../components/Loading";

function CourseCompletion(props: {
  progress?: {
    completed: number;
    currentLesson: Lesson;
    currentChapter: Chapter;
    watchTime: number;
    totalLessons: number;
    percentage: number;
    totalQuizzes: number;
    quizzesCompleted: number;
  };
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  function secondsToHumanReadable(seconds: number): string {
    // Ensure the input is a valid number
    if (isNaN(seconds) || seconds < 0) {
      throw new Error(
        "Invalid input: seconds should be a non-negative number."
      );
    }

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format each unit to always have two digits
    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = remainingSeconds.toString().padStart(2, "0");

    // Combine the formatted units into a human-readable string
    return `${hoursString}h:${minutesString}m:${secondsString}s`;
  }
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        border: "1px solid #404040",
        borderRadius: "12px",
        p: "24px",
        minWidth: "500px",
        [theme.breakpoints.down("md")]: {
          minWidth: "100%",
        },
      }}
    >
      <Stack>
        <Typography
          fontSize={28}
          fontFamily={"Space Grotesk"}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 18,
            },
          }}
        >
          Course Completion
        </Typography>
        <Typography
          fontSize={18}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
        >
          Lessons Completed {props.progress?.completed} out of{" "}
          {props.progress?.totalLessons}.
        </Typography>
        <Typography
          fontSize={18}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
        >
          Quizzes Passed {props.progress?.quizzesCompleted} out of{" "}
          {props.progress?.totalQuizzes}.
        </Typography>
        <Typography
          fontSize={16}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
        >
          Total watch time:{" "}
          {secondsToHumanReadable(props.progress?.watchTime || 0)}
        </Typography>
      </Stack>
      <Stack>
        <ZCircularProgress
          value={props.progress?.percentage}
          size={isDesktop ? 128 : 64}
          variant="determinate"
          label={`${Math.ceil(props.progress?.percentage || 0)}%`}
        />
      </Stack>
    </Stack>
  );
}

function ContinueLearning(props: { lesson?: Lesson; chapter?: Chapter }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  return (
    <Stack
      flex={1}
      sx={{ border: "1px solid #404040", borderRadius: "12px", p: "24px" }}
      spacing={"24px"}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <Typography fontSize={18} fontFamily={"Space Grotesk"} fontWeight={500}>
          Continue learning
        </Typography>
        <ArrowRightAlt />
      </Stack>
      <Stack
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Stack
          sx={{
            position: "relative",
            width: "250px",
            height: "170px",
            background: "#1C1C1C",
            borderRadius: "10px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img
            src={props.lesson?.thumbnail || LOGO}
            alt={props.lesson?.title}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              padding: "8px",
              opacity: 0.6,
            }}
          />
          <IconButton
            onClick={() =>
              navigate(
                `/dashboard/library/${props.chapter!.id}/${props.lesson!.id}`
              )
            }
            sx={{ height: "64px", width: "64px", position: "absolute" }}
          >
            <SvgIcon sx={{ height: "56px", width: "56px" }}>
              <IC_PLAY />
            </SvgIcon>
          </IconButton>
        </Stack>
        <Stack justifyContent={"space-between"}>
          <Stack>
            <Typography
              fontSize={16}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                },
              }}
            >
              Chapter # {props.chapter?.index}
            </Typography>
            <Typography
              fontSize={26}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 18,
                },
              }}
            >
              Lesson {props.lesson?.index}: {props.lesson?.title}
            </Typography>
            <Typography
              fontSize={18}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 14,
                },
              }}
            >
              {props.lesson?.excerpt}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
            {props.lesson?.tags?.map((t) => (
              <Chip label={t} size="small" />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

function WeekReport() {
  const { showModal } = useModal();
  const [report, setReport] = useState<WeeklyReport>();
  const editor = useCreateBlockNote({});
  async function load() {
    try {
      const _data = await RtmGetFundamentals({
        page: 0,
        filter: { date: "week", now: new Date().toISOString() },
      });
      if (_data && _data.data.length > 0) {
        setReport(_data.data[0]);
        editor.replaceBlocks(
          editor.document,
          JSON.parse(_data.data[0].content)
        );
      }
    } catch (err: any) {
      console.error("Error loading data for todays events. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      flex={1}
      sx={{
        border: "1px solid #404040",
        borderRadius: "12px",
        p: "24px",
        alignSelf: "flex-start",
        overflow: "hidden",
      }}
      spacing={"24px"}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
        <Newspaper />
        <Typography fontSize={20} fontFamily={"Space Grotesk"} fontWeight={500}>
          Weekly Report
        </Typography>
      </Stack>
      {report && (
        <Stack spacing={"8px"}>
          <Typography fontSize={26}>{report?.title}</Typography>
          {/* <BlockNoteView editor={editor} editable={false} /> */}
          <Typography fontSize={18}>{report?.summary}</Typography>
          <Stack direction={"row"} spacing={"8px"}>
            {report?.tags?.map((t) => (
              <Chip label={t} />
            ))}
          </Stack>
          {report.events!.length > 0 && (
            <Typography>Relevant Events</Typography>
          )}
          {report.events?.map((ev) => (
            <Typography
              fontSize={16}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {(ev as DailyEvent).title}
            </Typography>
          ))}
          <Button
            color="inherit"
            onClick={() => {
              const modal = showModal(ViewWeeklyReportDialog, {
                report: report,
                onClose() {
                  modal.destroy();
                },
              });
            }}
            endIcon={
              <SvgIcon>
                <ArrowRightAlt />
              </SvgIcon>
            }
            sx={{ alignSelf: "flex-start" }}
          >
            Read Full Report
          </Button>
        </Stack>
      )}
      {!report && (
        <Stack alignItems={"center"} spacing="8px">
          <SvgIcon sx={{ height: "48px", width: "48px", opacity: 0.5 }}>
            <ClockIcon />
          </SvgIcon>
          <Typography fontSize={18} sx={{ opacity: 0.7 }}>
            No data. Check back later!
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

function RenderEvent(props: { event: DailyEvent }) {
  const event = props.event;
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const expandable = Boolean(event.author && event.comments);
  const editor = useCreateBlockNote({
    initialContent: event.comments ? JSON.parse(event.comments) : undefined,
  });
  return (
    <Stack
      spacing={"8px"}
      sx={{
        background: "rgba(36, 36, 36, 0.39)",
        border: "1px solid rgba(56, 56, 56, 0.50)",
        borderRadius: "8px",
        p: "8px",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        onClick={expandable ? () => setExpanded(true) : undefined}
      >
        <Typography
          onClick={() => window.open(event.url)}
          fontSize={18}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {event.title}
        </Typography>
        {expandable && (
          <SvgIcon
            sx={{
              transition: "all .5s",
              transform: expanded ? undefined : "rotate(-90deg)",
            }}
          >
            <ChevronLeft />
          </SvgIcon>
        )}
      </Stack>

      {expandable && expanded && (
        <Stack sx={{ background: "#222", px: "24px", py: "12px" }}>
          <BlockNoteView
            theme={darkDefaultTheme}
            editor={editor}
            editable={false}
          />
          {event.tags?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px" }}>
              <Typography>Tags</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                {event.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            </Stack>
          )}
          {typeof event.author !== "string" && (
            <Stack spacing={"8px"} sx={{ mt: "18px" }}>
              <Typography>Authored By</Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  alt={event.author.name}
                  src={event.author.image}
                  sx={{ background: "#444" }}
                >
                  {event.author.name[0]}
                </Avatar>
                <Typography>{event.author.name}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}

      <Stack
        direction={"row"}
        alignItems={"center"}
        rowGap="10px"
        columnGap={"10px"}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        <NamedChip
          label="Impact"
          value={event.impact}
          color={GetValueForValues(
            [
              { option: "Low", value: undefined },
              { option: "Medium", value: theme.palette.info.main },
              { option: "High", value: theme.palette.error.main },
            ],
            event.impact
          )}
        />
        {event.previous && (
          <NamedChip label="Previous" value={event.previous} />
        )}
        {event.forecast && (
          <NamedChip label="Forecast" value={event.forecast} />
        )}
        {event.country && <NamedChip label="Currency" value={event.country} />}
      </Stack>
    </Stack>
  );
}

function TodaysEvents() {
  const [data, setData] = useState<DailyEvent[]>([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  async function load() {
    try {
      const _events = await RtmGetEvents({
        page: 0,
        filter: { date: "today", now: new Date().toISOString() },
      });
      if (_events) {
        setData(_events.data);
      }
    } catch (err: any) {
      console.error("Error loading data for todays events. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      sx={{
        border: "1px solid #404040",
        borderRadius: "12px",
        p: "24px",
        alignSelf: "flex-start",
        flex: 1,
        [theme.breakpoints.down("md")]: {
          alignSelf: "auto",
          flex: 0,
        },
      }}
      spacing={"24px"}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
        <SvgIcon>
          <IC_EVENT />
        </SvgIcon>
        <Typography fontSize={20} fontFamily={"Space Grotesk"} fontWeight={500}>
          Today's Events
        </Typography>
      </Stack>
      {data.length === 0 && (
        <Stack alignItems={"center"} spacing="8px">
          <SvgIcon sx={{ height: "48px", width: "48px", opacity: 0.5 }}>
            <ClockIcon />
          </SvgIcon>
          <Typography fontSize={14} sx={{ opacity: 0.7 }}>
            No data. Check back later!
          </Typography>
        </Stack>
      )}
      <Stack spacing={"12px"}>
        {data.map((d) => (
          <RenderEvent event={d} />
        ))}
      </Stack>
    </Stack>
  );
}

export default function SectionDashboard() {
  const [user] = useRecoilState(aCurrentUser);
  const [progress, setProgress] = useState<{
    completed: number;
    currentLesson: Lesson;
    currentChapter: Chapter;
    watchTime: number;
    totalLessons: number;
    percentage: number;
    totalQuizzes: number;
    quizzesCompleted: number;
  }>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);

  async function load() {
    try {
      const _data = await RtmGetUserProgress();
      if (_data) {
        setProgress(_data);
      }
    } catch (err: any) {
      console.error("Error loading data for progress. ", err);
    }
  }

  useEffect(() => {
    load();
    setShowSidebar(false);
  }, []);

  if (!user || !progress) {
    return <LoadingView />;
  }

  return (
    <Stack
      flex={1}
      sx={{
        overflowY: "auto",
        flex: 1,
        p: "24px",
        [theme.breakpoints.down("md")]: {
          p: "12px",
        },
      }}
      position={"relative"}
      spacing="24px"
    >
      <Stack>
        <Typography
          fontSize={26}
          fontFamily={"Space Grotesk"}
          fontWeight={"500"}
        >
          Dashboard
        </Typography>
        <Typography fontSize={20} fontWeight={"400"}>
          Welcome back, {user?.firstName}!
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <CourseCompletion progress={progress} />
        <ContinueLearning
          lesson={progress?.currentLesson}
          chapter={progress?.currentChapter}
        />
      </Stack>
      <Stack
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <WeekReport />
        <TodaysEvents />
      </Stack>
      <div
        style={{
          background: "#48F",
          height: isDesktop ? "200px" : "150px",
          width: isDesktop ? "200px" : "150px",
          position: "absolute",
          filter: "blur(200px)",
          left: 200,
          zIndex: -4,
          pointerEvents: "none",
        }}
      />
    </Stack>
  );
}
