import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import IC_ALERT from "../assets/icons/ui/IC_ALERT";

export default function ConfirmationDialog(props: {
  title: string;
  body: string;
  onClose: (result: boolean) => void;
}) {
  return (
    <Dialog fullWidth open>
      <DialogTitle>
        <Stack direction="row" spacing={"10px"} alignItems={"center"}>
          <SvgIcon>
            <IC_ALERT />
          </SvgIcon>
          <Typography
            sx={{ fontSize: 22, fontWeight: 500, fontFamily: "Space Grotesk" }}
          >
            {props.title}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ py: "24px" }}>{props.body}</Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ p: "14px" }} spacing={"8px"}>
          <Button onClick={() => props.onClose?.(false)} color="info">
            Cancel
          </Button>
          <Button
            color="warning"
            variant="contained"
            onClick={() => props.onClose(true)}
          >
            Yes
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
