import React from 'react';

function IC_UPLOAD(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M13.006 21.25a1 1 0 0 1-2 0v-4.5h-.594c-.176 0-.392 0-.568-.022H9.84c-.127-.016-.703-.088-.977-.653-.275-.567.027-1.067.092-1.176l.002-.004c.093-.153.227-.323.337-.464l.024-.03c.294-.376.676-.86 1.057-1.251.19-.196.407-.397.638-.556.205-.14.555-.344.986-.344.43 0 .78.203.986.344.231.16.448.36.638.556.38.39.762.875 1.057 1.25l.024.03c.11.142.244.312.337.465l.002.004c.065.109.367.609.092 1.176-.274.565-.85.637-.976.653h-.004c-.176.022-.392.022-.568.022h-.582v4.5z" fill={fill} fillRule="evenodd"/>
		<path d="M1.25 12.5a5.752 5.752 0 0 1 4.208-5.54c.196-.055.294-.082.35-.14.057-.058.082-.156.131-.352a6.252 6.252 0 0 1 12.272.831c.027.246.041.368.104.44.062.07.184.1.427.159A5.25 5.25 0 0 1 17.5 18.25h-1.1c-.458 0-.687 0-.717-.038a.08.08 0 0 1-.021-.052c-.005-.048.19-.238.58-.617a2.5 2.5 0 0 0 .05-3.535c-.648-.81-1.26-1.666-2.054-2.342-.63-.536-1.395-.916-2.238-.916s-1.609.38-2.238.916c-.794.676-1.406 1.532-2.055 2.341a2.5 2.5 0 0 0 .05 3.536c.391.38.586.57.581.617a.08.08 0 0 1-.02.052c-.03.038-.26.038-.719.038H7a5.75 5.75 0 0 1-5.75-5.75z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_UPLOAD;