import { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { User } from "../types/User";
import IC_USER_EDIT from "../assets/icons/ui/IC_USER_EDIT";
import ZInput from "../components/ZInput";
import ZAutoComplete from "../components/ZAutoComplete";
import DisableUserDialog from "./DisableUser";
import EnableUserDialog from "./EnableUser";
import CancelSubscriptionADialog from "./CancelSubscriptionA";
import {
  RtmGetReferralsByUserA,
  RtmGetUserProgressA,
  RtmImpersonateA,
  RtmUpdateRoleA,
} from "../core/rtm/admin";
import Stripe from "stripe";
import { Lesson } from "../types/Lesson";
import { Chapter } from "../types/Chapter";
import ZCircularProgress from "../components/ZCircularProgress";

const ROLE_OPTIONS = [
  { label: "Administrator", id: "administrator" },
  { label: "User", id: "user" },
  { label: "Moderator", id: "moderator" },
];

export default function EditUserDialog(props: {
  user: User & { referrals?: number };
  onClose?: () => void;
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [referrals, setReferrals] = useState<User[]>([]);
  const [progress, setProgress] = useState<{
    completed: number;
    currentLesson: Lesson;
    currentChapter: Chapter;
    watchTime: number;
    totalLessons: number;
    percentage: number;
    totalQuizzes: number;
    quizzesCompleted: number;
  }>();

  const [busy, setBusy] = useState(false);

  const { showModal } = useModal();

  async function updateUserRole(newRole: string) {
    try {
      setBusy(true);
      await RtmUpdateRoleA(props.user.uid, newRole as any);
      setRole(newRole);
      enqueueSnackbar(`Role updated successfully.`, {
        variant: "success",
      });
    } catch (err: any) {
      enqueueSnackbar("Error updating user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function impersonateUser() {
    try {
      setBusy(true);
      const _result = await RtmImpersonateA(props.user.uid);
      if (_result) {
        enqueueSnackbar(`Logging in as user. `, {
          variant: "success",
        });
        // Reload the web page, as its required for connection to reset.
        window.location.reload();
      }
    } catch (err: any) {
      enqueueSnackbar("Error updating user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function showDisableDialog() {
    const modal = showModal(DisableUserDialog, {
      user: props.user,
      onClose(resukt) {
        modal.destroy();
        if (resukt) {
          setDisabled(true);
        }
      },
    });
  }

  function showEnableDialog() {
    const modal = showModal(EnableUserDialog, {
      user: props.user,
      onClose(result) {
        modal.destroy();
        if (result) {
          setDisabled(false);
        }
      },
    });
  }

  function showCancelSubscriptionDialog() {
    const modal = showModal(CancelSubscriptionADialog, {
      user: props.user,
      onClose(resukt) {
        modal.destroy();
        if (resukt) {
          props.onClose?.();
        }
      },
    });
  }

  async function loadReferrals() {
    try {
      setBusy(true);
      const _result = await RtmGetReferralsByUserA(props.user.uid);
      if (_result) {
        setReferrals(_result);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading user data. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function loadProgress() {
    try {
      setBusy(true);
      const _result = await RtmGetUserProgressA(props.user.uid);
      if (_result) {
        setProgress(_result);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading user data. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setFirstName(props.user?.firstName || "");
    setLastName(props.user?.lastName || "");
    setEmail(props.user?.email || "");
    setRole(props.user?.role || "");
    setDisabled(props.user?.disabled || false);
    loadReferrals();
    loadProgress();
  }, []);

  function secondsToHumanReadable(seconds: number): string {
    // Ensure the input is a valid number
    if (isNaN(seconds) || seconds < 0) {
      throw new Error(
        "Invalid input: seconds should be a non-negative number."
      );
    }

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format each unit to always have two digits
    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = remainingSeconds.toString().padStart(2, "0");

    // Combine the formatted units into a human-readable string
    return `${hoursString}h:${minutesString}m:${secondsString}s`;
  }

  return (
    <Dialog open hideBackdrop onClose={props.onClose} fullWidth>
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IC_USER_EDIT width={24} height={24} />
          <Typography>Edit User</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="16px" sx={{ px: "32px", py: "24px" }}>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              UID
            </Typography>
            <ZInput text={props.user?.uid} fullWidth disabled />
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              className="required"
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Name
            </Typography>
            <Stack direction={"row"} spacing={"8px"}>
              <ZInput
                text={firstName}
                fullWidth
                disabled={busy}
                placeholder="John"
              />
              <ZInput
                text={lastName}
                fullWidth
                disabled={busy}
                placeholder="Doe"
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Email Address
            </Typography>
            <ZInput
              disabled
              text={email}
              fullWidth
              placeholder="someone@example.com"
            />
          </Stack>

          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              className="required"
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Role
            </Typography>
            <ZAutoComplete
              key={role}
              options={ROLE_OPTIONS}
              placeholder="Select a role.."
              onUpdate={(v) => {
                updateUserRole((v as any)?.id);
              }}
              value={ROLE_OPTIONS.find((r) => r.id === role.toLowerCase())}
              important
              disabled={busy}
            />
          </Stack>

          {props.user.discord && (
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Discord
              </Typography>
              <Stack direction={"row"} spacing="12px" alignItems={"center"}>
                <Avatar
                  src={
                    props.user.discord.avatar
                      ? `https://cdn.discordapp.com/avatars/${props.user.discord.id}/${props.user.discord.avatar}.png`
                      : ""
                  }
                  sx={{ color: "white" }}
                />
                <Stack>
                  <Typography fontSize={14} fontWeight={500}>
                    {props.user.discord.global_name}
                  </Typography>
                  <Typography fontSize={14} fontWeight={400} color="#94969C">
                    @{props.user.discord.username}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}

          {/* Referrer  */}
          {props.user?.referrer && (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Referred by
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {props.user.referrer?.email}
              </Typography>
            </Stack>
          )}

          {/* Subscription Info  */}
          <Divider />

          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={20}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Subscription
            </Typography>
          </Stack>
          {/* Subscriber since */}
          {props.user?.subscription && props.user.subscription.created && (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Subscriber since
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(props.user.subscription?.created!)
                  .format("MMM DD, YYYY hh:mm A")}
              </Typography>
            </Stack>
          )}
          {/* Plan  */}
          {props.user.subscription?.metadata?.plan && (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Plan
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {props.user.subscription?.metadata.plan}
              </Typography>
            </Stack>
          )}
          {/* Payment Method  */}
          {props.user.subscription?.default_payment_method && (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Payment Method
              </Typography>
              <Typography
                fontSize={14}
                color="#94969C"
                sx={{ textTransform: "capitalize" }}
              >
                {
                  (
                    props.user.subscription
                      .default_payment_method as Stripe.PaymentMethod
                  ).type
                }
              </Typography>
            </Stack>
          )}

          {/* TODO: Add Payment Method related fields  */}
          {(props.user.subscription?.default_payment_method as any)?.type ===
            "card" && (
            <>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Brand
                </Typography>
                <Typography
                  fontSize={14}
                  color="#94969C"
                  sx={{ textTransform: "capitalize" }}
                >
                  {
                    (
                      props.user.subscription!
                        .default_payment_method as Stripe.PaymentMethod
                    ).card?.brand
                  }
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Last 4 Digits
                </Typography>
                <Typography fontSize={14} color="#94969C">
                  {
                    (
                      props.user.subscription!
                        .default_payment_method as Stripe.PaymentMethod
                    ).card?.last4
                  }
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Expiry
                </Typography>
                <Typography fontSize={14} color="#94969C">
                  {
                    (
                      props.user.subscription!
                        .default_payment_method as Stripe.PaymentMethod
                    ).card?.exp_month
                  }
                  /
                  {
                    (
                      props.user.subscription!
                        .default_payment_method as Stripe.PaymentMethod
                    ).card?.exp_year
                  }
                </Typography>
              </Stack>
            </>
          )}

          {props.user.subscription &&
            !props.user.subscription?.cancel_at_period_end && (
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Renews on
                </Typography>
                <Typography fontSize={14} color="#94969C">
                  {moment
                    .unix(props.user.subscription?.current_period_end!)
                    .format("MMM DD, YYYY hh:mm A")}
                </Typography>
              </Stack>
            )}

          {!props.user.subscription && (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px", opacity: 0.6 }}
              >
                The user does not have any active subscription.
              </Typography>
            </Stack>
          )}

          {!props.user.subscription?.cancel_at_period_end &&
            props.user.subscription?.status === "active" && (
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  {""}
                </Typography>
                <Button
                  color="error"
                  disabled={busy}
                  onClick={showCancelSubscriptionDialog}
                >
                  Cancel Subscription
                </Button>
              </Stack>
            )}

          <Divider />

          {progress && (
            <>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <Typography
                  fontSize={20}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Course Progress
                </Typography>
                <ZCircularProgress
                  value={progress.percentage}
                  variant="determinate"
                  size={48}
                  label={`${Math.ceil(progress?.percentage || 0)}%`}
                  labelProps={{ fontSize: 12 }}
                />
              </Stack>

              <Stack spacing={"8px"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                >
                  <Typography fontSize={14} fontWeight={400}>
                    Lessons Completed
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {progress.completed} out of {progress.totalLessons}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                >
                  <Typography fontSize={14} fontWeight={400}>
                    Total Watch Time
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {secondsToHumanReadable(progress.watchTime)}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                >
                  <Typography fontSize={14} fontWeight={400}>
                    Quizzes Passed
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {progress.quizzesCompleted} out of {progress.totalQuizzes}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ width: "100%" }}
                >
                  <Typography fontSize={14} fontWeight={400}>
                    Current Lesson
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {progress.currentLesson.title} (
                    {progress.currentChapter.title})
                  </Typography>
                </Stack>
              </Stack>

              <Divider />
            </>
          )}

          {referrals.length > 0 && (
            <>
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Typography
                  fontSize={20}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Referrals{" "}
                  <span
                    style={{
                      padding: 8,
                    }}
                  >
                    {referrals.length}
                  </span>
                </Typography>
              </Stack>
              <Stack spacing={"8px"}>
                {referrals.map((r) => (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={"8px"}
                  >
                    {/* Avatar  */}
                    <Avatar src={r.image} alt={r.firstName} />
                    <Stack>
                      <Typography>
                        {r.firstName} {r.lastName}
                      </Typography>
                      <Typography sx={{ opacity: 0.7, fontSize: 12 }}>
                        {r.email}
                      </Typography>
                    </Stack>
                    <Typography sx={{ opacity: 0.7, fontSize: 12 }}>
                      Registered on{" "}
                      {moment.unix(r.registered).format("YYYY-MM-DD hh:mm A")}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
              <Divider />
            </>
          )}

          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={20}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Activity
            </Typography>
          </Stack>

          {/* Date Registered  */}
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Registered on
            </Typography>
            <Typography fontSize={14} color="#94969C">
              {moment
                .unix(props.user?.registered!)
                .format("MMM DD, YYYY hh:mm A")}
            </Typography>
          </Stack>
          {/* Date Updated  */}
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Last activity
            </Typography>
            <Typography fontSize={14} color="#94969C">
              {moment.unix(props.user?.updated!).format("MMM DD, YYYY hh:mm A")}
            </Typography>
          </Stack>

          {/* Manage  */}
          <Divider />
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Typography
              fontSize={20}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Manage Access
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Stack
              direction={"row"}
              sx={{ width: "100%", px: "32px" }}
              spacing="12px"
            >
              <Button
                disabled={busy}
                onClick={impersonateUser}
                size="small"
                fullWidth
                variant="contained"
                color="primary"
              >
                Sign In as User
              </Button>
              {disabled && (
                <Button
                  disabled={busy}
                  onClick={showEnableDialog}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Enable
                </Button>
              )}
              {!disabled && (
                <Button
                  disabled={busy}
                  onClick={showDisableDialog}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="warning"
                >
                  Disable Account
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} sx={{ px: "14px", py: "12px" }} spacing="12px">
          <Button
            disabled={busy}
            onClick={props.onClose}
            size="large"
            variant="contained"
            color="inherit"
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
