import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import { DiscordGetMe } from "../../core/helper";
import SplashScreen from "../../components/SplashScreen";
import { RtmSetDiscordProfile } from "../../core/rtm/user";

export default function PageDiscordHandler() {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);
  const loc = useLocation();
  const hParams = new URLSearchParams(loc.hash);

  const navigate = useNavigate();

  async function syncDiscordProfile() {
    try {
      setBusy(true);
      // Get the discord user's profile
      const prof = await DiscordGetMe(hParams.get("access_token")!);
      // Update the profile
      if (prof) {
        // Update user's discord profile
        await RtmSetDiscordProfile(prof);
      }
      setVerified(true);
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying your account. Please contact administrators.",
        {
          variant: "error",
        }
      );
      setError(true);
    }
    setBusy(false);
  }

  useEffect(() => {
    if (!hParams.has("access_token")) {
      // Token not sent
      navigate("/");
    } else {
      syncDiscordProfile();
    }
  }, []);

  return <SplashScreen />;
}
