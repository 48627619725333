import React from 'react';

function IC_NEWS(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M5.828 6.75h.99c.512 0 .928.433.928.967v.966H5.89c-.902 0-1.485.002-1.914.063-.403.056-.529.147-.6.22-.07.074-.158.205-.212.625-.058.447-.06 1.054-.06 1.992v6.284c0 .8.803 1.45 1.572 1.45s1.571-.65 1.571-1.45h1.5c0 1.868-1.455 3.383-3.25 3.383s-3.25-1.515-3.25-3.383V11.52c0-.857 0-1.596.076-2.187.082-.634.267-1.24.74-1.734.473-.492 1.057-.684 1.666-.77.568-.08 1.277-.079 2.1-.079z" fill={fill}/>
		<path d="M19.392 2.867c-.867-.117-1.97-.117-3.337-.117h-5.17c-.819 0-1.377 0-1.855.128a3.75 3.75 0 0 0-2.652 2.651c-.128.48-.128 1.037-.128 1.855V18a1.75 1.75 0 0 1-1.75 1.75.75.75 0 0 0 0 1.5h11.555c1.368 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337v-5.11c0-1.367 0-2.47-.116-3.337-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981zM10.5 7.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8zm0 4a.75.75 0 0 0 0 1.5H13a.75.75 0 0 0 0-1.5h-2.5zm5.5 0a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5H16zm-5.5 4a.75.75 0 0 0 0 1.5H13a.75.75 0 0 0 0-1.5h-2.5zm5.5 0a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5H16z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_NEWS;