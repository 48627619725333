import React from 'react';

function IC_NOTE_REMOVE(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M5.875 1a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0V2a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M12.43 2.25H9.32c-1.603 0-2.882 0-3.885.135-1.037.14-1.89.435-2.565 1.11S1.9 5.023 1.76 6.06c-.135 1.003-.135 2.282-.135 3.884v5.112c0 1.602 0 2.881.135 3.884.14 1.037.435 1.89 1.11 2.565s1.528.97 2.565 1.11c1.003.135 2.282.135 3.884.135h2.182c.157 0 .236 0 .325-.098.089-.098.079-.207.06-.426a2.495 2.495 0 0 1 .721-1.994l1.308-1.308c.2-.2.3-.3.3-.424s-.1-.224-.3-.424l-1.308-1.308a2.5 2.5 0 0 1 3.536-3.536l1.308 1.308c.2.2.3.3.424.3s.224-.1.424-.3l1.308-1.308c.074-.073.151-.142.231-.204.15-.118.226-.177.257-.239.03-.062.03-.142.03-.301V9.944c0-1.602 0-2.881-.135-3.884-.14-1.037-.435-1.89-1.11-2.565s-1.528-.97-2.565-1.11c-1.003-.135-2.282-.135-3.884-.135zm-5.555 7a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8zm0 5a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3z" fill={fill} fillRule="evenodd"/>
		<path d="M13.668 14.293a1 1 0 0 1 1.414 0l2.793 2.793 2.793-2.793a1 1 0 0 1 1.414 1.414L19.29 18.5l2.793 2.793a1 1 0 0 1-1.414 1.414l-2.793-2.793-2.793 2.793a1 1 0 0 1-1.414-1.414L16.46 18.5l-2.793-2.793a1 1 0 0 1 0-1.414z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_NOTE_REMOVE;