import { Rating, Stack, Typography } from "@mui/material";

export default function StaticTestimonialCard(props: {
  name: string;
  message: string;
}) {
  return (
    <Stack
      spacing={"38px"}
      sx={{
        width: "311px",
        height: "320px",
        borderRadius: "18px",
        border: "1px solid rgba(60, 215, 205, 0.15)",
        background: "rgba(14, 11, 52, 0.25)",
        backdropFilter: "blur(12.5px)",
        p: "38px",
      }}
    >
      {/* Ratings icon  */}
      <Rating
        readOnly
        value={5}
        color="primary"
        sx={{
          "& .MuiRating-iconFilled": {
            color: "#FFF",
            background: "#00B67A",
            marginRight: "1px",
          },
        }}
      />
      <Stack flex={1} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontFamily: "Helvetica",
            fontSize: 18,
            letterSpacing: "-0.18px",
          }}
        >
          “{props.message}”
        </Typography>

        {/* The name  */}
        <Typography
          color={"secondary"}
          sx={{ fontFamily: "Helvetica", fontSize: 14 }}
        >
          {props.name}
        </Typography>
      </Stack>
    </Stack>
  );
}
