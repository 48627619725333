import React from 'react';

function IC_FILE(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M16.564 1.353c-.983-.103-2.216-.103-3.757-.103h-.875c-1.893 0-3.407 0-4.595.15-1.224.156-2.25.487-3.069 1.26-.827.782-1.188 1.774-1.357 2.958C2.75 6.752 2.75 8.19 2.75 9.97v6.15c0 .8 0 1.447.035 1.975.036.545.111 1.03.294 1.495.53 1.339 1.638 2.373 3.014 2.858.859.303 2.331.302 3.886.302 2.84 0 4.526 0 5.909-.486 2.22-.783 3.993-2.445 4.837-4.581.281-.712.406-1.477.466-2.394.059-.9.059-2.013.059-3.432V9.274c0-1.448 0-2.62-.11-3.558-.114-.974-.357-1.812-.927-2.53a5.007 5.007 0 0 0-1.034-.978c-.745-.527-1.606-.75-2.615-.855zm-10.96 2.73c.392-.37.948-.612 1.978-.743 1.051-.134 2.441-.135 4.418-.135h.752c1.61 0 2.74 0 3.61.092.854.09 1.338.256 1.695.509.242.17.454.372.632.596.256.323.425.755.517 1.542.095.81.097 1.867.097 3.39v2.9c0 .268 0 .906-.281 1.379-.173.29-.394.534-.622.658-.366.2-.787.313-1.233.313l-1.04-.037c-.38-.008-.824.004-1.252.119a2.417 2.417 0 0 0-1.71 1.709c-.114.428-.126.871-.118 1.251l.037 1.04c0 .468-.125.876-.342 1.254-.127.22-.348.422-.652.6-.463.269-1.016.271-1.354.273l-.993.002c-1.834 0-2.501-.014-3.005-.19-.872-.308-1.538-.95-1.848-1.735-.078-.197-.133-.455-.162-.903-.03-.457-.03-1.04-.03-1.88v-6.041c0-1.873.002-3.172.141-4.152.135-.945.38-1.447.764-1.81z" fill={fill} fillRule="evenodd"/>
		<path d="M7 7a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M7 11a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_FILE;