import React from 'react';

function IC_CARD_ERROR(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M15.168 13.168a1 1 0 0 1 1.414 0l2.293 2.293 2.293-2.293a1 1 0 0 1 1.414 1.414l-2.293 2.293 2.293 2.293a1 1 0 0 1-1.414 1.414l-2.293-2.293-2.293 2.293a1 1 0 0 1-1.414-1.414l2.293-2.293-2.293-2.293a1 1 0 0 1 0-1.414z" fill={fill} fillRule="evenodd"/>
		<path d="M17.844 3.254c-1.138-.129-2.582-.129-4.421-.129h-3.095c-1.84 0-3.284 0-4.422.129-1.163.13-2.105.405-2.888 1.023-.235.185-.452.39-.65.612-.666.747-.964 1.653-1.105 2.764-.017.128-.025.192-.01.248.023.09.092.168.178.202.054.022.121.022.256.022h20.376c.135 0 .202 0 .256-.022a.307.307 0 0 0 .178-.202c.015-.056.007-.12-.01-.248-.141-1.111-.439-2.017-1.105-2.764a5.128 5.128 0 0 0-.65-.612c-.783-.618-1.725-.892-2.888-1.023z" fill={fill}/>
		<path d="M22.623 10.672c.002.343.003.514-.083.602-.085.088-.297.092-.72.102a2.491 2.491 0 0 0-1.713.731l-.808.808c-.2.2-.3.3-.424.3s-.224-.1-.424-.3l-.808-.808a2.5 2.5 0 0 0-3.536 3.536l.808.808c.2.2.3.3.3.424s-.1.224-.3.424l-.808.808a2.492 2.492 0 0 0-.731 1.713c-.01.422-.014.634-.102.72-.087.085-.259.085-.601.085h-2.345c-1.84 0-3.284 0-4.422-.129-1.163-.13-2.105-.405-2.888-1.023a5.13 5.13 0 0 1-.65-.612c-.666-.747-.964-1.653-1.105-2.764-.138-1.08-.138-2.445-.138-4.17v-.105c0-.575 0-1.111.005-1.61.003-.276.004-.413.092-.5.088-.087.227-.087.504-.087h20.298c.277 0 .416 0 .504.087.088.087.09.224.092.5l.003.46z" fill={fill}/>
	</g>
</svg>
	);
};

export default IC_CARD_ERROR;