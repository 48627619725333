import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useEffect, useState } from "react";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import Ic_add from "../../../assets/icons/ui/ic_add";
import { useNavigate } from "react-router";
import { User } from "../../../types/User";
import { RtmGetUsersA } from "../../../core/rtm/admin";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { Role } from "../../../types/Role";
import ZTable from "../../../components/ZTable";
import { Filter } from "@mui/icons-material";
import ZSelect from "../../../components/ZSelect";
import ZInput from "../../../components/ZInput";
import Ic_search from "../../../assets/icons/ui/ic_search";
import IC_SORT_A from "../../../assets/icons/ui/IC_SORT_A";
import IC_SORT_Z from "../../../assets/icons/ui/IC_SORT_Z";
import IC_FILTER from "../../../assets/icons/ui/IC_FILTER";
import IC_FILTER_EDIT from "../../../assets/icons/ui/IC_FILTER_EDIT";
import AddUserDialog from "../../../dialogs/AddUser";
import EditUserDialog from "../../../dialogs/EditUser";
import IC_DISCORD from "../../../assets/icons/ui/IC_DISCORD";

export default function SectionUsersA() {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<User[]>([]);
  const [busy, setBusy] = useState(false);
  const [sort, setSort] = useState<{
    type:
      | "registered"
      | "updated"
      | "firstName"
      | "lastName"
      | "subscription"
      | "lastSignIn"
      | "role";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    type: "role" | "subscription";
    value: any;
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  const [filterPopoverAnchor, setfilterPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);

  async function loadUsers(page: number) {
    try {
      //Load data
      setBusy(true);
      const users = await RtmGetUsersA({
        page: page,
        sort: sort,
        filter: filter,
        search: search,
      });
      if (users) {
        setTotalPages(users.pageCount);
        setPageNumber(users.page);
        setTotalUsers(users.size);
        setData(users.data.filter((r) => r.role === Role.User));
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showAddUserDialog() {
    const modal = showModal(AddUserDialog, {
      onClose() {
        modal.destroy();
        loadUsers(pageNumber);
      },
    });
  }

  function showEditDialog(usr: User) {
    const modal = showModal(EditUserDialog, {
      onClose() {
        modal.destroy();
        loadUsers(pageNumber);
      },
      user: usr,
    });
  }

  useEffect(() => {
    // Load page 0
    loadUsers(0);
  }, []);

  useEffect(() => {
    loadUsers(pageNumber);
  }, [sort, filter, search]);

  function RenderUser(user: User) {
    return (
      <TableRow
        onClick={() => showEditDialog(user)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(user.uid)}
          >
            {user.uid.substring(0, 4)}...
            {user.uid.substring(user.uid.length - 4)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align="left">
          {user.discord && (
            <Stack direction={"row"} spacing="12px" alignItems={"center"}>
              <Avatar
                src={
                  user.discord.avatar
                    ? `https://cdn.discordapp.com/avatars/${user.discord.id}/${user.discord.avatar}.png`
                    : ""
                }
                sx={{ color: "white" }}
              />
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  {user.discord.global_name}
                </Typography>
                <Typography fontSize={14} fontWeight={400} color="#94969C">
                  @{user.discord.username}
                </Typography>
              </Stack>
            </Stack>
          )}
          {!user.discord && <Typography>N/C</Typography>}
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={
                user.subscription?.status === "active"
                  ? "Active Subscriber"
                  : "No Subscription"
              }
              variant="filled"
              color={
                user.subscription?.status === "active" ? "secondary" : "warning"
              }
              size="small"
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(user.registered).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {moment.unix(user.lastSignin).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip
            label={user.role}
            variant="filled"
            size="small"
            color={
              user.role === Role.Administrator
                ? "error"
                : user.role === Role.User
                ? "secondary"
                : "warning"
            }
            sx={{ textTransform: "capitalize" }}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack flex={1} sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      {/* Top section: title and add course button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Identity & Access Management
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            color="secondary"
            variant="outlined"
            onClick={() => loadUsers(pageNumber)}
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
          <Button
            disabled={busy}
            color="primary"
            onClick={showAddUserDialog}
            variant="contained"
            startIcon={<Ic_add width={24} height={24} />}
          >
            Add User
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <ZTable
        sx={{ flex: 1 }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadUsers(pn)}
        prevPage={(pn) => loadUsers(pn)}
        titleBadge={totalUsers + " Users"}
        title="Users"
        description="List of all the users on the platform."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <IconButton
              onClick={(e) => {
                if (filter) {
                  setFilter(undefined);
                } else {
                  setfilterPopoverAnchor(e.currentTarget);
                }
              }}
            >
              {filter && <IC_FILTER_EDIT width={24} height={24} />}
              {!filter && <IC_FILTER width={24} height={24} />}
            </IconButton>
            <Popover
              open={Boolean(filterPopoverAnchor)}
              anchorEl={filterPopoverAnchor}
              onClose={() => setfilterPopoverAnchor(null)}
            >
              <Stack sx={{ px: "24px", py: "12px" }}>
                <Typography>Filter Users Where</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
                  <ZSelect
                    value={filter?.type}
                    onChange={(ce) =>
                      setFilter({ type: ce?.id as any, value: filter?.value })
                    }
                    options={[{ label: "Subscription", id: "subscription" }]}
                  />
                  <Typography>is</Typography>
                  {/* We show options for plan and role, and input for balance  */}
                  {filter?.type === "subscription" && (
                    <ZSelect
                      value={filter?.value ? "true" : "false"}
                      onChange={(ce) =>
                        setFilter({
                          type: filter.type,
                          value: ce?.id === "true" ? true : false,
                        })
                      }
                      options={[
                        { label: "Active", id: "true" },
                        { label: "Not Subscribed", id: "false" },
                      ]}
                    />
                  )}
                  {filter?.type === "role" && (
                    <ZSelect
                      value={filter?.value}
                      onChange={(ce) =>
                        setFilter({
                          type: filter.type,
                          value: ce?.id,
                        })
                      }
                      options={[
                        { label: "Admin", id: "administrator" },
                        { label: "User", id: "user" },
                        { label: "Moderator", id: "moderator" },
                      ]}
                    />
                  )}
                </Stack>
              </Stack>
            </Popover>
            <ZInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={<Ic_search width={24} height={24} />}
              placeholder="Search a user..."
            />
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "firstName",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>User</Typography>
                {sort?.type === "firstName" && (
                  <>
                    {sort?.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort?.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>
            {/* Discord */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Discord</Typography>
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "subscription",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Status</Typography>
                {sort?.type === "subscription" && (
                  <>
                    {sort.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "registered",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Registered</Typography>
                {sort?.type === "registered" && (
                  <>
                    {sort.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "lastSignIn",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Last Activity</Typography>
                {sort?.type === "lastSignIn" && (
                  <>
                    {sort.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>
            {/* Role  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "role",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Role</Typography>
                {sort?.type === "role" && (
                  <>
                    {sort.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderUser(d))}</>}
      />
    </Stack>
  );
}
