import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";

export default function ViewAttachmentDialog(props: {
  attachment: { url: string; name: string; type: string };
  onClose?: () => void;
}) {
  return (
    <Dialog
      hideBackdrop
      open
      fullScreen
      onClose={props.onClose}
      PaperProps={{
        sx: {
          border: "1px solid #FFF3",
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
      sx={{ margin: "24px", borderRadius: "8px", boxShadow: "none" }}
    >
      <DialogTitle>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={20} fontFamily={"Space Grotesk"}>
            {props.attachment.name}
          </Typography>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ height: "100%", width: "100%" }}>
          {props.attachment.type.startsWith("image/") && (
            <img
              src={props.attachment.url}
              alt={props.attachment.name}
              style={{ flex: 1, width: "100%", objectFit: "contain" }}
            />
          )}
          {props.attachment.type.startsWith("video/") && (
            <ReactPlayer
              url={props.attachment.url}
              controls
              height={"100%"}
              width={"100%"}
            />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
