import React from 'react';

function Ic_setting(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M8.702 2.01a1.276 1.276 0 0 0-.687.115c-.15.066-.32.161-.484.254L5.282 3.667a1.267 1.267 0 0 0-.456.533c-.123.29-.084.583-.054.752.031.183.097.435.16.676.397 1.517-.571 3.149-2.138 3.569l-.028.007c-.237.063-.459.123-.633.186-.162.058-.434.168-.625.416a1.258 1.258 0 0 0-.241.656 5.648 5.648 0 0 0-.017.549v1.979c0 .19 0 .385.017.548.018.177.065.427.24.656.192.248.464.358.626.416.174.063.66.194.66.194 1.567.42 2.534 2.05 2.138 3.568 0 0-.13.493-.16.676-.03.169-.07.461.053.752.113.265.31.43.456.533l2.25 1.288c.164.093.333.189.483.254.163.071.403.154.688.115.309-.041.538-.217.67-.328.14-.117.491-.466.491-.466A3.028 3.028 0 0 1 12 20.328c.777 0 1.554.29 2.137.868 0 0 .352.35.492.466.132.11.361.287.67.328.285.039.525-.044.688-.115.15-.066.32-.161.483-.254l2.25-1.288c.146-.103.343-.268.456-.534.123-.29.083-.582.054-.751-.032-.183-.16-.676-.16-.676-.397-1.517.57-3.149 2.136-3.568 0 0 .487-.13.66-.194.163-.058.435-.168.626-.416.176-.229.223-.479.241-.656.017-.163.017-.358.017-.548v-1.98c0-.19 0-.385-.017-.548a1.258 1.258 0 0 0-.24-.656c-.192-.248-.464-.358-.626-.416a8.492 8.492 0 0 0-.633-.186l-.028-.007c-1.567-.42-2.535-2.052-2.138-3.569.063-.241.128-.493.16-.676.03-.17.07-.461-.054-.752a1.267 1.267 0 0 0-.456-.533l-2.25-1.288c-.163-.093-.333-.188-.483-.254a1.276 1.276 0 0 0-.687-.115c-.31.041-.538.217-.67.328-.14.117-.492.466-.492.466A3.027 3.027 0 0 1 12 3.67c-.777 0-1.553-.29-2.136-.867 0 0-.352-.35-.492-.466-.132-.11-.36-.287-.67-.328zM12 15.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_setting;