import React, { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Navigate, useNavigate } from "react-router";
import { FbAuth } from "../core/firebase";

/**
 * Renders children only if the user is logged in. Otherwise navigates to the /login (or specified redirectTo) page.
 * @returns
 */
export default function AuthenticatedRoute(props: {
  children: any;
  redirect: any;
  splash: any;
}) {
  //const user = FbAuth.currentUser;
  const nav = useNavigate();
  const [user, setUser] = React.useState<any>();
  const [ready, setReady] = React.useState(false);
  useEffect(() => {
    const unsub = getAuth().onAuthStateChanged((usr) => {
      if (usr) {
        // we are logged in
        if (!usr.emailVerified) {
          signOut(FbAuth);
          setUser(null);
          nav(props.redirect);
        } else {
          setUser(usr);
        }
      } else {
        setUser(null);
        nav(props.redirect);
      }
      setReady(true);
    });
    return unsub;
  }, []);

  if (ready && user) {
    return props.children;
  } else {
    return props.splash;
  }
}
