import { Button, Chip, Divider, Paper, Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import Ic_add from "../../../assets/icons/ui/ic_add";
import { useNavigate } from "react-router";
import { Chapter } from "../../../types/Chapter";
import { RtmGetChaptersA, RtmUpdateChapterA } from "../../../core/rtm/admin";
import ChapterCardA from "../../../components/ChapterCardA";
import AddChapterDialog from "../../../dialogs/AddChapter";
import { Reorder } from "framer-motion";
import { LOGO } from "../../../assets";
import moment from "moment";

export default function SectionChaptersA() {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [busy, setBusy] = useState(false);
  const [courses, setCourses] = useState<(Chapter & { lessons: number })[]>([]);

  function showAddChapterDialog() {
    const modal = showModal(AddChapterDialog, {
      onClose(result) {
        modal.destroy();
        if (result) {
          // Load course editor
          navigate("/dashboard/lms/chapters/" + result.id);
        }
        load();
      },
    });
  }

  async function load() {
    try {
      setBusy(true);
      const _list = await RtmGetChaptersA();
      setCourses(_list);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading chapters. ", err);
    }
    setBusy(false);
  }

  // Called after a re-order. Here we update each chapters' index to its index in array.
  async function onReordered(chapters: (Chapter & { lessons: number })[]) {
    setBusy(true);
    let i = 0;
    for (let _chapter of chapters) {
      _chapter.index = i + 1;
      await RtmUpdateChapterA(_chapter);
      i += 1;
    }
    setCourses(chapters);
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  function RenderChapter(chapter: Chapter) {
    return (
      <Stack
        spacing={"8px"}
        alignItems={"center"}
        direction={"row"}
        sx={{
          background: "#222",
          borderRadius: "8px",
          border: "1px solid #333",
          p: "8px",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "48px" }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontFamily: "Space Grotesk",
              fontWeight: "500",
            }}
          >
            #{chapter.index}
          </Typography>
        </Stack>
        <img
          src={chapter.cover || LOGO}
          style={{
            height: "48px",
            width: "100px",
            objectFit: "contain",
            border: "1px solid #6666",
            borderRadius: "8px",
            padding: "4px",
          }}
        />
        <Typography
          sx={{ fontSize: 20, fontFamily: "Space Grotesk", fontWeight: "500" }}
        >
          {chapter.title || "(Untitled Chapter)"}
        </Typography>
        :
        <Typography sx={{ fontSize: 16, opacity: 0.6 }}>
          {chapter.description || "(No description)"}
        </Typography>
        {chapter.tags?.map((t) => (
          <Chip label={t} size="small" />
        ))}
        <Stack flex={1} />
        <Typography sx={{ opacity: 0.6 }}>Last updated:</Typography>
        <Typography sx={{ opacity: 0.9 }}>
          {moment.unix(chapter.updated).fromNow()}
        </Typography>
        <Divider orientation="vertical" sx={{ height: "40px", width: "1px" }} />
        <Button
          onClick={() => navigate("/dashboard/lms/chapters/" + chapter.id)}
        >
          Edit
        </Button>
      </Stack>
    );
  }

  return (
    <Stack sx={{ p: "24px" }} gap={"8px"}>
      {/* Top section: title and add chapters button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Chapters
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            onClick={load}
            color="secondary"
            variant="outlined"
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
          <Button
            disabled={busy}
            onClick={showAddChapterDialog}
            color="primary"
            variant="contained"
            startIcon={<Ic_add width={24} height={24} />}
          >
            Add a Chapter
          </Button>
        </Stack>
      </Stack>
      <Divider />
      {/* Bottom section: Cards of the chapters  */}
      <Reorder.Group
        onReorder={onReordered}
        values={courses}
        as="div"
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          padding: 0,
          pointerEvents: busy ? "none" : "all",
        }}
      >
        {courses.map((c) => (
          <Reorder.Item
            key={c.id}
            value={c}
            id={c.id}
            as="div"
            style={{ cursor: "move" }}
          >
            <RenderChapter {...c} />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </Stack>
  );
}
