import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Stack,
  Typography,
  TypographyProps,
  circularProgressClasses,
} from "@mui/material";

export default function ZCircularProgress(
  props: CircularProgressProps & {
    label?: string;
    labelProps?: TypographyProps;
    icon?: React.ReactNode;
  }
) {
  return (
    <Stack
      sx={{
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
      {props.icon && props.icon}
      <Typography position={"absolute"} {...props.labelProps}>
        {props.label}
      </Typography>
    </Stack>
  );
}
