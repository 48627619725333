import {
  Button,
  Chip,
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useEffect, useState } from "react";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import { useNavigate } from "react-router";
import { RtmGetActivitiesA } from "../../../core/rtm/admin";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import ZTable from "../../../components/ZTable";
import ZInput from "../../../components/ZInput";
import Ic_search from "../../../assets/icons/ui/ic_search";
import IC_SORT_A from "../../../assets/icons/ui/IC_SORT_A";
import IC_SORT_Z from "../../../assets/icons/ui/IC_SORT_Z";
import { PlatformActivity } from "../../../types/PlatformActivity";

export default function SectionActivitiesA() {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalActivities, setTotalActivities] = useState(0);
  const [data, setData] = useState<PlatformActivity[]>([]);
  const [busy, setBusy] = useState(false);
  const [sort, setSort] = useState<{
    type: "created" | "source";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    type?: "information" | "error" | "warning";
    source?: string;
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  async function load(page: number) {
    try {
      //Load data
      setBusy(true);
      const users = await RtmGetActivitiesA({
        page: page,
        sort: sort,
        filter: filter,
        search: search,
      });
      if (users) {
        setTotalPages(users.pageCount);
        setPageNumber(users.page);
        setTotalActivities(users.size);
        setData(users.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    // Load page 0
    load(0);
  }, []);

  useEffect(() => {
    load(pageNumber);
  }, [sort, filter, search]);

  function RenderActivity(activity: PlatformActivity) {
    return (
      <TableRow>
        {/* Time  */}
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(activity.created).format("MMM DD, YYYY hh:mm:ss A")}
          </Typography>
        </TableCell>

        {/* Source  */}
        <TableCell>
          <Typography color="#94969C">{activity.source}</Typography>
        </TableCell>

        {/* Type */}

        <TableCell>
          <Chip
            label={activity.type}
            color={
              activity.type === "information"
                ? "primary"
                : activity.type === "warning"
                ? "warning"
                : "error"
            }
            sx={{ textTransform: "capitalize" }}
          />
        </TableCell>

        {/* Message  */}

        <TableCell align="justify">
          <Typography color="#94969C">{activity.message}</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack flex={1} sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Platform Activity
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            color="secondary"
            variant="outlined"
            onClick={() => load(pageNumber)}
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <ZTable
        sx={{ flex: 1 }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => load(pn)}
        prevPage={(pn) => load(pn)}
        titleBadge={totalActivities + " Records"}
        title="Activities"
        description="List of activities on the platform."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <Button
              onClick={() => setFilter({ ...filter, type: undefined })}
              color="inherit"
              variant={!filter?.type ? "contained" : undefined}
              sx={{ width: "200px" }}
            >
              Everything
            </Button>
            <Button
              onClick={() => setFilter({ ...filter, type: "information" })}
              color="inherit"
              variant={filter?.type === "information" ? "contained" : undefined}
              sx={{ width: "200px" }}
            >
              Informational
            </Button>
            <Button
              onClick={() => setFilter({ ...filter, type: "warning" })}
              color="warning"
              variant={filter?.type === "warning" ? "contained" : undefined}
              sx={{ width: "200px" }}
            >
              Warnings
            </Button>
            <Button
              onClick={() => setFilter({ ...filter, type: "error" })}
              color="error"
              variant={filter?.type === "error" ? "contained" : undefined}
              sx={{ width: "200px" }}
            >
              Errors
            </Button>
            <ZInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={<Ic_search width={24} height={24} />}
              placeholder="Search..."
            />
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* Created */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "created",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Created</Typography>
                {sort?.type === "created" && (
                  <>
                    {sort?.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort?.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>

            {/* Source  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "source",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Source</Typography>
                {sort?.type === "source" && (
                  <>
                    {sort?.value === "asc" && (
                      <IC_SORT_A width={24} height={24} />
                    )}
                    {sort?.value === "desc" && (
                      <IC_SORT_Z width={24} height={24} />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>

            {/* Type  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Kind</Typography>
              </Stack>
            </TableCell>

            {/* Message */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Message</Typography>
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderActivity(d))}</>}
      />
    </Stack>
  );
}
