import { GetRtmClient } from ".";
import { Chapter } from "../../types/Chapter";
import { FailedPayment } from "../../types/FailedPayment";
import { PaginatedResponse } from "../../types/IPaginatedResponse";
import { Invoice } from "../../types/Invoice";
import { Lesson } from "../../types/Lesson";
import { PlatformActivity } from "../../types/PlatformActivity";
import { PlatformConfig } from "../../types/PlatformConfig";
import { Role } from "../../types/Role";
import { User } from "../../types/User";

/**
 * Returns the specified platform config.
 * @param key
 * @returns
 */
export async function RtmGetConfigA<T>(key: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<PlatformConfig & { value: T }>(
    "RtmGetConfigA",
    key
  );
  return _result;
}

/**
 * Saves the specified platform config.
 * @param key
 * @param value
 * @returns
 */
export async function RtmSetConfigA(key: string, value: any) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmSetConfigA", { key, value });
  return _result;
}

// Chapters

/**
 * Returns array of all the courses in the system.
 * @returns
 */
export async function RtmGetChaptersA() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<(Chapter & { lessons: number })[]>(
    "RtmGetChaptersA"
  );
  return _result;
}

/**
 * Creates a new course.
 * @param course
 * @returns
 */
export async function RtmCreateChapterA(chapter: Partial<Chapter>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Chapter>("RtmCreateChapterA", chapter);
  return _result;
}

/**
 * Returns the details of a chapter.
 * @returns
 */
export async function RtmGetChapterA(id: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Chapter>("RtmGetChapterA", id);
  return _result;
}

/**
 * Updates the specified properties of the course.
 * @param course
 * @returns
 */
export async function RtmUpdateChapterA(chapter: Partial<Chapter>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmUpdateChapterA", chapter);
  return _result;
}

// Lessons

/**
 * Returns array of all the lessons of a course.
 */
export async function RtmGetLessonsA(chapter: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Lesson[]>("RtmGetLessonsA", chapter);
  return _result;
}

/**
 * Creates a new lesson for a specified course.
 */
export async function RtmCreateLessonA(chapter: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Lesson>("RtmCreateLessonA", chapter);
  return _result;
}

/**
 * Returns the details of a lesson of a specified course.
 */
export async function RtmGetLessonA(id: string, chapter: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<Lesson>("RtmGetLessonA", { id, chapter });
  return _result;
}

/**
 * Updates the specified properties of a lesson.
 */
export async function RtmUpdateLessonA(lesson: Partial<Lesson>) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmUpdateLessonA", lesson);
  return _result;
}

export async function RtmDeleteLessonA(lesson: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmDeleteLessonA", lesson);
  return _result;
}

// User Management

/**
 * Lists all the users on the platform, sorted by registeration time in descending. Requires administrator role.
 */
export async function RtmGetUsersA(props: {
  page: number;
  filter?: {
    type: "role" | "subscription";
    value: any;
  };
  sort?: {
    type:
      | "registered"
      | "updated"
      | "firstName"
      | "lastName"
      | "lastSignIn"
      | "subscription"
      | "role";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _users = await _rtm.CallWait<PaginatedResponse<User[]>>(
    "RtmGetUsersA",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _users;
}

/**
 * Lists all the users on the platform, sorted by registeration time in descending. Requires administrator role.
 */
export async function RtmGetAdminsA(props: {
  page: number;
  sort?: {
    type:
      | "registered"
      | "updated"
      | "firstName"
      | "lastName"
      | "lastSignIn"
      | "subscription"
      | "role";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _users = await _rtm.CallWait<PaginatedResponse<User[]>>(
    "RtmGetAdminsA",
    {
      page: props.page,
      sort: props.sort,
      search: props.search,
    }
  );
  return _users;
}

/**
 * Creates a new user with specified details.
 * @returns
 */
export async function RtmAddUserA(user: Partial<User>) {
  const _rtm = await GetRtmClient();
  const _user = await _rtm.CallWait<{
    user: User;
    password: string;
  }>("RtmAddUserA", user);
  return _user;
}

export async function RtmDisableUserA(uid: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmDisableUserA", uid);
  return _result;
}

export async function RtmEnableUserA(uid: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmEnableUserA", uid);
  return _result;
}

/**
 * Immediately cancels a user's subscription.
 * @param uid
 * @returns
 */
export async function RtmCancelSubscriptionA(uid: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmCancelSubscriptionA", uid);
  return _result;
}

/**
 * Immediately cancels a user's subscription.
 * @param uid
 * @returns
 */
export async function RtmUpdateRoleA(uid: string, role: Role) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmUpdateRoleA", { uid, role });
  return _result;
}

/**
 * Configures the user impersonation for an admin.
 */
export async function RtmImpersonateA(uid: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmImpersonateA", uid);
  return _result;
}

/**
 * Returns all the affiliates.
 */
export async function RtmGetAffiliatesA() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<(User & { referrals: number })[]>(
    "RtmGetAffiliatesA"
  );
  return _result;
}

/**
 * Returns all the users referrerd by an affiliate.
 * @returns
 */
export async function RtmGetReferralsByUserA(uid: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<User[]>("RtmGetReferralsByUserA", uid);
  return _result;
}

// Invoices

/**
 * Lists all the invoices on the platform, sorted by created time in descending. Requires administrator role.
 */
export async function RtmGetInvoicesA(props: {
  page: number;
  filter?: {
    type: "status";
    value: any;
  };
  sort?: {
    type:
      | "updated"
      | "data.created"
      | "customerName"
      | "data.total"
      | "id"
      | "data.status";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _invoices = await _rtm.CallWait<PaginatedResponse<Invoice[]>>(
    "RtmGetInvoicesA",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _invoices;
}

/**
 * Updates the discordjs bot token and re-initializes the bot. If empty, stops the bot.
 * @returns
 */
export async function RtmUpdateBotTokenA(token?: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<boolean>("RtmUpdateBotTokenA", token);
  return _result;
}

/**
 * Returns the list of discord servers joined by the bot.
 * @returns
 */
export async function RtmGetDiscordServersA() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<any[]>("RtmGetDiscordServersA");
  return _result;
}

/**
 * Returns the list of discord servers joined by the bot.
 * @returns
 */
export async function RtmGetDiscordRolesA(guildId: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<any[]>("RtmGetDiscordRolesA", guildId);
  return _result;
}

/**
 * Returns the list of discord servers joined by the bot.
 * @returns
 */
export async function RtmGetDiscordChannelsA(guildId: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<any[]>("RtmGetDiscordChannelsA", guildId);
  return _result;
}

export async function RtmGetActivitiesA(props: {
  page: number;
  filter?: {
    type?: string;
    source?: string;
  };
  sort?: {
    type: "created" | "source";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  const _rtm = await GetRtmClient();
  const _activities = await _rtm.CallWait<
    PaginatedResponse<PlatformActivity[]>
  >("RtmGetActivitiesA", {
    page: props.page,
    filter: props.filter,
    sort: props.sort,
    search: props.search,
  });
  return _activities;
}

export async function RtmGetSalesAnalyticsA() {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<{
    totalSales: number;
    dailySales: { [key: number]: number };
    weeklySales: number;
    monthlySales: number;
    activeSubscriptions: number;
    failedPayments: FailedPayment[];
    openInvoices: { user: User; invoice: Invoice }[];
  }>("RtmGetSalesAnalyticsA");
  return _result;
}

export async function RtmGetUserProgressA(uid: string) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<{
    completed: number;
    currentLesson: Lesson;
    currentChapter: Chapter;
    watchTime: number;
    totalLessons: number;
    percentage: number;
    totalQuizzes: number;
    quizzesCompleted: number;
  }>("RtmGetUserProgressA", uid);
  return _result;
}

export async function RtmGetProgressReportsA(page: number) {
  const _rtm = await GetRtmClient();
  const _result = await _rtm.CallWait<
    PaginatedResponse<
      {
        uid: string;
        user: User;
        totalWatchTime: number;
        totalQuizzesPassed: number;
        totalLessonsWatched: number;
        totalLessons: number;
        totalQuizzes: number;
        score: number;
      }[]
    >
  >("RtmGetProgressReportsA", { page });
  return _result;
}
