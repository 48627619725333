import React from 'react';

function IC_NOTEBOOK(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M1.625 6a1 1 0 0 1 1-1h2.5a1 1 0 0 1 0 2h-2.5a1 1 0 0 1-1-1zm0 6a1 1 0 0 1 1-1h2.5a1 1 0 1 1 0 2h-2.5a1 1 0 0 1-1-1zm0 6a1 1 0 0 1 1-1h2.5a1 1 0 1 1 0 2h-2.5a1 1 0 0 1-1-1z" fill={fill} fillRule="evenodd"/>
		<path d="M13.681 1.25H12.07c-1.838 0-3.294 0-4.433.153-1.172.158-2.121.49-2.87 1.238-.748.749-1.08 1.698-1.238 2.87-.153 1.14-.153 2.595-.153 4.433v4.112c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153h1.612c1.838 0 3.294 0 4.433-.153 1.172-.158 2.121-.49 2.87-1.238.748-.749 1.08-1.698 1.238-2.87.153-1.14.153-2.595.153-4.433V9.944c0-1.838 0-3.294-.153-4.433-.158-1.172-.49-2.121-1.238-2.87-.749-.748-1.698-1.08-2.87-1.238-1.14-.153-2.595-.153-4.433-.153zM9.125 8.5a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2h-8zm0 5a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_NOTEBOOK;