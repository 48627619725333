import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  IC_MT5,
  IC_ORIGINFX,
  IC_PACIFIC,
  IC_TRADINGVIEW,
  IC_TWINSAILS,
  IMG_ARROW,
  LOGO,
  TRUSTPILOT,
} from "../../../assets";
import { motion } from "framer-motion";

export default function StaticHero() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      alignItems={"center"}
      spacing={"100px"}
      sx={{ position: "relative", zIndex: 0 }}
    >
      <Stack
        // We inverse the direction on mobiles, so the logo stays above the content.
        direction={!isMobile ? "row" : "column-reverse"}
        position={"relative"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          maxWidth: "1320px",
          width: "100%",
          zIndex: 5,
        }}
        spacing={"45px"}
      >
        {/* Column Left: Text, Description, and Rating  */}
        <motion.filter
          initial={{ filter: "blur(10px)" }}
          whileInView={{ filter: "blur(0px)" }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <Stack alignItems={!isMobile ? "flex-start" : "center"}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontFamily: "Space Grotesk",
                wordWrap: "normal",
                fontSize: "78px",
                textAlign: "left",
                px: "0px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "38px",
                  textAlign: "center",
                  px: "24px",
                },
              }}
            >
              Learn to trade with{" "}
              <span style={{ color: theme.palette.secondary.main }}>Alpha</span>{" "}
              trading university.
            </Typography>

            {/* Rating  */}
            <Stack
              direction={"row"}
              spacing={"16px"}
              sx={{ pt: !isMobile ? "45px" : "22px" }}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  fontSize: !isMobile ? "20px" : "16px",
                  fontWeight: "500",
                  fontFamily: "Helvetica-Medium",
                }}
              >
                Excellent
              </Typography>
              <Typography
                sx={{
                  fontSize: !isMobile ? "20px" : "16px",
                  fontWeight: "300",
                  fontFamily: "Helvetica",
                }}
              >
                4.3 out of 5.0
              </Typography>
              <img src={TRUSTPILOT} />
            </Stack>

            {/* Text Description and CTA */}
            <Stack
              alignItems={!isMobile ? "flex-start" : "center"}
              sx={{ pt: !isMobile ? "130px" : "25px" }}
              spacing={"35px"}
            >
              <Typography
                sx={{
                  fontSize: !isMobile ? 18 : 16,
                  textAlign: !isMobile ? "start" : "center",
                  fontWeight: "400",
                  fontFamily: "Helvetica",
                  maxWidth: !isMobile ? "460px" : "90%",
                }}
              >
                Take advantage of the ATU educators and our platform to enhance
                your trading skills if you are brand new or a seasoned trader.
              </Typography>
              <Stack direction={"row"} spacing={"30px"}>
                <Button variant="contained" href="/join">
                  Join ATU
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  href="#learn-more"
                >
                  Learn more
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </motion.filter>

        {/* Column Right, For Logo */}
        <Stack>
          <motion.img
            src={LOGO}
            style={{
              height: !isMobile ? "466px" : "202px",
              objectFit: "contain",
            }}
            initial={{ translateY: 200 }}
            whileInView={{ translateY: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          />
        </Stack>

        {/* Glow effects  */}
        <div
          style={{
            background: "#3CD7CD",
            height: "500px",
            width: "500px",
            position: "absolute",
            filter: "blur(350px)",
            left: -250,
            zIndex: -1,
            pointerEvents: "none",
            opacity: !isMobile ? 0.7 : 0,
          }}
        />
        <div
          style={{
            background: "#3CD7CD",
            height: "500px",
            width: "500px",
            position: "absolute",
            filter: "blur(450px)",
            right: -400,
            zIndex: -1,
            pointerEvents: "none",
            opacity: !isMobile ? 0.7 : 0.4,
          }}
        />
        <motion.img
          src={IMG_ARROW}
          style={{ position: "absolute", rotate: "90deg" }}
          initial={{ left: -100, top: 0, opacity: 0 }}
          animate={{ left: 1000, top: 1000, opacity: [0, 1, 0] }}
          transition={{ duration: 30, repeat: Infinity }}
        />
      </Stack>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        sx={{
          maxWidth: "1320px",
          width: "100%",
          zIndex: 10,
        }}
        columnGap={!isMobile ? "60px" : "10px"}
        rowGap={!isMobile ? "60px" : "10px"}
      >
        {/* We iterate all logos and add them  */}
        {[IC_TRADINGVIEW, IC_ORIGINFX, IC_TWINSAILS, IC_MT5, IC_PACIFIC].map(
          (ic, i) => (
            <motion.div
              initial={{ opacity: 0, scale: 1.2 }}
              whileInView={{ scale: 1, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.3, delay: 0.1 * i }}
            >
              <Stack
                sx={{
                  width: !isMobile ? "200px" : "105px",
                  height: !isMobile ? "95px" : "50px",
                  background: "#0E0B34",
                  borderRadius: !isMobile ? "12px" : "6px",
                }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={ic}
                  style={{
                    objectFit: "contain",
                    maxWidth: !isMobile ? "200px" : "90px",
                    height: !isMobile ? "" : "20px",
                  }}
                />
              </Stack>
            </motion.div>
          )
        )}
      </Stack>
    </Stack>
  );
}
