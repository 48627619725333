import {
  ArrowOutward,
  Close,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import {
  Avatar,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ReactPlayer from "react-player";
import { WeeklyReport } from "../types/WeeklyReport";
import { useCreateBlockNote } from "@blocknote/react";
import { useState } from "react";
import { useModal } from "mui-modal-provider";
import ViewAttachmentDialog from "./AttachmentDialog";
import { DailyEvent } from "../types/DailyEvent";
import IC_EVENT from "../assets/icons/ui/IC_EVENT";
import { GetValueForValues } from "../core/helper";
import moment from "moment";
import { BlockNoteView, darkDefaultTheme } from "@blocknote/mantine";
import IC_FILE from "../assets/icons/ui/IC_FILE";

export default function ViewWeeklyReportDialog(props: {
  report: WeeklyReport;
  onClose?: () => void;
}) {
  const report = props.report;

  const post = props.report;

  const editor = useCreateBlockNote({
    initialContent: JSON.parse(post.content),
  });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { showModal } = useModal();

  function openAttachmentDialog(attach: {
    type: string;
    url: string;
    name: string;
  }) {
    // If the attachment is a video, we show video in a fullscreen dialog.
    // If its an image, we show image in a full screen dialog.
    // Otherwise, we open the file in new tab.
    if (attach.type.startsWith("image/")) {
      // Show image dialog
      const modal = showModal(ViewAttachmentDialog, {
        attachment: attach,
        onClose() {
          modal.destroy();
        },
      });
    } else if (attach.type.startsWith("video/")) {
      // show video dialog
      const modal = showModal(ViewAttachmentDialog, {
        attachment: attach,
        onClose() {
          modal.destroy();
        },
      });
    } else {
      // open link
      window.open(attach.url, "_blank");
    }
  }

  function RenderEventInfo(event: DailyEvent) {
    return (
      <Stack
        spacing={"8px"}
        sx={{
          background: "#333",
          p: "24px",
          borderRadius: "8px",
        }}
      >
        <Stack direction={"row"} spacing={"8px"}>
          <SvgIcon>
            <IC_EVENT />
          </SvgIcon>
          <Typography
            component={"a"}
            href={event.url}
            target="_blank"
            sx={{
              textDecoration: "none",
              color: theme.palette.text.primary,
              ":hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            fontSize={20}
            fontFamily={"Space Grotesk"}
          >
            {event.title} <ArrowOutward sx={{ fontSize: "20px" }} />
          </Typography>
        </Stack>
        <Stack spacing={"8px"} alignItems={"flex-start"}>
          <Stack direction={"row"} spacing={"32px"}>
            {event.impact && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Impact</Typography>
                <Typography
                  fontSize={18}
                  fontFamily={"Space Grotesk"}
                  color={GetValueForValues(
                    [
                      { option: "Low", value: undefined },
                      {
                        option: "Medium",
                        value: theme.palette.info.main,
                      },
                      { option: "High", value: theme.palette.error.main },
                    ],
                    event.impact
                  )}
                >
                  {event.impact}
                </Typography>
              </Stack>
            )}
            {event.previous && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Previous</Typography>
                <Typography fontSize={18} fontFamily={"Space Grotesk"}>
                  {event.previous}
                </Typography>
              </Stack>
            )}
            {event.forecast && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Forecast</Typography>
                <Typography fontSize={18} fontFamily={"Space Grotesk"}>
                  {event.forecast}
                </Typography>
              </Stack>
            )}
            {event.country && (
              <Stack>
                <Typography sx={{ opacity: 0.8 }}>Currency</Typography>
                <Typography fontSize={18} fontFamily={"Space Grotesk"}>
                  {event.country}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Dialog
      hideBackdrop
      open
      fullScreen
      onClose={props.onClose}
      PaperProps={{
        sx: {
          border: "1px solid #FFF3",
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
      sx={{
        margin: "24px",
        borderRadius: "8px",
        boxShadow: "none",
        [theme.breakpoints.down("md")]: {
          margin: 0,
        },
      }}
    >
      <DialogTitle>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography sx={{ opacity: 0.8 }}>
              {moment.unix(post.date).format("MMM DD, YYYY hh:mm A Z")}
            </Typography>
            <Typography fontSize={20} fontFamily={"Space Grotesk"}>
              {report.title}
            </Typography>
          </Stack>
          <IconButton
            onClick={props.onClose}
            sx={{ height: "48px", width: "48px" }}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          m: "12px",
          [theme.breakpoints.down("md")]: {
            m: 0,
            overflowX: "hidden",
          },
        }}
      >
        <Stack sx={{ height: "100%", width: "100%" }}>
          <BlockNoteView
            editor={editor}
            editable={false}
            theme={darkDefaultTheme}
          />

          {(post.short!.length > 0 || post.long!.length > 0) && (
            <Stack spacing={"8px"} sx={{ mt: "48px" }}>
              <Typography>Recommended Pairs</Typography>
              <Stack
                direction={"row"}
                flexWrap={"wrap"}
                rowGap={"8px"}
                columnGap={"8px"}
              >
                <Stack flex={1} alignItems={"flex-start"} spacing={"8px"}>
                  <Typography>
                    Long <TrendingUp />
                  </Typography>
                  <Stack
                    direction={"row"}
                    rowGap={"8px"}
                    columnGap={"8px"}
                    flexWrap={"wrap"}
                  >
                    {post.long?.map((l) => (
                      <Chip color="success" label={l} />
                    ))}
                  </Stack>
                </Stack>

                <Stack flex={1} alignItems={"flex-start"} spacing={"8px"}>
                  <Typography>
                    Shorts <TrendingDown />
                  </Typography>
                  <Stack
                    direction={"row"}
                    rowGap={"8px"}
                    columnGap={"8px"}
                    flexWrap={"wrap"}
                  >
                    {post.short?.map((s) => (
                      <Chip color="error" label={s} />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}

          <Stack spacing={"8px"} sx={{ mt: "48px" }}>
            <Typography>Related Events</Typography>
            {post.events?.map((ev) => RenderEventInfo(ev as DailyEvent))}
          </Stack>

          {post.attachments?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px" }}>
              <Typography>Attachments</Typography>
              <Stack
                direction={"row"}
                rowGap={"8px"}
                columnGap={"8px"}
                flexWrap={"wrap"}
              >
                {post.attachments?.map((t) => (
                  <Stack
                    sx={{
                      width: "200px",
                      height: "180px",
                      background: "#3336",
                      borderRadius: "8px",
                      py: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => openAttachmentDialog(t)}
                    alignItems={"center"}
                  >
                    {t.type.startsWith("image/") && (
                      <img
                        src={t.url}
                        alt={t.name}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    {t.type.startsWith("video/") && (
                      <video
                        src={t.url + "#t=0.1"}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    {!t.type.startsWith("video/") &&
                      !t.type.startsWith("image/") && (
                        <>
                          <SvgIcon
                            sx={{ flex: 1, height: "48px", width: "48px" }}
                          >
                            <IC_FILE />
                          </SvgIcon>
                          <Typography textAlign={"center"} fontSize={12}>
                            {t.name}
                          </Typography>
                          <Typography fontSize={12}>
                            {Math.ceil(t.size / 1024)} KB
                          </Typography>
                        </>
                      )}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {post.tags?.length! > 0 && (
            <Stack spacing={"8px"} sx={{ mt: "14px" }}>
              <Typography>Tags</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                {post.tags?.map((t) => (
                  <Chip label={t} />
                ))}
              </Stack>
            </Stack>
          )}
          {typeof post.author !== "string" && (
            <Stack spacing={"8px"} sx={{ mt: "18px" }}>
              <Typography>Authored By</Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  alt={post.author.name}
                  src={post.author.image}
                  sx={{ background: "#444" }}
                >
                  {post.author.name[0]}
                </Avatar>
                <Typography>{post.author.name}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
