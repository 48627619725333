import React from 'react';

function Ic_bookEdit(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M6.221 3.017l-.06-.012c-.71-.133-1.338-.251-1.869-.255-.596-.004-1.136.134-1.628.55-.508.43-.726.984-.824 1.605-.09.573-.09 1.292-.09 2.133v6.51c0 .778 0 1.424.057 1.937.058.53.187 1.03.529 1.449.355.434.871.658 1.42.819.547.16 1.265.295 2.147.461l.04.008c1.563.294 2.766.759 3.62 1.214l.018.01c.438.233.792.421 1.062.55.138.066.273.124.4.168.118.04.28.086.457.086a.75.75 0 0 0 .75-.75V7.2c0-.054 0-.08.002-.1a.233.233 0 0 1 .053-.134 2 2 0 0 1 .105-.118c.413-.447 1.913-1.487 4.642-1.993.762-.141 1.208-.22 1.543-.223.27-.001.368.048.465.129.076.063.16.16.215.51.063.393.066.935.066 1.805V9.81c0 .52.427.941.954.941a.948.948 0 0 0 .955-.941V7.007c0-.78 0-1.472-.09-2.03-.098-.618-.324-1.2-.867-1.653-.523-.436-1.098-.578-1.71-.574-.528.003-1.147.118-1.812.242l-.072.013c-2.214.41-3.839 1.16-4.849 1.86-.193.132-.289.199-.382.2-.092 0-.189-.065-.382-.195-1.03-.694-2.657-1.438-4.865-1.853z" fill={fill}/>
		<path d="M18.578 13.054c.334-.334.5-.5.674-.6a1.521 1.521 0 0 1 1.52 0c.174.1.34.266.674.6.333.333.5.5.6.673.272.47.272 1.05 0 1.521-.1.174-.267.34-.6.674l-4.308 4.307c-.66.661-1.658.688-2.54.877-.69.147-1.035.221-1.23.026-.195-.195-.121-.54.026-1.23.189-.882.216-1.88.877-2.54l4.307-4.308z" fill={fill}/>
	</g>
</svg>
	);
};

export default Ic_bookEdit;