import React from 'react';

function IC_REMOVE(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M19.884 4.116a1.25 1.25 0 0 1 0 1.768l-14 14a1.25 1.25 0 0 1-1.768-1.768l14-14a1.25 1.25 0 0 1 1.768 0z" fill={fill} fillRule="evenodd"/>
		<path d="M4.116 4.116a1.25 1.25 0 0 1 1.768 0l14 14a1.25 1.25 0 1 1-1.768 1.768l-14-14a1.25 1.25 0 0 1 0-1.768z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_REMOVE;