import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useModal } from "mui-modal-provider";
import ZInput from "../../../components/ZInput";
import {
  RtmGetConfigA,
  RtmGetDiscordChannelsA,
  RtmGetDiscordRolesA,
  RtmGetDiscordServersA,
  RtmSetConfigA,
  RtmUpdateBotTokenA,
} from "../../../core/rtm/admin";
import { DiscordConfig } from "../../../types/DiscordConfig";
import ZAutoComplete, {
  AutocompleteOption,
} from "../../../components/ZAutoComplete";

export default function SectionDiscordConfigurationA() {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const { showModal } = useModal();
  const [busy, setBusy] = useState(false);

  const [config, setConfig] = useState<DiscordConfig>();
  const [token, setToken] = useState("");

  const [guilds, setGuilds] = useState<AutocompleteOption[]>([]);
  const [roles, setRoles] = useState<AutocompleteOption[]>([]);
  const [channels, setChannels] = useState<AutocompleteOption[]>([]);
  const [role, setRole] = useState<AutocompleteOption>();
  const [weeklyReportsChannel, setWeeklyReportsChannel] =
    useState<AutocompleteOption>();
  const [dailyReportsChannel, setDailyReportsChannel] =
    useState<AutocompleteOption>();
  const [dailyEventsChannel, setDailyEventsChannel] =
    useState<AutocompleteOption>();
  const [guild, setGuild] = useState<AutocompleteOption>();

  // Comboboxes need manual refresh..
  const [refresh, setRefresh] = useState(0);

  const [saveState, setSaveState] = useState<"saving" | "saved" | "error">();

  async function load() {
    try {
      setBusy(true);
      const _cfg = await RtmGetConfigA<DiscordConfig>("discord");
      setConfig(_cfg.value || "");
      setToken(_cfg.value?.token || "");

      if (_cfg.value as DiscordConfig) {
        // Get guilds
        const _guilds = await RtmGetDiscordServersA();
        const _guildOps: AutocompleteOption[] = [];
        for (let _g of _guilds) {
          _guildOps.push({
            id: _g.id,
            label: _g.name,
            icon: `https://cdn.discordapp.com/icons/${_g.id}/${_g.icon}.png`,
          });
        }
        setGuilds([..._guildOps]);

        if (_cfg.value.guildId) {
          // Get roles
          const _roles = await RtmGetDiscordRolesA(_cfg.value.guildId);
          const _roleOps: AutocompleteOption[] = [];
          for (let _r of _roles) {
            _roleOps.push({
              id: _r.id,
              label: _r.name,
            });
          }
          setRoles([..._roleOps]);

          // Get channels
          const _channels = await RtmGetDiscordChannelsA(_cfg.value.guildId);
          const _channelOps: AutocompleteOption[] = [];
          for (let _c of _channels.filter((c) => c.type === 0)) {
            _channelOps.push({
              id: _c.id,
              label: "#" + _c.name,
            });
          }
          setChannels([..._channelOps]);
        }
      }

      setRefresh(new Date().getTime());
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data. ", err);

      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveToken() {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmUpdateBotTokenA(token);
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveGuild(selectedGuild: AutocompleteOption) {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmSetConfigA("discord", { ...config, guildId: selectedGuild!.id });
      setSaveState("saved");
      setGuild(selectedGuild);
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveRole(selectedRole: AutocompleteOption) {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmSetConfigA("discord", {
        ...config,
        vipRoleId: selectedRole?.id || "",
      });
      setRole(selectedRole);
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveWeeklyReportsChannel(channel: AutocompleteOption) {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmSetConfigA("discord", {
        ...config,
        weeklyReportChannelId: channel?.id || "",
      });
      setWeeklyReportsChannel(channel);
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveDailyReportsChannel(channel: AutocompleteOption) {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmSetConfigA("discord", {
        ...config,
        dailyReportChannelId: channel?.id || "",
      });
      setDailyReportsChannel(channel);
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  async function saveDailyEventChannel(channel: AutocompleteOption) {
    try {
      setBusy(true);
      setSaveState("saving");
      await RtmSetConfigA("discord", {
        ...config,
        dailyEventChannelId: channel?.id || "",
      });
      setDailyEventsChannel(channel);
      setSaveState("saved");
      load();
    } catch (err: any) {
      console.error("Error saving data. ", err);
      setSaveState("error");
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (config?.vipRoleId) {
      setRole(roles.find((r) => r.id === config.vipRoleId));
      setRefresh(new Date().getTime());
    }
  }, [roles, role]);

  useEffect(() => {
    if (config?.guildId) {
      setGuild(guilds.find((g) => g.id === config.guildId));
      setRefresh(new Date().getTime());
    }
  }, [guilds, guild]);

  useEffect(() => {
    if (config?.weeklyReportChannelId) {
      setWeeklyReportsChannel(
        channels.find((g) => g.id === config.weeklyReportChannelId)
      );
      setRefresh(new Date().getTime());
    }
  }, [channels, weeklyReportsChannel]);

  useEffect(() => {
    if (config?.weeklyReportChannelId) {
      setDailyReportsChannel(
        channels.find((g) => g.id === config.dailyReportChannelId)
      );
      setRefresh(new Date().getTime());
    }
  }, [channels, dailyReportsChannel]);

  useEffect(() => {
    if (config?.dailyEventChannelId) {
      setDailyEventsChannel(
        channels.find((g) => g.id === config.dailyEventChannelId)
      );
      setRefresh(new Date().getTime());
    }
  }, [channels, dailyEventsChannel]);

  return (
    <Stack sx={{ p: "24px", overflow: "hidden", flex: 1 }} gap={"8px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ overflow: "hidden" }}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Discord Configuration
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          {saveState === "saving" && (
            <>
              <CircularProgress size={"24px"} />
              <Typography>Saving changes...</Typography>
            </>
          )}
          {saveState === "saved" && (
            <>
              <Typography>Changes are saved.</Typography>
            </>
          )}

          {saveState === "error" && (
            <>
              <Typography color={"error"}>Error saving changes!</Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack
        flex={1}
        sx={{ overflow: "hidden", flex: 1, alignItems: "flex-start" }}
        spacing="8px"
      >
        <Stack sx={{ width: "450px" }}>
          <ZInput
            label="Bot Token"
            placeholder="Paste your bot token."
            helperText="You can obtain your bot token from Discord Developer Portal."
            text={token}
            onUpdate={(t) => setToken(t)}
          />
        </Stack>
        <Button
          variant="contained"
          onClick={saveToken}
          disabled={busy || token === config?.token}
        >
          Update
        </Button>

        {/* Guilds Selection  */}
        <Stack sx={{ width: "450px" }}>
          <ZAutoComplete
            key={refresh}
            label="Server"
            important
            disabled={!Boolean(config?.profile)}
            options={guilds}
            value={guild}
            onUpdate={(o) => saveGuild(o as any)}
            helperText="Select the server to operate the bot in."
          />
        </Stack>

        <Stack sx={{ width: "450px" }}>
          <ZAutoComplete
            key={refresh}
            label="VIP Role"
            disabled={!Boolean(config?.guildId)}
            options={roles}
            value={role}
            onUpdate={(o) => saveRole(o as any)}
            helperText="Select the role for subscribers."
          />
        </Stack>

        <Stack sx={{ width: "450px" }}>
          <ZAutoComplete
            key={refresh}
            label="Daily Reports Channel"
            disabled={!Boolean(config?.guildId)}
            options={channels}
            value={dailyReportsChannel}
            onUpdate={(o) => saveDailyReportsChannel(o as any)}
            helperText="Select the channel for sending daily reports."
          />
        </Stack>

        <Stack sx={{ width: "450px" }}>
          <ZAutoComplete
            key={refresh}
            label="Weekly Reports Channel"
            disabled={!Boolean(config?.guildId)}
            options={channels}
            value={weeklyReportsChannel}
            onUpdate={(o) => saveWeeklyReportsChannel(o as any)}
            helperText="Select the channel for sending weekly reports."
          />
        </Stack>

        <Stack sx={{ width: "450px" }}>
          <ZAutoComplete
            key={refresh}
            label="High-Impact News Channel"
            disabled={!Boolean(config?.guildId)}
            options={channels}
            value={dailyEventsChannel}
            onUpdate={(o) => saveDailyEventChannel(o as any)}
            helperText="Select the channel for sending daily high impact events."
          />
        </Stack>

        <Button disabled={busy} onClick={load}>
          Refresh
        </Button>
      </Stack>
    </Stack>
  );
}
