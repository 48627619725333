import React from 'react';

function IC_DISCORD(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M8.777 2.254a.75.75 0 0 1 .781.479l.46 1.206c.082.215.122.323.21.376.088.053.206.04.443.013a11.561 11.561 0 0 1 2.658 0c.237.027.355.04.443-.013.088-.053.129-.16.21-.376l.46-1.206a.75.75 0 0 1 .782-.479c1.727.187 3.465.576 5.2 1.825a.75.75 0 0 1 .27.366c1.104 3.233 1.708 6.054 1.941 8.322.23 2.24.11 4.023-.319 5.129a.712.712 0 0 1-.024.056c-.39.806-.979 1.724-1.71 2.454-.717.716-1.669 1.344-2.774 1.344-.278 0-.517-.125-.646-.2a3.588 3.588 0 0 1-.479-.342 8.346 8.346 0 0 1-.983-.988 8.007 8.007 0 0 1-.871-1.226c-.084-.148-.125-.223-.122-.307a.318.318 0 0 1 .086-.2c.06-.06.152-.083.337-.129.748-.185 1.75-.459 2.137-.657a.75.75 0 0 0-.534-1.402c-3.328 1.268-6.138 1.268-9.466 0a.75.75 0 0 0-.534 1.402c.728.277 1.437.5 2.135.667.178.043.267.064.325.117.058.055.096.141.096.22 0 .08-.04.15-.118.29-.237.421-.55.848-.871 1.225a8.347 8.347 0 0 1-.983.988c-.156.13-.32.25-.478.342-.13.075-.369.2-.647.2-1.105 0-2.057-.628-2.775-1.344-.73-.73-1.32-1.648-1.71-2.454a.747.747 0 0 1-.023-.056c-.428-1.106-.55-2.89-.32-5.129.234-2.268.838-5.09 1.941-8.322a.75.75 0 0 1 .272-.366C5.31 2.83 7.05 2.44 8.777 2.254zM5.5 10.5c0-1.102.89-2 1.992-2h.016c1.102 0 1.992.898 1.992 2 0 1.102-.89 2-1.992 2h-.016a1.996 1.996 0 0 1-1.992-2zm9 0c0-1.102.89-2 1.992-2h.015c1.103 0 1.993.898 1.993 2 0 1.102-.89 2-1.992 2h-.015a1.996 1.996 0 0 1-1.993-2z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default IC_DISCORD;