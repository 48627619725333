import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useEffect, useState } from "react";
import Ic_refresh from "../../../assets/icons/ui/ic_refresh";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import ZTable from "../../../components/ZTable";
import ZSelect from "../../../components/ZSelect";
import ZInput from "../../../components/ZInput";
import Ic_search from "../../../assets/icons/ui/ic_search";
import IC_SORT_A from "../../../assets/icons/ui/IC_SORT_A";
import IC_SORT_Z from "../../../assets/icons/ui/IC_SORT_Z";
import IC_FILTER from "../../../assets/icons/ui/IC_FILTER";
import IC_FILTER_EDIT from "../../../assets/icons/ui/IC_FILTER_EDIT";
import IC_NOTE_ADD from "../../../assets/icons/ui/IC_NOTE_ADD";
import { RtmGetEventsA } from "../../../core/rtm/moderator";
import { Close } from "@mui/icons-material";
import { DailyEvent } from "../../../types/DailyEvent";
import EditEventDialog from "../../../dialogs/AddEvent";
import {
  CalendarIcon,
  DateCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export default function SectionEventsA() {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const theme = useTheme();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [data, setData] = useState<DailyEvent[]>([]);
  const [busy, setBusy] = useState(false);
  const [sort, setSort] = useState<{
    type: "id" | "title" | "date" | "created" | "status";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    date: "today" | "week" | "month" | number;
  }>({ date: "week" });

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const showCalenderPopup = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  async function load(page: number) {
    try {
      //Load data
      setBusy(true);
      const _posts = await RtmGetEventsA({
        page: page,
        sort: sort
          ? sort
          : {
              type: "date",
              value: "desc",
            },
        filter: { ...filter, now: new Date().toISOString() },
        search: search,
      });
      if (_posts) {
        setTotalPages(_posts.pageCount);
        setPageNumber(_posts.page);
        setTotalPosts(_posts.size);
        setData(_posts.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function showEditDialog(event: DailyEvent) {
    const modal = showModal(EditEventDialog, {
      onClose(result) {
        modal.destroy();
        if (result) {
          load(pageNumber);
        }
      },
      event: event,
    });
  }

  useEffect(() => {
    // Load page 0
    load(0);
  }, []);

  useEffect(() => {
    load(pageNumber);
  }, [sort, filter, search]);

  function RenderEvent(
    event: DailyEvent & {
      author?: { name: string; id: string; image: string; email: string };
    }
  ) {
    return (
      <TableRow
        onClick={() => showEditDialog(event)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        {/* Event Date/Time */}
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(event.date).format("ddd, MMM DD, YYYY hh:mm A")}
          </Typography>
        </TableCell>

        {/* Created at */}
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(event.created!).format("MMM DD, YYYY hh:mm A")}
          </Typography>
        </TableCell>

        {/* Author */}
        <TableCell align="left">
          {event.author && (
            <Stack direction={"row"} spacing="12px" alignItems={"center"}>
              <Avatar
                src={event.author.image || ""}
                alt={event.author.name}
                sx={{ color: "white" }}
              />
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  {event.author.name}
                </Typography>
                <Typography fontSize={14} fontWeight={400} color="#94969C">
                  {event.author.email}
                </Typography>
              </Stack>
            </Stack>
          )}
          {!event.author && <Typography>Not authored yet.</Typography>}
        </TableCell>

        {/* Title & Comments */}
        <TableCell>
          <Stack>
            <Typography fontSize={14} fontWeight={500}>
              {event.title}
            </Typography>
            <Typography fontSize={12} fontWeight={400} color="#94969C">
              {event.comments?.substring(0, 40)}
            </Typography>
          </Stack>
        </TableCell>

        {/* Forecast */}
        <TableCell>
          <Typography color="#94969C">{event.forecast}</Typography>
        </TableCell>

        {/* Previous */}
        <TableCell>
          <Typography color="#94969C">{event.previous}</Typography>
        </TableCell>

        {/* Impact  */}
        <TableCell>
          <Typography color="#94969C">{event.impact}</Typography>
        </TableCell>

        {/* Country  */}
        <TableCell>
          <Typography color="#94969C">{event.country}</Typography>
        </TableCell>

        {/* Status  */}
        <TableCell>
          <Chip
            label={event.status}
            variant="filled"
            color={event.status === "published" ? "secondary" : "default"}
            size="small"
          />
        </TableCell>

        {/* Tags  */}
        <TableCell>
          {event.tags?.map((t) => (
            <Chip label={t} variant="filled" size="small" sx={{ mx: "4px" }} />
          ))}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack flex={1} sx={{ p: "24px", overflow: "hidden" }} gap={"8px"}>
      {/* Top section: title and add button  */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontFamily: "Space Grotesk", fontWeight: 600, fontSize: 28 }}
        >
          Daily Events
        </Typography>

        <Stack
          direction={"row"}
          sx={{ mx: "24px", my: "12px" }}
          spacing={"8px"}
        >
          <Button
            disabled={busy}
            color="secondary"
            variant="outlined"
            onClick={() => load(pageNumber)}
            startIcon={<Ic_refresh width={24} height={24} />}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack flex={1} sx={{ overflow: "hidden" }}>
        <ZTable
          sx={{ height: "100%" }}
          busy={busy}
          pageNumber={pageNumber}
          paginated
          totalPages={totalPages}
          nextPage={(pn) => load(pn)}
          prevPage={(pn) => load(pn)}
          titleBadge={totalPosts + " Events"}
          title="Events"
          description="List of all daily events added to the system."
          actionButtons={
            <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
              <Stack
                direction={"row"}
                spacing={"8px"}
                flex={1}
                alignItems={"center"}
              >
                <Button
                  onClick={() => setFilter({ date: "today" })}
                  variant={filter?.date === "today" ? "contained" : "text"}
                  size="small"
                  color="inherit"
                  sx={{
                    borderRadius: "8px",
                    px: "8px",
                    textWrap: "nowrap",
                    py: "4px",
                    height: "44px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      height: "40px",
                    },
                  }}
                >
                  Today
                </Button>
                <Button
                  onClick={() => setFilter({ date: "week" })}
                  variant={filter?.date === "week" ? "contained" : "text"}
                  size="small"
                  color="inherit"
                  sx={{
                    borderRadius: "8px",
                    textWrap: "nowrap",
                    px: "8px",
                    py: "4px",
                    height: "44px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      height: "40px",
                    },
                  }}
                >
                  This Week
                </Button>

                <Button
                  onClick={() => setFilter({ date: "month" })}
                  variant={filter?.date === "month" ? "contained" : "text"}
                  size="small"
                  color="inherit"
                  sx={{
                    borderRadius: "8px",
                    px: "8px",
                    textWrap: "nowrap",
                    py: "4px",
                    height: "44px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 12,
                      height: "40px",
                    },
                  }}
                >
                  This Month
                </Button>

                <IconButton onClick={handleClick}>
                  <CalendarIcon />
                </IconButton>
                <Popover
                  open={showCalenderPopup}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Stack spacing={"8px"}>
                    <Typography
                      fontFamily={"Space Grotesk"}
                      fontSize={14}
                      sx={{ px: "14px", py: "20px" }}
                    >
                      Filter By Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateCalendar
                        value={
                          filter?.date && typeof filter.date === "number"
                            ? moment.unix(filter.date)
                            : undefined
                        }
                        onChange={(v) =>
                          setFilter({
                            ...filter,
                            date: moment(v).unix(),
                          } as any)
                        }
                      />
                    </LocalizationProvider>
                  </Stack>
                </Popover>
              </Stack>
              <ZInput
                text={searchRaw}
                onUpdate={(r) => setSearchRaw(r)}
                onReturn={() => setSearch(searchRaw)}
                startIcon={<Ic_search width={24} height={24} />}
                placeholder="Search..."
              />
            </Stack>
          }
          tableHead={
            <TableRow>
              {/* Event Date/Time */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "date",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Date Time</Typography>
                  {sort?.type === "date" && (
                    <>
                      {sort?.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort?.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>

              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "created",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Synced At</Typography>
                  {sort?.type === "created" && (
                    <>
                      {sort?.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort?.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>

              {/* User  */}
              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Author</Typography>
                </Stack>
              </TableCell>

              {/* Title / Comments */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "title",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Content</Typography>
                  {sort?.type === "id" && (
                    <>
                      {sort?.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort?.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>

              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Forecast</Typography>
                </Stack>
              </TableCell>

              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Previous</Typography>
                </Stack>
              </TableCell>

              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Impact</Typography>
                </Stack>
              </TableCell>

              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Country</Typography>
                </Stack>
              </TableCell>

              {/* Status  */}
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setSort({
                    type: "status",
                    value: sort?.value === "asc" ? "desc" : "asc",
                  })
                }
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Status</Typography>
                  {sort?.type === "status" && (
                    <>
                      {sort.value === "asc" && (
                        <IC_SORT_A width={24} height={24} />
                      )}
                      {sort.value === "desc" && (
                        <IC_SORT_Z width={24} height={24} />
                      )}
                    </>
                  )}
                </Stack>
              </TableCell>

              {/* Tags  */}
              <TableCell>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>Tags</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          }
          tableBody={<>{data.map((d) => RenderEvent(d as any))}</>}
        />
      </Stack>
    </Stack>
  );
}
