import React from 'react';

function Ic_card(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none">
		<path d="M13.548 3.25c1.839 0 3.283 0 4.421.129 1.163.13 2.105.405 2.888 1.023.235.185.452.39.65.612.666.747.964 1.653 1.105 2.764.017.128.025.192.01.248a.307.307 0 0 1-.178.202c-.054.022-.121.022-.256.022H1.812c-.135 0-.202 0-.256-.022a.307.307 0 0 1-.178-.202c-.015-.056-.007-.12.01-.248.141-1.111.439-2.017 1.105-2.764.198-.222.415-.427.65-.612.783-.618 1.725-.892 2.888-1.023 1.138-.129 2.582-.129 4.422-.129h3.095zm9.202 8.803c0 1.724 0 3.09-.138 4.17-.141 1.11-.439 2.016-1.105 2.763a5.131 5.131 0 0 1-.65.612c-.783.618-1.725.892-2.888 1.023-1.138.129-2.582.129-4.422.129h-3.095c-1.839 0-3.283 0-4.421-.129-1.163-.13-2.105-.405-2.888-1.023a5.13 5.13 0 0 1-.65-.612c-.666-.747-.964-1.653-1.105-2.764-.138-1.08-.138-2.445-.138-4.17v-.105c0-.575 0-1.111.005-1.61.003-.276.004-.413.092-.5.088-.087.227-.087.504-.087H22.15c.277 0 .416 0 .504.087.088.087.09.224.092.5.005.498.005 1.034.005 1.61v.106zM10.25 16a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.75-.75zm5.25-.75a.75.75 0 0 0 0 1.5H19a.75.75 0 0 0 0-1.5h-3.5z" fill={fill} fillRule="evenodd"/>
	</g>
</svg>
	);
};

export default Ic_card;